/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import Workspace from "../../containers/Workspace";

import { resetEmitData } from "../../redux/actions/beacon";

import { isEmpty, isNull } from "../../utils/validation";
import { State } from "../../redux/reducers";

const Dashboard: React.FC<{ isEmbedded?: boolean }> = ({ isEmbedded = false }) => {
    const [jsonPath, setJSONPath] = useState<string[]>([]);
    const [mountWorkspace, setMountWorkspace] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth < 991 ? false : true);
    const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);

    const dashboardRef = useRef(null);

    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        const pathname = location.pathname;

        // Reset timeline item storage on every route changes
        sessionStorage.removeItem("OverrideWidgetConfig");
        sessionStorage.removeItem("isVerifying");
        setTimeout(() => { // wait to see if component has unmounted(avoid memory leak)
            if(!isNull(dashboardRef.current)) {
                setMountWorkspace(false);

                if(pathname.includes("/Dashboard")) {
                    const newPath = pathname.replace("Dashboard/", "").substring(1, pathname.length).split("/");

                    newPath[0] === "Dashboard" || newPath[0] === ""?
                        setJSONPath([]):
                        setJSONPath(pathname.replace("Dashboard/", "").substring(1, pathname.length).split("/"));
                } else {
                    setJSONPath([]);
                }

                resetEmitData(dispatch);

                setMountWorkspace(true);
            }
        }, 100);

        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        return () => {
            dashboardRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(sidebarItemSelector)) {
            if (!isEmpty(sidebarItemSelector.isSidebarVisible)) {
                sidebarItemSelector.isSidebarVisible ? setIsSidebarVisible(true) : setIsSidebarVisible(false);
            }
        }
    }, [sidebarItemSelector]);

    return (
        <section className="dashboard" ref={dashboardRef}>
           {!isEmbedded && <div style={{display: 'block'}}> <Sidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/></div>}
            <main className={isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}>
                {!isEmbedded && <Navbar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />}
                {
                    !isEmpty(jsonPath) && mountWorkspace?
                        <section id="content" style={
                            {
                                marginLeft : isSidebarVisible && !isEmbedded ? "250px" : "0px",
                                width :  isSidebarVisible && !isEmbedded ? "calc(100% - 250px)" : "100%",
                                height: isEmbedded ? "100%" : "calc(100% - 50px)"
                            }}>
                                {isEmbedded}
                            <Workspace jsonPath={jsonPath} isEmbedded={isEmbedded} />
                        </section>: null
                }
            </main>
        </section>
    );
};

export default Dashboard;