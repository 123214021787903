/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from "react";
import moment from "moment";
import numeral from "numeral";
import { Dialog } from "primereact/dialog";

import { DialogField } from "../../../../../../../models/classes/DialogField";
import { IConsumeAPI } from "../../../../../../../models/interfaces/IConsumeAPI";
import { IFieldValue } from "../../../../../../../models/interfaces/IFieldValue";

import FieldGenerator from "../../../../../../../utils/field_generator";

import { cancelDataFetch, consumeAPI, resetForm } from "../../../../../../../redux/actions/panelItem";
import { emptyDialogQueueItem } from "../../../../../../../redux/actions/dialog";
import { PanelFieldData } from "../../../../../../../models/classes/PanelFieldData";
import { addIfNotExist } from "../../../../../../../utils/array";
import { isEmpty } from '../../../../../../../utils/validation';

import { Controller } from "react-hook-form";


import './style.css';
import __t from "../../../../../../../utils/translation";
import { emitLOVData, resetEmitLOVData } from "../../../../../../../redux/actions/formFieldItem";
import { ProgressSpinner } from "primereact/progressspinner";
import { BlockUI } from 'primereact/blockui';
import EventEmitter from "../../../../../../../common/EventEmitter";
import { Button } from "primereact/button";

const TableDialogElement = (
    action: any,
    dialog: any,
    setDialog: Function,
    formRef: any,
    control: any,
    handleSubmit: any,
    tableName: string,
    dispatch: Function,
    errors: any,
    subscribeComponents: any,
    beaconSelector: any,
    browserHeight: number,
    container?: {
        widgetName: string,
        panelName: string,
        form?: any,
        [key: string]: any
    }
) => {


    const dialogActionListener = (data: any) => {
        const { targetPanel, isSubmitting, panelItemSelector } = data;

        if (!dialog || targetPanel !== tableName) return;
    
        dialog.isSubmitting = isSubmitting;
    
        if (!isEmpty(panelItemSelector)) {
            if (panelItemSelector.isSuccessSubmitActionData) {
                setDialog(null);
                return;
            }
    
            if (!panelItemSelector?.errorData?.meta?.isRequestFromDialog) return;
        }
    
        setDialog(dialog);
    };

    EventEmitter.on('DIALOG_ACTION', dialogActionListener);

    const submitAction = (formData: any, submitData: IFieldValue[], isBatch = false) => {
        let isValid = true;

        if (dialog?.fields?.length > 0) {
            dialog.fields.forEach((field: DialogField) => {
                // Parse form fields value
                if (formData.hasOwnProperty(field.FieldName)) {
                    let value = formData[field.FieldName];

                    // If a hidden field have a value from the table
                    if (isEmpty(value) && !isEmpty(field.value)) {
                        value = field.value;
                    }

                    if (isBatch) {
                        let fieldValueToOverrideIndex = submitData.findIndex(obj => obj.isFieldOverrider && obj.fieldName === field.FieldName);
                        if (value !== "")
                            if (fieldValueToOverrideIndex > -1)
                                submitData[fieldValueToOverrideIndex].value = value;
                    }

                    if (!isEmpty(value)) {
                        if (field.DataType === "Date") {

                            if (!moment(value, field.FieldFormat).isValid()) {
                                isValid = false;
                               return;
                            }
                            
                            const formattedDate = moment(value, field.FieldFormat).format("YYYY-MM-DD");
                            
                            value = formattedDate;
                        }

                        if (field.DataType === "Datetime") {
                            if (!moment(value, field.FieldFormat).isValid()) {
                                isValid = false;
                                return;
                            }

                            const formattedDate = moment(value, field.FieldFormat).format("YYYY-MM-DD HH:mm:ss");

                            if (formattedDate !== "Invalid date") { // if date hasn't formatted before
                                value = formattedDate;
                            }
                        }

                        if (field.DataType === "Decimal" || field.DataType === "Number") {
                            const parsedValue = numeral(value).value();
                            if (parsedValue !== null) {
                                value = parsedValue;
                            }
                        }
                    }

                    submitData = addIfNotExist(
                        submitData,
                        "fieldName",
                        field.FieldName,
                        {
                            fieldName: field.FieldName,
                            fieldType: field.DataType,
                            value,
                            dataType: field.DataType,
                        },
                        submitData.length
                    );
                }
            });
        }

        // For MongoDB
        const IDIndex: number = dialog.fields.findIndex((field: DialogField) => field.FieldName === "_id");
        if (IDIndex > 0) {
            const field = dialog.fields[IDIndex];
            submitData = addIfNotExist(
                submitData,
                "fieldName",
                field.FieldName,
                {
                    fieldName: field.FieldName,
                    fieldType: field.DataType,
                    value: field.value,
                    dataType: field.DataType,
                },
                submitData.length
            );
        }

        // Post action
        dialog?.postActions?.forEach((_postAction: any, idx: number) => {
            dialog.postActions[idx]["RefreshFieldRowInfo"] = dialog.rowData;
        });

        const actionOptions: IConsumeAPI = {
            targetPanel: tableName,
            // targetDialog: dialog.name,
            APIName: dialog.APIName,
            inputParameterValues: submitData,
            UseQueue: false,
            isAPIForDataTable: false,
            dialogQueueItemIndexToRemove: 0,
            postActions: [dialog?.postActions],
            isRequestFromDialog: true
        };

        if (isValid) {
            consumeAPI(
                dispatch,
                actionOptions,
                async (result: any) => {
                    console.log("result", result)
    
                    if (result && result.success === true) {
                        dialog.isNeedResubmit = false;
                        await setDialog(null);
                        resetState();
                    }
                }
            );    
        }
        
    };

    const onSubmit = async (formData: any) => {
        dialog.isNeedResubmit = true;
        await setDialog(dialog); 

        console.log("dialog.progressQueue", dialog.progressQueue)

        const actionFields = (dialog.fields as DialogField[]).filter(f =>
            (!isEmpty(f.Actions) && f.Actions!.length > 0))

        if (!isEmpty(actionFields) && actionFields.length > 0) {
            const data = actionFields[0];

            console.log("data.validationStatus!", data.validationStatus!)

            if (data && !isEmpty(data.validationStatus!)) {
                const { inputRef, setInputFocus } = data.validationStatus!;

                const { isLoadingCallApi, isSuccessCallApi } = data.validationStatus
                if (isLoadingCallApi) return;

                if (inputRef && setInputFocus) {

                    await setInputFocus();  // Await focus before setting error

                    if (inputRef.current) {
                        inputRef.current.classList.add(
                            'p-invalid',
                            'p-inputtext',
                            'p-component');

                        setTimeout(() => {
                            if (!isEmpty(data.value)) {
                                inputRef.current.blur()
                            }
                        }, 500);
                    }
                }

                if (!isSuccessCallApi) return;
            }

        }

        if (dialog.progressQueue.length > 0) return;

        let submitData: IFieldValue[] = [];

        if (dialog.dialogQueueSelector) {
            dialog.dialogQueueSelector.forEach((queueItem: any) => {
                submitData = [];
                queueItem.tableFields.forEach((tableField: PanelFieldData) => {

                    const overrideTableFieldIndex = queueItem.action.Fields
                        .findIndex((actionField: PanelFieldData) => (actionField.Editable || !actionField.Hidden) && actionField.FieldName === tableField.FieldName);
                    let value = queueItem.rowData[tableField.FieldName];

                    if (!isEmpty(value)) {
                        if (tableField.DataType === "Date") {
                            value = moment(queueItem.rowData[tableField.FieldName], tableField.FieldFormat).format("YYYY-MM-DD");
                        }

                        if (tableField.DataType === "Datetime") {
                            value = moment(queueItem.rowData[tableField.FieldName], tableField.FieldFormat).format("YYYY-MM-DD HH:mm:ss");
                        }

                        if (tableField.DataType === "Decimal" || tableField.DataType === "Number") {
                            const parsedValue = numeral(value).value();
                            if (parsedValue !== null) {
                                value = parsedValue;
                            }
                        }
                    }

                    submitData.push({
                        fieldName: tableField.FieldName,
                        fieldType: tableField.DataType,
                        value,
                        dataType: tableField.DataType,
                        isFieldOverrider: overrideTableFieldIndex > -1
                    });
                });
                submitAction(formData, submitData, true);
            });
        } else {
            submitAction(formData, submitData);
        }
    };

    const resetState = () => {
        dialog.fields.forEach((field: DialogField) => {
            resetEmitLOVData(dispatch, 'RESET_LOV', field.FieldName).then(x => {
                emitLOVData(dispatch, `${tableName}___LOV_DATA`, null, field.FieldName)
            });

            field.value = null;
            const component = document.getElementById(field.FieldName) as HTMLInputElement
            if (component) {
                component.value = '';
            }

        })

        for (let index = 0; index < dialog!.fields!.length; index++) {
            const f = dialog.fields[index];
            f.value = null;
            const component = document.getElementById(f.FieldName) as HTMLInputElement
            if (component) {
                component.value = '';
            }
        }

        resetForm(dispatch, tableName);
        const { form } = container!;
        const { reset } = form!;
        reset({})
    }

    const triggerSubmit = () => {
        handleSubmit(onSubmit)();
    };

    const getFormErrorMessage = (name: string) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    subscribeComponents?.forEach((subscribeInfo: any) => {
        const beaconSource = `${subscribeInfo?.Source?.WidgetName}_${subscribeInfo?.Source?.PanelName}`;

        if (!isEmpty(beaconSelector[beaconSource])) {
            if (!isEmpty(dialog.fields)) {
                for (let index = 0; index < dialog.fields.length; index++) {
                    const field = dialog.fields[index];
                    const defaultFieldValue = field.DefaultValue as DialogField;

                    if (!isEmpty(field.DefaultValue) && !isEmpty(defaultFieldValue) && !isEmpty(defaultFieldValue.ValueSource)) {

                        const value = beaconSelector[beaconSource]?.find((emittedData: any) => (
                            emittedData.DataName === defaultFieldValue.ValueSource &&
                            emittedData.DataSource === defaultFieldValue.FieldName
                        ));

                        if (!isEmpty(value)) {
                            dialog.fields[index].value = value.EmittedValue;
                            dialog.fields[index].Value = value.EmittedValue;
                        }
                    }

                }
            }
        }
    })

    const closeDialog = () => {

        cancelDataFetch(dispatch, tableName);

        for (let index = 0; index < dialog!.fields?.length; index++) {
            const f = dialog.fields[index];
            f.value = null;
            const component = document.getElementById(f.FieldName) as HTMLInputElement
            if (component) {
                component.value = '';
            }
        }

        const { form } = container!;
        const { reset, setValue } = form!;

        for (let index = 0; index < dialog!.fields!.length; index++) {
            setValue(null);
        }

        reset({})
        EventEmitter.off('DIALOG_ACTION', dialogActionListener)

        resetForm(dispatch, tableName);
        setDialog(null);


        if (dialog.dialogQueueSelector) // if is batch
            emptyDialogQueueItem(dispatch);

    }


    return (

        <Dialog
            style={{ width: dialog.width, height: dialog.height - 300 }}
            visible={!!dialog}
            header={dialog.header}
            modal={true}
            closable={!dialog.isSubmitting}

            onHide={() => {
                closeDialog()
            }}
        >
            {
                dialog?.loading ?
                    <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '150px', padding: '10px' }}>
                        <BlockUI blocked={true} template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>}>
                        </BlockUI>
                    </div> :
                    <BlockUI blocked={dialog?.isSubmitting} template={
                        <React.Fragment>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <ProgressSpinner style={{ width: "50px", height: "50px" }} /><br />
                                <Button
                                    className="p-button-raised p-button-danger"
                                    label="Cancel"
                                    onClick={() => {
                                        if (dialog) {
                                            dialog.isSubmitting = false;
                                            setDialog(dialog);
                                        }
                                        cancelDataFetch(dispatch, tableName);
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    }>
                        <div style={{ maxHeight: browserHeight }}>
                            <form
                                style={{ height: dialog.height - 400 || 'auto' }}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                {[...Array(dialog.numberOfRows)].map((y, yIndex) => {
                                    const match = dialog.fields.find((value: any) =>
                                        !isEmpty(value) && !isEmpty(value.DisplayPosition) &&
                                        !isEmpty(value.InputType) &&
                                        value.DisplayPosition.split(",").length > 0 &&
                                        value.DisplayPosition.split(",")[1].trim().includes(yIndex)
                                    );
                                    if (isEmpty(match)) {
                                        return null;
                                    }
                                    return <div
                                        key={yIndex}
                                        className="p-grid p-justify-between"
                                    >
                                        {[...Array(dialog.numberOfColumns)].map((x, xIndex) => {
                                            const currentDisplayPosition = `${xIndex},${yIndex}`;
                                            const fieldIndex: number = dialog.fields
                                                .findIndex((field: DialogField) => field.DisplayPosition === currentDisplayPosition);
                                            const inputField: DialogField = dialog.fields[fieldIndex];
                                            if (inputField?.InputType) {
                                                let customProps = {
                                                    chartName: tableName,
                                                    dialog,
                                                    setDialog,
                                                    container,
                                                    triggerSubmit,
                                                    style: {
                                                        "width": isEmpty(inputField?.FieldWidth) ? "initial" : inputField.FieldWidth,
                                                        "minWidth": isEmpty(inputField?.FieldWidth) ? "initial" : inputField.FieldWidth + " !important;"
                                                    }
                                                };
                                                return (
                                                    <div
                                                        key={xIndex}
                                                        className="p-col"
                                                        style={{
                                                            ...(inputField?.Hidden && {
                                                                // padding: 0,
                                                                height: 0,
                                                                visibility: "hidden"
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            inputField ?
                                                                <div
                                                                    key={fieldIndex}
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                        display: "flex",
                                                                        flexDirection: inputField?.InputType === "DisplayText" || inputField?.InputType === "FileUpload" ? "row" : process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "column" : "row",
                                                                        alignItems: inputField?.InputType === "FileUpload" ? undefined : "flex-start",
                                                                        justifyContent: "space-between"
                                                                    }}
                                                                >
                                                                    <label htmlFor={inputField.FieldName}>{__t(inputField, "FieldLabel") || inputField.FieldName}</label>
                                                                    <div style={{ display: "grid" }}>
                                                                        <Controller
                                                                            key={inputField.FieldName + fieldIndex}
                                                                            name={inputField.FieldName}
                                                                            control={control}
                                                                            defaultValue={inputField.value}
                                                                            rules={inputField.Mandatory ? { required: __t(inputField, "FieldLabel") || inputField.FieldName + ' is required.' } : undefined}
                                                                            render={({ field, fieldState }) => {
                                                                                return (<FieldGenerator key={inputField.FieldName + fieldIndex + fieldIndex}
                                                                                    {...{ reference: field, field: inputField, ref: formRef, customProps: customProps, fieldState }}
                                                                                    onValidated={async (data) => {
                                                                                        const { valid } = data;
                                                                                        console.log("dialog.isNeedResubmit", dialog.isNeedResubmit, "valid", valid)
                                                                                        if (valid && dialog.isNeedResubmit) {
                                                                                            triggerSubmit()
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setTimeout(() => {
                                                                                            const { form } = container!;
                                                                                            if (form) {
                                                                                                const { setValue } = form;
                                                                                                setValue(inputField.FieldName, e);
                                                                                            }
                                                                                        }, 500);
                                                                                    }}

                                                                                />);
                                                                            }}
                                                                        />
                                                                        {getFormErrorMessage(inputField.FieldName)}
                                                                    </div>

                                                                </div> : null
                                                        }
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                })}
                                <div className="p-grid p-justify-evenly" style={{
                                    padding: "10px",
                                    paddingTop: "20px"
                                }}>
                                    <Button 
                                        type="button"
                                        onClick={closeDialog} 
                                        className="p-button p-component p-button-text-only p-button-danger p-button-raised" 
                                        raised
                                        label="Close" 
                                        severity="danger" 
                                        icon={`pi ${process.env.REACT_APP_ICON_BTN_CANCEL}`}
                                        iconPos={process.env.REACT_APP_ICON_BTN_CANCEL === "left" || process.env.REACT_APP_ICON_BTN_CANCEL === "right" || process.env.REACT_APP_ICON_BTN_CANCEL === "top" || process.env.REACT_APP_ICON_BTN_CANCEL === "bottom" ? process.env.REACT_APP_ICON_BTN_CANCEL : "left"}
                                        style={{
                                            backgroundColor: process.env.REACT_APP_BACKGROUND_BTN_CANCEL || '#d32f2f',
                                            color: process.env.REACT_APP_TEXT_COLOR_BTN_CANCEL || '#ffffff',
                                        }}
                                    />
                                    {/* <button className="p-button p-component p-button-text-only p-button-danger" style={{
                                        padding: "0.429em 1em"
                                        }} onClick={closeDialog}>Close</button> */}
                                    <div style={{ margin: "10px" }}></div>
                                    <Button 
                                        type="submit" 
                                        className="p-button p-component p-button-text-only p-button-raised"
                                        raised
                                        label="Submit" 
                                        severity="info"
                                        icon={`pi ${process.env.REACT_APP_ICON_BTN_SUBMIT}`}
                                        iconPos={process.env.REACT_APP_ICON_BTN_SUBMIT === "left" || process.env.REACT_APP_ICON_BTN_SUBMIT === "right" || process.env.REACT_APP_ICON_BTN_SUBMIT === "top" || process.env.REACT_APP_ICON_BTN_SUBMIT === "bottom" ? process.env.REACT_APP_ICON_BTN_SUBMIT : "left"}
                                        style={{
                                            backgroundColor: process.env.REACT_APP_BACKGROUND_BTN_SUBMIT || '#2196f3',
                                            color: process.env.REACT_APP_TEXT_COLOR_BTN_SUBMIT || '#ffffff',
                                        }}
                                    />
                                    {/* <input
                                        className="p-button p-component p-button-text-only"
                                        style={{
                                            padding: "0.429em 1em"
                                        }}
                                        type="submit"
                                    /> */}
                                </div>
                            </form>
                        </div>
                    </BlockUI>
            }
        </Dialog>

    );
};

export default TableDialogElement;
