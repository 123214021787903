/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar as SB } from 'primereact/sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

import './styles/index.css';
import './styles/panel_menu.css';
import logo from '../../assets/company-cbi.png';

import CompanyLogo from './components/CompanyLogo';

import { getSidebarMenu, toggleSidebarMenu } from '../../redux/actions/sidebarItem';
import { State } from '../../redux/reducers';

import { isEmpty, isNull } from '../../utils/validation';
import styled from 'styled-components';
import __t from '../../utils/translation';

const PanelMenuItem = styled(PanelMenu)`
.p-menuitem {
    background: ${!isEmpty(process.env.REACT_APP_COLOR_SIDEBAR) ? process.env.REACT_APP_COLOR_SIDEBAR + " !important" : ""};
}

.p-menuitem-text {
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

.p-panelmenu-header > a .p-panelmenu-icon {
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

#sidebar {
    background: ${!isEmpty(process.env.REACT_APP_COLOR_SIDEBAR) ? process.env.REACT_APP_COLOR_SIDEBAR + " !important" : ""};
    color: ${!isEmpty(process.env.REACT_APP_MENU_COLOR) ? process.env.REACT_APP_MENU_COLOR + " !important" : ""};
}

width: 100%;
height: calc(100vh - 130px);
overflow-y: auto;
`

interface MenuItem {
  _id: string;
  label: string;
  menuRoute: string;
  menuAction: string;
  order: number;
  category: string;
  children?: MenuItem[];
  Locale?: { [key: string]: { label: string } };
}

const sidebarArrayToDataTree = (list: any, isRoot = false, setIsSidebarVisible: (visible: boolean) => void) => {
  let parsedList: any = [];
  if (isRoot) {
    list = JSON.stringify(list);
    list = list.split("children").join("items");
    list = JSON.parse(list);
  }
  if (Array.isArray(list)) {
    list.forEach((data: any) => {
      if (!isEmpty(data)) {
        let command = "";
        if (data.menuRoute) command = `window.location.hash="${data.menuRoute}";`;
        if (data.menuAction) command += data.menuAction;
        if (data.menuRoute) command += `setIsSidebarVisible(false);`;
        data.label = __t(data, "label");
        data.key = data._id;
        // eslint-disable-next-line no-new-func
        data.command = new Function('setIsSidebarVisible', command).bind(null, setIsSidebarVisible);

        if (data.items) {
          sidebarArrayToDataTree(data.items, false, setIsSidebarVisible);
        }
        parsedList.push(data);
      }
    });
  }
  return parsedList;
};

// Filter callback which decides if an item matches the search filter
function filterCallback(item: any, search: any) {
  const itemLabel = item.label.toLowerCase();
  return itemLabel.includes(search.toString().toLowerCase());
}

// Filter recursively to find all items that match the search filter
function filter(parentItem: any, search: any, expandedKeys: any) {
  // Create a deep copy of the parent item to avoid mutating the original data
  const itemCopy = { ...parentItem, items: parentItem.items ? [...parentItem.items] : [] };

  // If the item has children, filter them first
  if (itemCopy.items && itemCopy.items.length > 0) {
    itemCopy.items = itemCopy.items.filter((childItem: any) => filter(childItem, search, expandedKeys));

    // If the item itself matches the search term, keep it and expand it
    if (filterCallback(itemCopy, search)) {
      expandedKeys[itemCopy.key] = true; // Expand the node
      return itemCopy;
    }

    // If any of its children match, keep the item and expand it
    if (itemCopy.items.length > 0) {
      expandedKeys[itemCopy.key] = true; // Expand the node
      return itemCopy;
    }

    // Otherwise, discard the item
    return null;
  }

  // If the item has no children, check if it matches the search term
  return filterCallback(itemCopy, search) ? itemCopy : null;
}

interface SidebarProps {
  isSidebarVisible: boolean;
  setIsSidebarVisible: (visible: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sidebarMenu, setSidebarMenu] = useState<any>(null);
  const originalMenu = useRef<any>(null); // Store the original menu in a ref
  const toastRef = useRef<Toast>(null);
  const [expandedKeys, setExpandedKeys] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  const dispatch = useDispatch();
  const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);

  const parseMenu = (menuData: any) => {
    // Function to sort menu items by order
    const sortByOrder = (a: MenuItem, b: MenuItem) => a.order - b.order;

    // Sort the menu items
    menuData.sort(sortByOrder);

    // Add your custom logic to move "Sign Out" to the bottom
    const signOutItemIndex = menuData.findIndex((item: MenuItem) => item.label === "Sign Out");
    const signOutItem = signOutItemIndex !== -1 
      ? menuData.splice(signOutItemIndex, 1)[0] 
      : { label: "Sign Out", menuRoute: '/signout' } as MenuItem;

    signOutItem.menuAction = null;
    signOutItem.menuRoute = '/signout'; // Force replace menuRoute to signout route

    menuData.push(signOutItem);
  }

  const collapseAll = () => {
    setExpandedKeys({});
  };

  const handleSearch = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (!searchTerm) {
      // Reset to the original menu when search is cleared
      setFilteredItems(originalMenu.current);
      collapseAll();
      return;
    }

    const newExpandedKeys: any = {};
    const newFilteredItems = originalMenu.current
      .map((item: any) => filter({ ...item }, searchTerm, newExpandedKeys)) // Deep copy and filter each item
      .filter((item: any) => item !== null); // Remove null items (non-matching)

    setFilteredItems(newFilteredItems);
    setExpandedKeys(newExpandedKeys); // Update expandedKeys with the new keys
  };

  const handleMouseEnter = () => {
    if (window.innerWidth < 991) {
      setIsSidebarVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth < 991) {
      setIsSidebarVisible(false);
      toggleSidebarMenu(dispatch, false);
    }
  };

  useEffect(()=> {
    if (searchTerm !== "" && searchTerm) {
      const newExpandedKeys: any = {};
      const newFilteredItems = originalMenu.current
        .map((item: any) => filter({ ...item }, searchTerm, newExpandedKeys)) // Deep copy and filter each item
        .filter((item: any) => item !== null); // Remove null items (non-matching)
      setExpandedKeys(newExpandedKeys); // Update expandedKeys with the new keys
    }
  }, [isSidebarVisible])

  useEffect(() => {
    if (!isEmpty(sidebarItemSelector) && !isNull(sidebarRef.current)) {
      if (!isEmpty(sidebarItemSelector.isSidebarVisible) && !isNull(sidebarRef.current)) {
        sidebarItemSelector.isSidebarVisible ? setIsSidebarVisible(true) : setIsSidebarVisible(false);
      }

      if (sidebarItemSelector.isLoadingGetSidebarData && !isNull(sidebarRef.current)) {
        setIsLoading(true);
      }
      if (sidebarItemSelector.isSuccessGetSidebarData && !isNull(sidebarRef.current)) {
        parseMenu(sidebarItemSelector.successData.data || []);

        const menu = sidebarArrayToDataTree(sidebarItemSelector.successData.data || [], true, setIsSidebarVisible);
        setSidebarMenu(menu);
        originalMenu.current = menu; // Store the original menu in a ref
        setFilteredItems(menu); // Initialize filteredItems with the original menu
        handleSearch({
          target: {
            value: ''
          }
        });

        setIsLoading(false);
      }

      let returnType = 0;

      if (sidebarItemSelector.isErrorGetSidebarData && !isNull(sidebarRef.current)) {
        setIsLoading(false);
        let toastOptions: any = { summary: "Failed to fetch data", icon: <></>, severity: "error", detail: "" };

        if (!isEmpty(sidebarItemSelector?.errorData)) {
          if (typeof sidebarItemSelector.errorData?.message === "string") {
            returnType = 4;
            toastOptions.detail = sidebarItemSelector.errorData.message;
          } else if (typeof sidebarItemSelector.errorData?.message === "object") {
            returnType = sidebarItemSelector.errorData.message?.ReturnType || 2;
            toastOptions.detail = sidebarItemSelector.errorData.message?.ReturnMessage;
            if (sidebarItemSelector.errorData.message?.ReturnType === 2) {
              toastOptions.severity = "warn";
            }
          }
        }

        toastOptions.severity === "warn" ? toastOptions["life"] = 15000 : toastOptions["life"] = 30000;

        if (!isEmpty(toastOptions?.detail)) {
          if (toastOptions.detail === "jwt expired") {
            localStorage.clear();
            sessionStorage.clear();
            navigate('signin', {
              replace: true
            });
          }
          if (toastRef && parseInt(`${returnType}`) > 0) {
            toastRef.current!.show(toastOptions);
          } else {
            console.error(toastOptions.detail);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [sidebarItemSelector]);

  useEffect(() => {
    setTimeout(() => { // Delays dispatch so navbar can dispatch toggle first
      if (!isNull(sidebarRef.current)) {
        getSidebarMenu(dispatch);
      }
    }, 150);

    return () => {
      sidebarRef.current = null;
    }
    // eslint-disable-next-line
  }, []);

  const titleBackround = !isEmpty(process.env.REACT_APP_COLOR_TITLE_SIDEBAR) ? { background: process.env.REACT_APP_COLOR_TITLE_SIDEBAR } : {};

  return (
    <div ref={sidebarRef} style={{ display: isSidebarVisible ? "block" : "none" }} onMouseEnter={handleMouseEnter}>
      <Toast ref={toastRef} />
      <SB visible={isSidebarVisible}
        onHide={() => toggleSidebarMenu(dispatch, false)}
        dismissable={false}
        closeOnEscape={false}
        modal={false}
        showCloseIcon={false}
        style={{ overflowY: "hidden" }}
        id={'sidebar'}
        onMouseLeave={handleMouseLeave}
      >

        <CompanyLogo companyLogo={logo} />

        <div className="title" style={titleBackround}>
          <p style={{ fontWeight: "bold" }}>{!isEmpty(process.env.REACT_APP_TITLE) ? process.env.REACT_APP_TITLE : "BI Tools Demo"}</p>
        </div>
        {
          !isLoading ? <div className="p-grid p-justify-center p-align-center" style={{
            marginTop: '12px',
            marginBottom: '12px'
          }}>
            <InputText
              placeholder='Search Menu'
              className="p-inputtext-sm w-85"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div> : null
        }

        {
          isLoading ?
            <div style={{
              width: "100%",
              height: "500px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <span style={{ color: "#fff" }}>Loading menu...</span>
            </div> :
            <PanelMenuItem
              model={filteredItems}
              expandedKeys={expandedKeys}
              onExpandedKeysChange={(e) => setExpandedKeys(e.value)}
            />
        }
      </SB>
    </div>
  )
};

export default Sidebar;