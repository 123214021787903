/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Plot from "react-plotly.js";
import { Datum, PlotData, PlotMouseEvent, Layout } from "plotly.js";
import openSocket from "socket.io-client";

import "./styles/index.css";
import "./styles/plotly_custom_style.css";

import { PanelData } from "../../../../../../models/classes/PanelData";
import { PanelFieldData } from "../../../../../../models/classes/PanelFieldData";
import { WidgetData } from "../../../../../../models/classes/WidgetData";
import { IAPIOption } from "../../../../../../models/interfaces/IAPIOption";
import { IFieldValue } from "../../../../../../models/interfaces/IFieldValue";

import api from "../../../../../../common/api";
import { isEmpty } from "../../../../../../utils/validation";

import { emitData, resetEmitData } from "../../../../../../redux/actions/beacon";
import { State } from "../../../../../../redux/reducers";
import moment from "moment";
import { isStringUTC } from '../../../../../../utils/date';
import TreeChart from "../../../../../../components/TreeChart";
import MapChart from "../../../../../../components/MapChart";
import __t from "../../../../../../utils/translation";
import _ from "lodash";
import { findNearbyLocations } from "../../../../../../utils/location";
import { DialogField } from "../../../../../../models/classes/DialogField";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import FieldGenerator from "../../../../../../utils/field_generator";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from "primereact/button";

interface IPanelChart {
    widgetData: WidgetData;
    panelData: PanelData;
    panelWidth: number;
    onLoading: Function;
}

function PanelChartComponent(props: IPanelChart) {
    const { widgetData, panelData, panelWidth, onLoading } = props;
    const { WidgetName } = widgetData;

    const chartName = `${WidgetName}_${panelData.PanelName}`;

    const [canChartRender, setCanChartRender] = useState(false);
    const [chartHeight, setChartHeight] = useState<number | null>(null);
    const [inputParameterValues, inputParameterValuesSetValue] = useState<IFieldValue[]>([]);
    const [plotData, plotDataSetValue] = useState<Partial<PlotData>[]>([]);
    const [panelMessage, panelMessageSetValue] = useState<string>("");
    const [panelSilentMessage, panelSilentMessageSetValue] = useState<string>("");
    const [silentLoad, panelSilentLoad] = useState<boolean>(false);

    const [plotLayout, plotLayoutSetValue] = useState<Partial<Layout>>({});
    const [treeData, treeDataSetValue] = useState<any>(null);
    const [mapData, mapDataSetValue] = useState<any>(null);
    const [currentData, setData] = useState<any>([]);

    const [lastUpdate, lastUpdateSetValue] = useState<Date>(new Date());

    const beaconSelector = useSelector((state: State) => state.beaconItem);
    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const dispatch = useDispatch();
    let ref = useRef<Plot>(null);
    const [attributes, setAttributes] = useState<any[][]>([])
    const [selectedPoint, setSelectedPoint] = useState<any>()
    const [chartScale, setChartScale] = useState<number>(1)

    const plotConfig = {
        displaylogo: false,
        responsive: false,
        scrollZoom: true,
        mapboxAccessToken: process.env.REACT_APP_MAP_BOX_TOKEN || ''
    };

    const [mounted, setMounted] = useState(false)

    if (!mounted) {
        // Code for componentWillMount here
        // This code is called only one time before intial render
        sessionStorage.removeItem(chartName);
    }

    useEffect(() => {
        setMounted(true);
    }, [])

    const handleChartClick = (event: PlotMouseEvent) => {
        if (event.points?.length > 0) {
            resetSubscriberChart()
            const sourcePanel: string = chartName;
            let point: any = event.points[0];
            let dataToEmit: any;
            let chartType = point?.fullData?.type;

            if (point?.customdata?.length > 0 && !["scattermapbox", "scattergeo"].includes(chartType)) {   
                point.customdata = point.customdata[0];
            }

            if (!isEmpty(panelData.PublishComponents)) {
                panelData.PublishComponents.forEach((publishInfo) => {
                    if (chartType === "bar") {                        
                        point = selectedPoint;
                        if (point.data?.type === "bar") {
                            const dataLabel = panelData.Fields.find(field => field.ChartField === "DataLabel");
                            const dataValue = panelData.Fields.find(field => field.ChartField === "DataValue");
                            const dataName = panelData.Fields.find(field => field.ChartField === "DataName");
                            const dataAttributes = panelData.Fields.filter(field => field.ChartField === "DataAttribute");                                

                            if (Array.isArray(dataToEmit) || isEmpty(dataToEmit)) dataToEmit = [];

                            if (
                                dataName?.FieldName && dataValue?.FieldName &&
                                dataLabel?.FieldName &&
                                !isEmpty(point.data?.name)
                            ) {

                                dataToEmit.push({
                                    DataSource: dataName.FieldName,
                                    EmittedValue: point.data?.name,
                                    DataName: "BarChartSelectedItem"
                                })

                                dataToEmit.push({
                                    DataSource: dataLabel.FieldName,
                                    EmittedValue: point.x,
                                    DataName: "BarChartSelectedItem"
                                })

                                dataToEmit.push({
                                    DataSource: dataValue.FieldName,
                                    EmittedValue: point.y,
                                    DataName: "BarChartSelectedItem"
                                })                                

                                if (!isEmpty(dataAttributes)) {   
                                    console.log("attributes => ", attributes)
                                    console.log("dataAttributes", dataAttributes);
                                    console.log("point", point);
                                    
                                    dataAttributes.forEach(element => {
                                        if (Array.isArray(point.data[element.FieldName])) {
                                            
                                            dataToEmit.push({
                                                DataSource: element.FieldName,
                                                EmittedValue: point.value,
                                                DataName: "BarChartSelectedItem"
                                            })
                                        } else if(attributes) {
                                            let indexAttribute = null;
                                            for (let index = 0; index < attributes.length; index++) {
                                                const element = attributes[index] as any;
                                                const label = element.find((field: any) => field.EmittedValue === point.x );
                                                const value = element.find((field: any) => field.EmittedValue === point.y );
                                                if (label && value) {
                                                    indexAttribute = index;
                                                    break;
                                                }
                                            }
                                            console.log("indexAttribute", indexAttribute);
                                            if (indexAttribute != null) {
                                                let attribute: any = attributes[indexAttribute].find((field: any) => field.DataSource === element.FieldName);
                                                //const attribute = attributes?.map((attribute) => attribute.find((field: any) => field.DataSource === element.FieldName && field.EmittedValue === point.x) ) as any;
                                                console.log("attribute", attribute);
                                                
                                                if (attribute) {
                                                    dataToEmit.push({
                                                        DataSource: attribute.DataSource,
                                                        EmittedValue: attribute.EmittedValue,
                                                        DataName: "BarChartSelectedItem"
                                                    })
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    } else if (chartType === "scattermapbox") {
                        if (Array.isArray(dataToEmit) || isEmpty(dataToEmit)) dataToEmit = [];                     
                        dataToEmit.push(point.customdata)
                        publishInfo.DataName = "MapSelectedItem"
                        publishInfo.EmittedValue = point.customdata
                    } else if (chartType === "scattergeo") {
                        if (Array.isArray(dataToEmit) || isEmpty(dataToEmit)) dataToEmit = []; 
                        const nearPlaces = findNearbyLocations(point.lat, point.lon, mapData ?? [], {
                            latField: point.data.latField,
                            lonField: point.data.lonField
                        })

                        dataToEmit = {
                            UseArray: true,
                            data: nearPlaces
                        };
                        publishInfo.DataName = "MapSelectedItem"
                        publishInfo.EmittedValue = nearPlaces
                    } else if (chartType === "tree") {
                        if (Array.isArray(dataToEmit) || isEmpty(dataToEmit)) dataToEmit = [];
                        
                        Object.keys(point.customdata).forEach(key => {
                            let value = point.customdata[key];
                            dataToEmit.push({
                                DataSource: key,
                                EmittedValue: value,
                                DataName: "ChartSelectedItem"
                            })
                        })

                        publishInfo.DataName = "ChartSelectedItem"
                        publishInfo.EmittedValue = point.customdata
                    } else {

                        if (!isEmpty(_.get(point, "data.rawData", null))) {
                            if (Array.isArray(dataToEmit) || isEmpty(dataToEmit)) dataToEmit = [];
                            const data = point.data.rawData[point.pointNumber]

                            
                            Object.keys(data).forEach(key => {
                                let value = data[key];
                                dataToEmit.push({
                                    DataSource: key,
                                    EmittedValue: value,
                                    DataName: "MapSelectedItem"
                                })
                            })

                            publishInfo.DataName = "MapSelectedItem"
                            publishInfo.EmittedValue = data
                            
                        } else {
                            publishInfo.DataName = "ChartSelectedItem"
                            publishInfo.DataSource = point.data.customdata[point.pointNumber].DataSource
                            publishInfo.EmittedValue = point.data.customdata[point.pointNumber].EmittedValue //point["customdata"]; // default value for pie chart is on point.customdata
                        }
                    }
                });
            }

            if (chartType === "pie" || chartType === "sunburst") {
                if (!isEmpty( attributes[point.pointNumber])) {
                    dataToEmit = attributes[point.pointNumber]
                }
            }
            // if (chartType === "bar") {
            //     if (!isEmpty(point.data.attributes[`${point.value}${point.x}`])) {
            //         dataToEmit = point.data.attributes[`${point.value}${point.x}`]
            //     }
            // }           
            console.log("dataToEmit", dataToEmit);
             
            emitData(dispatch, sourcePanel, dataToEmit);
        } else {
            alert("Chart error: no 'points' data");
        }
    };

    const fetchPlotData = (silentLoad: boolean = false) => {
        let fieldValues: IFieldValue[] = [];

        if (!silentLoad) {
            panelMessageSetValue("Loading...");
            panelSilentMessageSetValue("");
        } else {
            onLoading(true)
            panelSilentMessageSetValue("Loading...");
        }

        if (isEmpty(inputParameterValues)) {
            panelData?.LoadData?.APIParameters?.forEach(
                (APIParam: PanelFieldData) => {
                    fieldValues.push({
                        fieldName: APIParam.FieldName,
                        dataType: APIParam.DataType,
                        value: APIParam.Value,
                    } as IFieldValue);
                }
            );
        } else {
            fieldValues = inputParameterValues;
        }


        if (silentLoad) {

            var cacheItem = sessionStorage.getItem(chartName) || ""
            var cacheData = isEmpty(cacheItem) ? null : JSON.parse(cacheItem);

            if (!isEmpty(cacheData)) {
                let cacheInputParameterValues: any = [];

                panelData?.LoadData?.APIParameters?.forEach((param) => {
                    let value;

                    if (param.DefaultValue) {
                        value = param.DefaultValue;
                    }

                    value = cacheData.find((emittedData: any) => (
                        emittedData.fieldName === param.FieldName
                    ))

                    cacheInputParameterValues.push({
                        fieldName: param.FieldName,
                        value: typeof value === 'object' && value !== null ? value.value : value,
                        dataType: param.DataType
                    });

                });
                fieldValues = [...inputParameterValues, ...cacheInputParameterValues]
                const result = [];
                const map = new Map();
                for (const item of fieldValues) {
                    if (!map.has(item.fieldName)) {
                        map.set(item.fieldName, true);    // set any value to Map
                        result.push(item);
                    }
                }
                fieldValues = result;
            }
        }


        let requestBody = {
            APIName: panelData.LoadData.APIName,
            inputParameterValues: fieldValues,
        };

        const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody,
        };

        api(apiOption).then(
            (response: { success: boolean; data: any; message: any }) => {
                panelSilentLoad(silentLoad);
                if (response?.success) {
                    onLoading(false)
                    setTimeout(() => {
                        dispatch({
                            type: "API_GET_TABLE_DATA_SUCCESS",
                            payload: {
                                targetPanel: chartName,
                                done: true,
                                data: []
                            }
                        });
                    }, 50);
                    if (!isEmpty(response?.data)) {
                        panelMessageSetValue("");
                        panelSilentMessageSetValue("");
                        generatePlotData(response.data);
                    } else {
                        resetPlotData();
                        panelMessageSetValue("Empty data.");
                    }
                } else {
                    onLoading(true)
                    resetPlotData();
                    panelSilentMessageSetValue(
                        typeof response?.message === "string" ?
                            response.message :
                            response?.message?.hasOwnProperty("ReturnMessage") ?
                                response.message.ReturnMessage :
                                response.message.toString()
                    );
                    panelMessageSetValue(
                        typeof response?.message === "string" ?
                            response.message :
                            response?.message?.hasOwnProperty("ReturnMessage") ?
                                response.message.ReturnMessage :
                                response.message.toString()
                    );
                    setTimeout(() => {
                        dispatch({
                            type: "API_GET_TABLE_DATA_ERROR",
                            payload: {
                                targetPanel: chartName,
                                message: panelMessage,
                                done: true,
                                data: []
                            }
                        });
                    }, 50);
                }
            }
        );
    };

    const generatePlotData = (data: any, skipDataSet: boolean = false) => {

        const onlyUnique = (value: any[], index: number, self: any) => {
            return self.indexOf(value) === index;
        }

        if (panelData.ChartOption.Type?.toLocaleLowerCase() === "pie") {
            let values: Datum[] = [];
            let labels: Datum[] = [];
            let fieldNames: Datum[] = [];
            let markerColors: Datum[] = [];

            if (Array.isArray(data)) {
                // data: [{"DataName":"CriticalAlert", "DataLabel": "Critical Alert", "DataValue": 16}, ...]
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];
                const customData: any[] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        fieldNames.push(datum[fieldName]);
                        labels.push(datum[fieldLabel]);
                        values.push(datum[fieldValue]);
                        dataColor?.FieldName && markerColors.push(datum[dataColor.FieldName]);

                        customData?.push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "PieChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "PieChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: datum[fieldLabel],
                            DataName: "PieChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "PieChartSelectedItem"
                        })

                        dataAttributes?.forEach((att: PanelFieldData) => {
                            //if (att.FieldName == fieldName) {
                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "PieChartSelectedItem"
                                })
                            //}
                        })
                    });

                    setAttributes(tmpAttributes);

                    plotDataSetValue([
                        {
                            values: values,
                            labels: labels,
                            type: panelData.ChartOption.Type?.toLocaleLowerCase(),
                            name: __t(panelData.ChartOption, "Title"),
                            hole: panelData.ChartOption.HoleSize || 0,
                            marker: {
                                colors: markerColors
                            },
                            ...(!panelData.ChartOption.ShowTextOnChart && {
                                textinfo: "none"
                            }),
                            ...(!isEmpty(panelData.ChartOption.TextInfo) && {
                                textinfo: panelData.ChartOption.TextInfo
                            }),
                            textposition: panelData.ChartOption.TextPosition || "inside",
                            customdata: customData,
                        } as PlotData,
                    ]);
                }
            } else {
                /*
                *   if panelData.Fields contains these values:
                *   [
                        {
                            "FieldName":"CriticalAlert",
                            "FieldLabel":"Critical Alert",
                            "DataType":"Number"
                        },
                        {
                            "FieldName":"HighAlert",
                            "FieldLabel":"High Alert",
                            "DataType":"Number"
                        },
                        {
                            "FieldName":"MediumAlert",
                            "FieldLabel":"Medium Alert",
                            "DataType":"Number"
                        },
                        {
                            "FieldName":"LowAlert",
                            "FieldLabel":"Low Alert",
                            "DataType":"Number"
                        }
                    ]
                *   then the API must return these data:
                    {
                        value: {
                            "CriticalAlert":16,
                            "HighAlert":1,
                            "MediumAlert":3,
                            "LowAlert":63
                        },
                        marker: {
                            color: {
                                "CriticalAlert":"rgb(56, 75, 126)",
                                "HighAlert":"rgb(18, 36, 37)",
                                "MediumAlert":"rgb(34, 53, 101)",
                                "LowAlert":"rgb(6, 4, 4)"
                            }
                        }
                    }
                */

                if (data.value) {
                    panelData.Fields?.forEach((field) => {
                        fieldNames.push(field.FieldName);
                        labels.push(field.FieldLabel);
                        values.push(data.value[field.FieldName]);
                        data.marker?.color && markerColors.push(data.marker.color[field.FieldName]);
                    });

                    plotDataSetValue([
                        {
                            values: values,
                            labels: labels,
                            type: panelData.ChartOption.Type?.toLocaleLowerCase(),
                            name: __t(panelData.ChartOption, "Title"),
                            hole: panelData.ChartOption.HoleSize || 0,
                            marker: {
                                colors: markerColors
                            },
                            ...(!panelData.ChartOption.ShowTextOnChart && {
                                textinfo: "none"
                            }),
                            ...(!isEmpty(panelData.ChartOption.TextInfo) && {
                                textinfo: panelData.ChartOption.TextInfo
                            }),
                            textposition: panelData.ChartOption.TextPosition || "inside",
                            customdata: fieldNames, // _id(s)
                        } as PlotData,
                    ]);
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "bar") {
            let barTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                /*
                    There's FieldsToChartConfigConversion property inside ChartOption
                    to route Backend's data to Plotly.js data values

                    "FieldsToChartConfigConversion" : {
                        "Fields" : [
                            {
                                "FieldName" : "UPC",
                                "ChartField" : "DataName"
                            },
                            {
                                "FieldName" : "SalesDate",
                                "ChartField" : "DataLabel"
                            },
                            {
                                "FieldName" : "SalesQuantity",
                                "ChartField" : "DataValue"
                            }
                        ]
                    }
                */
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];
                    const isHorizontal = (!isEmpty(panelData.ChartOption?.BarChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption?.BarChartOrientation?.toLocaleLowerCase() === "horizontal");

                    data?.forEach((datum: any, index: any) => {
                        //let tmpAttributes: any[][] = [];
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "BarChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "BarChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "BarChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                            //if (att.FieldName == fieldName) {

                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "BarChartSelectedItem"
                                })
                            //}
                        })

                        var unique = uniques.filter(onlyUnique);
                        if (rawData.hasOwnProperty(name)) {
                            if(isHorizontal) {
                                rawData[name].x.push(isEmpty(value) ? 0 : value);
                                rawData[name].y.push(label);
                                rawData[name].orientation = 'h';
                            } else {
                                rawData[name].x.push(label);
                                rawData[name].y.push(isEmpty(value) ? 0 : value);
                            }
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else {
                            rawData[name] = {
                                ...(isHorizontal ? {
                                    x: [isEmpty(value) ? 0 : value],
                                    y: [label],
                                    orientation: 'h'
                                }: {
                                    x: [label],
                                    y: [isEmpty(value) ? 0 : value],
                                }),
                                type: "bar",
                                ...(unique != null && unique.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    width: 1000*3600*24 / panelData.ChartOption.WidthDivider!
                                }),
                                name,
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    marker: {
                                        color: datum[dataColor?.FieldName!]
                                    },
                                },
                                text: [tooltipLabel],
                                hoverinfo: 'text',
                                transforms: [{
                                    type: 'sort',
                                    target: 'y',
                                    order: 'ascending'
                                }]
                            };
                        }
                    });

                    barTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    var uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            ...(!isEmpty(panelData.ChartOption.BarType) && {
                                barmode: panelData.ChartOption.BarType?.toLocaleLowerCase(),
                            }),
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                            [isHorizontal ? 'yaxis' : 'xaxis']: {
                                ...(dataLabel?.DataType === "Date" && {
                                    type: 'date'
                                }),
                                visible: isEmpty(dataName?.Hidden) ? true : !dataName?.Hidden,
                                showticklabels: isEmpty(dataLabel?.Hidden) ? true : !dataLabel?.Hidden,
                                ...(uniqueValues != null && uniqueValues.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    tickvals: uniqueValues
                                }),
                                // ...(dataLabel?.FieldFormat && {
                                //     /**
                                //      * Tick format for date & time on Plotly
                                //      * https://github.com/d3/d3-time-format/blob/master/README.md
                                //     */
                                //     tickformat: dataLabel.FieldFormat
                                // })
                            },
                        };
                    });

                    plotDataSetValue(barTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "line") {
            let lineTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );
                let dataDashLine = panelData.Fields.find(
                    (field) => field.ChartField === "DataDashLine"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "LineChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "LineChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "LineChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "LineChartSelectedItem"
                                })
                        })

                        var unique = uniques.filter(onlyUnique);
                        
                        if (rawData.hasOwnProperty(name)) {
                            rawData[name].x.push(label);
                            rawData[name].y.push(isEmpty(value) ? 0 : value);
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else {
                            rawData[name] = {
                                x: [label],
                                y: isEmpty(value) ? [0] : [value],
                                type: !isEmpty(panelData.ChartOption.LineType?.toLocaleLowerCase()) ? panelData.ChartOption.LineType?.toLocaleLowerCase() : 'scatter',
                                mode: !isEmpty(panelData.ChartOption.LineMode?.toLocaleLowerCase()) ? panelData.ChartOption.LineMode?.toLocaleLowerCase() : 'lines+markers',
                                ...(unique != null && unique.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    width: 1000*3600*24 / panelData.ChartOption.WidthDivider!
                                }),
                                name,
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    marker: {
                                        size: !isEmpty(panelData.ChartOption.MarkerSize) ? panelData.ChartOption.MarkerSize : 10,
                                        color: datum[dataColor?.FieldName!],
                                        line: {
                                            color: !isEmpty(panelData.ChartOption.LineMarkerColor?.toLocaleLowerCase()) ? panelData.ChartOption.LineMarkerColor?.toLocaleLowerCase() : datum[dataColor?.FieldName!],
                                            width: !isEmpty(panelData.ChartOption.LineMarkerWidth) ? panelData.ChartOption.LineMarkerWidth : 0,
                                        }
                                    },
                                    text: [tooltipLabel],
                                    hoverinfo: 'text',
                                    line: {
                                        ...(!isEmpty(dataDashLine) && !isEmpty(datum[dataDashLine?.FieldName!])) && {
                                            dash: datum[dataDashLine?.FieldName!]
                                        },
                                        shape: !isEmpty(panelData.ChartOption.LineShape?.toLocaleLowerCase()) ? panelData.ChartOption.LineShape?.toLocaleLowerCase() : 'linear',
                                        color: datum[dataColor?.FieldName!],
                                        width: !isEmpty(panelData.ChartOption.LineSize) ? panelData.ChartOption.LineSize : 1 
                                    },
                                },
                                //No need to transform, because data is already sorted
                                // transforms: [{
                                //     type: 'sort',
                                //     target: 'y',
                                //     order: 'ascending'
                                // }]
                            };
                        }
                    });

                    lineTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    var uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                            xaxis: {
                                ...(dataLabel?.DataType === "Date" && {
                                    type: 'date'
                                }),
                                visible: true,
                                showticklabels: true,
                                ...(uniqueValues != null && uniqueValues.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    tickvals: uniqueValues
                                }),
                            },
                        };
                    });

                    plotDataSetValue(lineTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "bubble") {
            let lineTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );
                let dataMarkerSize = panelData.Fields.find(
                    (field) => field.ChartField === "DataMarkerSize"
                );
                let dataMarkerSymbol = panelData.Fields.find(
                    (field) => field.ChartField === "DataMarkerSymbol"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "BubbleChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "BubbleChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "BubbleChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "BubbleChartSelectedItem"
                                })
                        })

                        var unique = uniques.filter(onlyUnique);
                        
                        if (rawData.hasOwnProperty(name)) {
                            rawData[name].x.push(label);
                            rawData[name].y.push(isEmpty(value) ? 0 : value);
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else {
                            rawData[name] = {
                                x: [label],
                                y: isEmpty(value) ? [0] : [value],
                                mode: "markers",
                                ...(unique != null && unique.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    width: 1000*3600*24 / panelData.ChartOption.WidthDivider!
                                }),
                                name,
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    marker: {
                                        ...(!isEmpty(dataMarkerSize) && !isEmpty(datum[dataMarkerSize?.FieldName!])) && {
                                            size: datum[dataMarkerSize?.FieldName!],
                                        },
                                        ...(isEmpty(dataMarkerSize) && isEmpty(datum[dataMarkerSize?.FieldName!])) && {
                                            size: 20, 
                                        },
                                        color: datum[dataColor?.FieldName!],
                                        opacity: !isEmpty(panelData.ChartOption.BubbleOpacity) ? panelData.ChartOption.BubbleOpacity : 1,
                                        ...(!isEmpty(dataMarkerSymbol) && !isEmpty(datum[dataMarkerSymbol?.FieldName!])) && {
                                            symbol: datum[dataMarkerSymbol?.FieldName!],
                                        },
                                        line: {
                                            color: !isEmpty(panelData.ChartOption.BubbleLineColor?.toLocaleLowerCase()) ? panelData.ChartOption.BubbleLineColor?.toLocaleLowerCase() : "#eaeaea",
                                            width: !isEmpty(panelData.ChartOption.BubbleLineWidth) ? panelData.ChartOption.BubbleLineWidth : 0,
                                        }
                                    },
                                    text: [tooltipLabel],
                                    hoverinfo: 'text',
                                },
                                type: "scatter",
                                transforms: [{
                                    type: 'sort',
                                    target: 'y',
                                    order: 'ascending'
                                }]
                            };
                        }
                    });

                    lineTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    const uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                            xaxis: {
                                ...(dataLabel?.DataType === "Date" && {
                                    type: 'date'
                                }),
                                visible: true,
                                showticklabels: true,
                                ...(uniqueValues != null && uniqueValues.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                    tickvals: uniqueValues
                                }),
                            },
                        };
                    });

                    plotDataSetValue(lineTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "box") {
            let boxTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];

                    let val: any[] = [];

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "BoxChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "BoxChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "BoxChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "BoxChartSelectedItem"
                                })
                        })

                        var unique = uniques.filter(onlyUnique);
                        
                        val.push(value);
                        
                        if (rawData.hasOwnProperty(name)) {
                            if (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") {
                                rawData[name].x.push(isEmpty(value) ? 0 : value);
                            } else {
                                rawData[name].y.push(isEmpty(value) ? 0 : value);
                            }
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else { 
                            rawData[name] = {
                                ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") && {
                                    x: isEmpty(value) ? [0] : [value],
                                    y: label,

                                }),
                                ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() !== "horizontal") && {
                                    y: isEmpty(value) ? [0] : [value],
                                    x: label,
                                }),
                                name: name,
                                type: 'box',
                                boxpoints: !isEmpty(panelData.ChartOption.BoxChartBoxPoint?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartBoxPoint?.toLocaleLowerCase() : false,
                                jitter: !isEmpty(panelData.ChartOption.BoxChartJitter) ? panelData.ChartOption.BoxChartJitter : 0,
                                whiskerwidth: !isEmpty(panelData.ChartOption.BoxChartWhiskerWidth) ? panelData.ChartOption.BoxChartWhiskerWidth : 0,
                                fillcolor: 'cls',
                                line: {
                                    width: !isEmpty(panelData.ChartOption.BoxChartLineWidth) ? panelData.ChartOption.BoxChartLineWidth : 0,
                                },
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    marker: {
                                        color: datum[dataColor?.FieldName!],
                                        size: !isEmpty(panelData.ChartOption.BoxChartMarkerSize) ? panelData.ChartOption.BoxChartMarkerSize : 4,
                                        outliercolor: !isEmpty(panelData.ChartOption.BoxChartMarkerOutlierColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartMarkerOutlierColor?.toLocaleLowerCase() : "",
                                        line: {
                                          outliercolor: !isEmpty(panelData.ChartOption.BoxChartMarkerLineOutlierColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartMarkerLineOutlierColor?.toLocaleLowerCase() : "",
                                          outlierwidth: !isEmpty(panelData.ChartOption.BoxChartMarkerLineOutlierWidth) ? panelData.ChartOption.BoxChartMarkerLineOutlierWidth : 0,
                                        }
                                    },
                                },
                                ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") && {
                                    orientation: 'h',
                                }),
                                ...( (!isEmpty(panelData.ChartOption.BoxChartBoxMean)) && {
                                    boxmean: panelData.ChartOption.BoxChartBoxMean,
                                }),
                            };
                        }
                    });

                    boxTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    var uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                            ...( (!isEmpty(panelData.ChartOption.BoxChartBoxMode?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartBoxMode?.toLocaleLowerCase() === "group") && {
                                boxmode: "group",
                            }),
                            ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") && {
                                yaxis: {
                                    showgrid: !isEmpty(panelData.ChartOption.BoxChartShowGrid) ? panelData.ChartOption.BoxChartShowGrid : false,
                                    zeroline: !isEmpty(panelData.ChartOption.BoxChartZeroLine) ? panelData.ChartOption.BoxChartZeroLine : false,
                                    gridcolor: !isEmpty(panelData.ChartOption.BoxChartgridcolor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartgridcolor?.toLocaleLowerCase() : "",
                                    gridwidth: !isEmpty(panelData.ChartOption.BoxChartGridWidth) ? panelData.ChartOption.BoxChartGridWidth : 0,
                                    zerolinecolor: !isEmpty(panelData.ChartOption.BoxChartZeroLineColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartZeroLineColor?.toLocaleLowerCase() : "",
                                    zerolinewidth: !isEmpty(panelData.ChartOption.BoxChartZeroLineWidth) ? panelData.ChartOption.BoxChartZeroLineWidth : 0,
                                },
                            }),
                            ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() !== "horizontal") && {
                                xaxis: {
                                    showgrid: !isEmpty(panelData.ChartOption.BoxChartShowGrid) ? panelData.ChartOption.BoxChartShowGrid : false,
                                    zeroline: !isEmpty(panelData.ChartOption.BoxChartZeroLine) ? panelData.ChartOption.BoxChartZeroLine : false,
                                    gridcolor: !isEmpty(panelData.ChartOption.BoxChartgridcolor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartgridcolor?.toLocaleLowerCase() : "",
                                    gridwidth: !isEmpty(panelData.ChartOption.BoxChartGridWidth) ? panelData.ChartOption.BoxChartGridWidth : 0,
                                    zerolinecolor: !isEmpty(panelData.ChartOption.BoxChartZeroLineColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartZeroLineColor?.toLocaleLowerCase() : "",
                                    zerolinewidth: !isEmpty(panelData.ChartOption.BoxChartZeroLineWidth) ? panelData.ChartOption.BoxChartZeroLineWidth : 0,
                                },
                            }),
                            margin: {
                                l: !isEmpty(panelData.ChartOption.BoxChartMarginLeft) ? panelData.ChartOption.BoxChartMarginLeft : 45,
                                r: !isEmpty(panelData.ChartOption.BoxChartMarginRight) ? panelData.ChartOption.BoxChartMarginRight : 45,
                                b: !isEmpty(panelData.ChartOption.BoxChartMarginBottom) ? panelData.ChartOption.BoxChartMarginBottom : 45,
                                t: !isEmpty(panelData.ChartOption.BoxChartMarginTop) ? panelData.ChartOption.BoxChartMarginTop : 45,
                            },
                            paper_bgcolor: !isEmpty(panelData.ChartOption.BoxChartPaperBgColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartPaperBgColor?.toLocaleLowerCase() : "rgba(255,255,255,0)",
                            plot_bgcolor: !isEmpty(panelData.ChartOption.BoxChartPlotBgColor?.toLocaleLowerCase()) ? panelData.ChartOption.BoxChartPlotBgColor?.toLocaleLowerCase() : "rgba(255,255,255,0)",
                            showlegend: !isEmpty(panelData.ChartOption.BoxChartShowLegend) ? panelData.ChartOption.BoxChartShowLegend : false,
                        };
                    });

                    plotDataSetValue(boxTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        }  else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "multiplechart") {
            let boxTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataChartType = panelData.Fields.find(
                    (field) => field.ChartField === "DataChartType"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];

                    let val: any[] = [];

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "MultipleChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "MultipleChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "MultipleChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "MultipleChartSelectedItem"
                                })
                        })

                        var unique = uniques.filter(onlyUnique);
                        
                        val.push(value);
                        
                        if (rawData.hasOwnProperty(name)) {
                            if (!isEmpty(dataChartType) && !isEmpty(datum[dataChartType?.FieldName!] !== 'box')) {
                                rawData[name].x.push(label);
                                rawData[name].y.push(isEmpty(value) ? 0 : value);
                            } else {
                                if (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") {
                                    rawData[name].x.push(isEmpty(value) ? 0 : value);
                                } else {
                                    rawData[name].y.push(isEmpty(value) ? 0 : value);
                                }
                            }
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else { 
                            rawData[name] = {
                                ...(!isEmpty(dataChartType) && !isEmpty(datum[dataChartType?.FieldName!] === 'box')) && {
                                    ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() === "horizontal") && {
                                        x: isEmpty(value) ? [0] : [value],
                                        y: label,

                                    }),
                                    ...( (!isEmpty(panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.BoxChartOrientation?.toLocaleLowerCase() !== "horizontal") && {
                                        y: isEmpty(value) ? [0] : [value],
                                        x: label,
                                    }),
                                },
                                ...(!isEmpty(dataChartType) && !isEmpty(datum[dataChartType?.FieldName!] !== 'box')) && {
                                    x: [label],
                                    y: isEmpty(value) ? [0] : [value],
                                    ...(unique != null && unique.length <= panelData.ChartOption.CountLimitBeforeAuto! && {
                                        width: 1000*3600*24 / panelData.ChartOption.WidthDivider!
                                    }),
                                },
                                name: name,
                                text: [tooltipLabel],
                                hoverinfo: 'text',
                                type: !isEmpty(dataChartType) && !isEmpty(datum[dataChartType?.FieldName!]) ? datum[dataChartType?.FieldName!] : "line" ,
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    ...(!isEmpty(dataChartType) && !isEmpty(datum[dataChartType?.FieldName!] === 'line')) && {
                                        mode: !isEmpty(panelData.ChartOption.LineMode?.toLocaleLowerCase()) ? panelData.ChartOption.LineMode?.toLocaleLowerCase() : 'lines+markers',
                                        line: {
                                            shape: !isEmpty(panelData.ChartOption.LineShape?.toLocaleLowerCase()) ? panelData.ChartOption.LineShape?.toLocaleLowerCase() : 'linear',
                                            color: datum[dataColor?.FieldName!],
                                            width: !isEmpty(panelData.ChartOption.LineSize) ? panelData.ChartOption.LineSize : 1 
                                        },
                                    }
                                },

                                transforms: [{
                                    type: 'sort',
                                    target: 'y',
                                    order: 'ascending'
                                }]
                            };
                        }
                    });

                    boxTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    var uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                        };
                    });

                    plotDataSetValue(boxTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "errorchart") {
            let boxTraces: PlotData[] = [];

            if (Array.isArray(data)) {
                let rawData: any = {};
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    const uniques: any[] = [];

                    let val: any[] = [];
                    let valAssymetric: any[] = [];

                    data?.forEach((datum: any, index: any) => {
                        tmpAttributes[index] = []
                        const name = datum[fieldName];
                        const value = datum[fieldValue];

                        let label = datum[fieldLabel];
                        let labelValue = label;
                        let tooltipLabel = "";
                        // #region parsing

                        // Convert non utc string to utc
                        if (dataLabel?.DataType === "Date" && dataLabel?.FieldFormat && !isStringUTC(label)) {
                            label = moment.utc(label, "MM/DD/YYYY hh:mm a").toISOString();
                            if (dataLabel.FieldFormat) {
                                labelValue = moment.utc(labelValue).format('YYYY-MM-DD')
                            }
                        }
                        // #endregion

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "MultipleChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: labelValue,
                            DataName: "MultipleChartSelectedItem"
                        })

                        uniques.push(labelValue)

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "MultipleChartSelectedItem"
                        })

                        if (!dataName?.Hidden) {
                            tooltipLabel += isEmpty(dataName?.FieldLabel) ? "" : dataName?.FieldLabel + ": ";
                            tooltipLabel += name + "<br>";
                        }
                        if (!dataLabel?.Hidden) {
                            tooltipLabel += isEmpty(dataLabel?.FieldLabel) ? "" : dataLabel?.FieldLabel + ": ";
                            tooltipLabel += labelValue + "<br>";
                        }
                        if (!dataValue?.Hidden) {
                            tooltipLabel += isEmpty(dataValue?.FieldLabel) ? "" : dataValue?.FieldLabel + ": ";
                            tooltipLabel += (value || "") + "<br>";
                        }

                        dataAttributes?.forEach((att: PanelFieldData) => {
                                if (!att?.Hidden) {
                                    tooltipLabel += isEmpty(att?.FieldLabel) ? "" : att?.FieldLabel + ": ";
                                    tooltipLabel += (datum[att.FieldName!] || "") + "<br>";
                                }

                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "MultipleChartSelectedItem"
                                })
                        })

                        var unique = uniques.filter(onlyUnique);
                        
                        val.push(value);
                        
                        if (rawData.hasOwnProperty(name)) {
                            if (!isEmpty(panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase() === 'horizontal') {
                                rawData[name].y.push(label);
                                rawData[name].x.push(isEmpty(value) ? 0 : value);
                            } else {
                                rawData[name].x.push(label);
                                rawData[name].y.push(isEmpty(value) ? 0 : value);
                            };
                            if (isEmpty(rawData[name].text)) {
                                rawData[name].text = []
                            }
                            rawData[name].text.push(tooltipLabel);
                        } else { 
                            rawData[name] = {
                                ...(!isEmpty(panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase() === 'horizontal') && {
                                    y: [label],
                                    x: isEmpty(value) ? [0] : [value],
                                },
                                ...(!isEmpty(panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase() !== 'horizontal') && {
                                    x: [label],
                                    y: isEmpty(value) ? [0] : [value],
                                },
                                mode: !isEmpty(panelData.ChartOption.ErrorChartMode?.toLocaleLowerCase()) ? panelData.ChartOption.ErrorChartMode?.toLocaleLowerCase() : '',
                                ...(!isEmpty(panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase() !== 'horizontal') && {
                                    error_y: {
                                        type: !isEmpty(panelData.ChartOption.ErrorChartDataErrorAlign?.toLocaleLowerCase()) ? panelData.ChartOption.ErrorChartDataErrorAlign?.toLocaleLowerCase() : 'data',
                                        array: val,
                                        ...(panelData.ChartOption.ErrorChartIsAssymetric) && {
                                            arrayminus: val,
                                        },
                                        visible: true,
                                        symmetric: panelData.ChartOption.ErrorChartIsAssymetric ? false : true,
                                    },
                                },   
                                ...(!isEmpty(panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase()) && panelData.ChartOption.ErrorChartOrientation?.toLocaleLowerCase() === 'horizontal') && {
                                    error_x: {
                                        type: 'percent',
                                        value: 10
                                    },
                                },
                                type: !isEmpty(panelData.ChartOption.ErrorChartType?.toLocaleLowerCase()) ? panelData.ChartOption.ErrorChartType?.toLocaleLowerCase() : 'scatter',
                                name: name,
                                ...(!isEmpty(dataColor) && !isEmpty(datum[dataColor?.FieldName!])) && {
                                    marker: {
                                        color: datum[dataColor?.FieldName!],
                                        size: !isEmpty(panelData.ChartOption.ErrorChartMarkerSize) ? panelData.ChartOption.ErrorChartMarkerSize : 6,
                                    },
                                },

                                transforms: [{
                                    type: 'sort',
                                    target: 'y',
                                    order: 'ascending'
                                }]
                            };
                        }
                    });

                    boxTraces = Object.keys(rawData).map(
                        (key: any) => rawData[key]
                    );

                    setAttributes(tmpAttributes);

                    var uniqueValues = uniques.filter(onlyUnique);

                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            font: {
                                family: 'Nunito',
                                size: 12
                            },
                            hoverlabel: {namelength :-1},
                            hovermode:"closest",
                        };
                    });

                    plotDataSetValue(boxTraces);
                } else {
                    panelMessageSetValue(
                        "Data to chart conversion failed, please check your configuration."
                    );
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "tree") {
            if (Array.isArray(data)) {

                let dataPrimaryKey = panelData.Fields.find(
                    (field) => field.ChartField === "DataPrimaryKey"
                );
                let dataForeignKey = panelData.Fields.find(
                    (field) => field.ChartField === "DataForeignKey"
                );
                let dataPosition = panelData.Fields.find(
                    (field) => field.ChartField === "DataPosition"
                );

                let dataShortLabel = panelData.Fields.find((
                    field) => field.ChartField === "DataShortLabel"
                );

                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                if (
                    dataPrimaryKey &&
                    dataForeignKey &&
                    dataPosition &&
                    dataLabel &&
                    dataValue &&
                    dataPrimaryKey?.FieldName &&
                    dataForeignKey?.FieldName &&
                    dataPosition?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {

                    const unflatten = (items: any, id = null, link = 'DataForeignKey') =>
                        items
                            .filter((item: any) => item[link] === id)
                            .map((item: any) => ({ ...item, children: unflatten(items, item.DataPrimaryKey) }));


                    for (let index = 0; index < data.length; index++) {
                        const child = data[index];
                        let newElement = {
                            DataPrimaryKey: child[dataPrimaryKey?.FieldName!],
                            DataForeignKey: child[dataForeignKey?.FieldName!],
                            DataShortLabel: child[dataShortLabel?.FieldName!],
                            DataLabel: child[dataLabel?.FieldName!],
                            DataValue: child[dataValue?.FieldName!],
                            DataPosition: child[dataPosition?.FieldName!],
                            DataColor: child[dataColor?.FieldName!],
                            children: []
                        } as any

                        if (!isEmpty(dataAttributes)) {
                            dataAttributes.forEach((attribute) => {
                                if (!(newElement.hasOwnProperty(attribute.FieldName))) {
                                    Object.assign(newElement, {[attribute.FieldName] : null})
                                }
                                 newElement[attribute.FieldName] = child[attribute.FieldName]
                            });
                        }

                        data[index] = newElement;
                    }

                    var tree = unflatten(data);
                    treeDataSetValue(tree);
                }

            }

        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "map") {
            if (Array.isArray(data)) {

                let dataLatitude = panelData.Fields.find(
                    (field) => field.ChartField === "Latitude"
                );

                let dataLongitude = panelData.Fields.find(
                    (field) => field.ChartField === "Longitude"
                );

                let dataText = panelData.Fields.find(
                    (field) => field.ChartField === "DataText"
                );

                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataMarkerSize = panelData.Fields.find(
                    (field) => field.ChartField === "MarkerSize"
                );

                let dataBorderColor = panelData.Fields.find(
                    (field) => field.ChartField === "BorderColor"
                );

                let dataValue = panelData.Fields.filter(
                    (field) => field.ChartField === "DataValue"
                );                

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                if ((dataLatitude && dataLongitude)
                ) {

                    for (let index = 0; index < data.length; index++) {
                        const child = data[index];
                        let newElement = {
                            Latitude: child[dataLatitude?.FieldName!],
                            Longitude: child[dataLongitude?.FieldName!],
                            DataText: child[dataText?.FieldName!],
                            Color: child[dataColor?.FieldName!] || 'fuchsia',
                            MarkerSize: child[dataMarkerSize?.FieldName!] || 4,
                            BorderColor: child[dataBorderColor?.FieldName!] || "white",
                        } as any

                        if (dataValue && Array.isArray(dataValue)) {
                            dataValue.forEach(item => {
                                for (const key in item) {
                                    Object.assign(newElement, {
                                     [item.FieldName] : child[item.FieldName]
                                    })
                                  
                                }
                            });
                         }

                         if (!isEmpty(dataAttributes)) {
                            dataAttributes.forEach((attribute) => {
                                if (!(newElement.hasOwnProperty(attribute.FieldName))) {
                                    Object.assign(newElement, {[attribute.FieldName] : null})
                                }
                                 newElement[attribute.FieldName] = child[attribute.FieldName]
                            });
                        }

                        data[index] = newElement;
                    }
                    setTimeout(() => {
                        dispatch({
                            type: "API_GET_TABLE_DATA_SUCCESS",
                            payload: {
                                targetPanel: chartName,
                                done: true,
                                data: []
                            }
                        });
                    }, 50);
                    mapDataSetValue(data);

                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "sunburst") {
            let values: Datum[] = [];
            let labels: Datum[] = [];
            let parents: string[] = [...Array(panelData.ChartOption?.SunburstParentCount || 1).fill("")];
            let fieldNames: Datum[] = [];
            let markerColors: Datum[] = [];

            if (Array.isArray(data)) {
                // data: [{"DataName":"CriticalAlert", "DataLabel": "Critical Alert", "DataValue": 16}, ...]
                let dataName = panelData.Fields.find(
                    (field) => field.ChartField === "DataName"
                );
                let dataLabel = panelData.Fields.find(
                    (field) => field.ChartField === "DataLabel"
                );
                let dataValue = panelData.Fields.find(
                    (field) => field.ChartField === "DataValue"
                );
                let dataColor = panelData.Fields.find(
                    (field) => field.ChartField === "DataColor"
                );

                let dataAttributes = panelData.Fields.filter(
                    (field) => field.ChartField === "DataAttribute"
                );

                let tmpAttributes: any[][] = [];
                const customData: any[] = [];

                if (
                    dataName &&
                    dataLabel &&
                    dataValue &&
                    dataName?.FieldName &&
                    dataLabel?.FieldName &&
                    dataValue?.FieldName
                ) {
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;

                    [...data].sort((a, b) => a[fieldValue] > b[fieldValue] ? -1 : 0).forEach((datum: any, index: any, arr) => {
                        tmpAttributes[index] = []
                        fieldNames.push(datum[fieldName]);
                        labels.push(datum[fieldLabel]);
                        values.push(datum[fieldValue]);
                        dataColor?.FieldName && markerColors.push(datum[dataColor.FieldName]);

                        if(parents.length < (arr.length - (panelData.ChartOption?.SunburstParentCount || 1))) {
                            parents.push(datum[fieldLabel]);
                            parents.push(arr[index][fieldLabel]);
                        }

                        customData?.push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "SunburstChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "SunburstChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldLabel,
                            EmittedValue: datum[fieldLabel],
                            DataName: "SunburstChartSelectedItem"
                        })

                        tmpAttributes[index].push({
                            DataSource: fieldValue,
                            EmittedValue: datum[fieldValue],
                            DataName: "SunburstChartSelectedItem"
                        })

                        dataAttributes?.forEach((att: PanelFieldData) => {
                            //if (att.FieldName == fieldName) {
                                tmpAttributes[index].push({
                                    DataSource: att.FieldName!,
                                    EmittedValue: datum[att.FieldName!],
                                    DataName: "SunburstChartSelectedItem"
                                })
                            //}
                        })
                    });
                    setAttributes(tmpAttributes);
                    plotDataSetValue([  
                        {
                            type: "sunburst",
                            labels: labels,  
                            parents: parents,
                            values: values,
                            marker: {
                                line: {
                                    width: panelData.ChartOption.SunburstMarkerLineWidth || 2,
                                    color: panelData.ChartOption.SunburstMarkerLineColor,
                                },
                                colors: markerColors as any[]
                            },
                            customdata: customData,
                            textposition: (panelData.ChartOption.TextPosition || "inside" as any),
                        },
                    ]);
                }
            } else {
                if (data.value) {
                    panelData.Fields?.forEach((field) => {
                        fieldNames.push(field.FieldName);
                        labels.push(field.FieldLabel);
                        parents.push(field.FieldLabel);
                        values.push(data.value[field.FieldName]);
                        data.marker?.color && markerColors.push(data.marker.color[field.FieldName]);
                    });  
                    plotDataSetValue([
                        {
                            type: "sunburst",
                            labels: labels,
                            parents: parents,
                            values: values,
                            marker: {
                                colors: markerColors as any[],
                                line: {
                                    width: panelData.ChartOption.SunburstMarkerLineWidth || 2,
                                    color: panelData.ChartOption.SunburstMarkerLineColor,
                                },
                            },
                            customdata: fieldNames,
                            textposition: (panelData.ChartOption.TextPosition || "inside" as any),
                            name: __t(panelData.ChartOption, "Title"),
                        } as PlotData,
                    ]);
                }
            }
            plotLayoutSetValue((plotLayout:any) => {
                return {
                    ...plotLayout,
                    font: {
                        family: 'Nunito',
                        size: 12
                    },
                    hoverlabel: {namelength :-1},
                    hovermode:"closest",
                };
            });
        } else if(panelData.ChartOption.Type?.toLocaleLowerCase() === "splom") {
            // Sploom Chart with Plotly
            
            let dimensions = [{
                    label: "",
                    values: [] as any[],
                }];
            let hoverText: string[] = [];
            let colors:number[] = [];
            dimensions.pop();
            if(Array.isArray(data)){
                let dataName = panelData.Fields.find((field) => field.ChartField === "DataName");
                let dataLabel = panelData.Fields.find((field) => field.ChartField === "DataLabel");
                let dataValue = panelData.Fields.find((field) => field.ChartField === "DataValue");
                const customData: any[] = [];
                if(dataName && dataLabel && dataValue && dataName?.FieldName && dataLabel?.FieldName && dataValue?.FieldName){
                    const fieldName = dataName.FieldName!;
                    const fieldLabel = dataLabel.FieldName!;
                    const fieldValue = dataValue.FieldName!;
                    
                    [...data].forEach((datum, index) => {
                        if(datum[fieldName] && datum[fieldLabel] && datum[fieldValue]){
                            let values = datum[fieldValue];
                            dimensions.push({
                                label: datum[fieldLabel],
                                values: (dimensions?.[index]?.values || [])as any[],
                            });
                            if(dataValue?.DataType === "JSON" && typeof values === "string"){
                                values = JSON.parse(values);
                            }
                            dimensions[index].label = datum[fieldLabel];
                            if(Array.isArray(values)){  
                                values.forEach((value) => {
                                    colors.push(value?.Color);
                                    hoverText.push(value.HoverText);
                                    dimensions[index].values.push(value.Value);
                                });
                            }
                        }
                        customData?.push({
                            DataSource: fieldName,
                            EmittedValue: datum[fieldName],
                            DataName: "SploomChartSelectedItem"
                        });
                    });
                }
                var pl_colorscale = panelData.ChartOption?.SplomMarkerColorScale || [
                    [0.0, '#119dff'],
                    [0.5, '#119dff'],
                    [0.5, '#ef553b'],
                    [1, '#ef553b']
                  ]
                  var axis = () => ({
                    showline: panelData.ChartOption?.SplomLayoutAxis?.ShowLine || false,
                    zeroline: panelData.ChartOption?.SplomLayoutAxis?.ZeroLine ||false,
                    gridcolor: panelData.ChartOption?.SplomLayoutAxis?.GridColor ||'#ffff',
                    ticklen: panelData.ChartOption?.SplomLayoutAxis?.TickLen || 2,
                    tickfont:{size:panelData.ChartOption?.SplomLayoutAxis?.TickFontSize || 10},
                    titlefont:{size:panelData.ChartOption?.SplomLayoutAxis?.TitleFontSize ||12}
                  })
                plotDataSetValue([
                    {
                        type: 'splom',
                        dimensions: dimensions,
                        text: hoverText,
                        marker: {
                            color: colors, 
                            colorscale: pl_colorscale,
                            size: panelData.ChartOption?.SplomMarkerSize || 5,
                            line: {
                                color: panelData.ChartOption?.SplomMarkerLinerColor || 'white',
                                width: panelData.ChartOption?.SplomMarkerLineWidth || 0.5
                            }
                        },
                    } as any
                ]);

                plotLayoutSetValue((plotLayout:any) => {
                    return {
                        ...plotLayout,
                        height: panelData.ChartOption?.SplomLayoutHeight ||1000,
                        width: panelData.ChartOption?.SplomLayoutWidth || 1000,
                        autosize: panelData.ChartOption?.SplomLayoutAutoSize || false,
                        hovermode:'closest',
                        dragmode:'select',
                        plot_bgcolor: panelData.ChartOption?.SplomLayoutPlotBackgroundColor || 'rgba(240,240,240, 0.95)',
                        xaxis:axis(),
                        yaxis:axis(),
                        xaxis2:axis(),
                        xaxis3:axis(),
                        xaxis4:axis(),
                        xaxis5:axis(),
                        xaxis6:axis(),
                        xaxis7:axis(),
                        xaxis8:axis(),
                        yaxis2:axis(),
                        yaxis3:axis(),
                        yaxis4:axis(),
                        yaxis5:axis(),
                        yaxis6:axis(),
                        yaxis7:axis(),
                        yaxis8:axis()
                    };
                });
            } else {
                console.info("Data Is Not Array")
            }

        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "choropleth") {

            let dataLocation = panelData.Fields.find((field) => field.ChartField === "DataLocation");
            let dataLabel = panelData.Fields.find((field) => field.ChartField === "DataLabel");
            let dataValue = panelData.Fields.find((field) => field.ChartField === "DataValue");
            let dataLatitude = panelData.Fields.find((field) => field.ChartField === "DataLatitude");
            let dataLongitude = panelData.Fields.find((field) => field.ChartField === "DataLongitude");


            // Utility function to group data by a key
            const groupBy = (arr:any, key:string) => {
                return arr.reduce((acc:any, obj:any) => {
                    const property = obj[key];
                    acc[property] = acc[property] || [];
                    acc[property].push(obj);
                    return acc;
                }, {});
            }

            // Function to convert flat JSON to GeoJSON
            const convertToGeoJSON = (flatJSONData: any) => {
                const geoJSON = {
                    type: "FeatureCollection",
                    features: [] as any
                };

                const groupedData = groupBy(flatJSONData, dataLocation?.FieldName!);

                for (const id in groupedData) {
                    const group = groupedData[id];
                    const name = !isEmpty(group[0]) ? group[0][dataLabel?.FieldName!] : '';
                    
                    const coordinates = group.map((data:any) => [
                        parseFloat(data[dataLongitude?.FieldName!].replace(',', '.')), 
                        parseFloat(data[dataLatitude?.FieldName!].replace(',', '.'))
                    ]);

                    // Creating the GeoJSON feature object
                    const feature = {
                        type: "Feature",
                        id: id,
                        properties: {
                            name: name
                        },
                        geometry: {
                            type: "Polygon",
                            coordinates: [coordinates]
                        }
                    };

                    geoJSON.features?.push(feature);
                }

                return geoJSON;
            }

            if (dataLocation && dataLabel && dataValue && dataLocation?.FieldName && dataLabel?.FieldName && dataValue?.FieldName) {

                const locations = data.map((row: any) => row[dataLocation?.FieldName!])
                const labels = data.map((row: any) => row[dataLabel?.FieldName!])
                const values = data.map((row: any) => row[dataValue?.FieldName!])

                if (dataLatitude && dataLongitude) {
                    const features = convertToGeoJSON(data)

                    plotDataSetValue([
                        {
                            type: "choroplethmapbox",
                            locations: [...locations],
                            z: [...values],
                            text: [...labels],
                            rawData: data,
                            coloraxis: "coloraxis",
                            geojson: features
                        } as any
                    ]);
    
                    plotLayoutSetValue((plotLayout: any) => {
                        return {
                            ...plotLayout,
                            mapbox: { style: "streets", center: { lon: -86, lat: 33 }, zoom: 5 },
                            height: (parseInt(panelData.ChartOption?.Height ?? '0') * 0.9) || 500,
                            width: (parseInt(panelData.ChartOption?.Width ?? '0') * 0.9) || 500,
                            title: __t(panelData.ChartOption, "Title"),
                            margin: {
                                l: 0,
                                r: 0,
                                b: 0,
                                t: isEmpty(panelData.ChartOption.Title) ? 0 : 50,
                                pad: 0,
                                autoexpand: true
                            }
                        };
                    });
                    
                } else {
                    plotDataSetValue([
                        {
                            type: "choropleth",
                            locations: [...locations],
                            z: [...values],
                            text: [...labels],
                            rawData: data,
                            locationmode: panelData.ChartOption?.LocationMode || "ISO-3",
                            colorscale: panelData.ChartOption?.ColorScale || "Blues",
                            showscale: panelData.ChartOption?.ShowScale || false,
                            reversescale: panelData.ChartOption?.ReverseScale || true,
                            marker: {
                                line: {
                                    color: panelData.ChartOption?.LineMarkerColor || "rgb(255,255,255)",
                                    width: panelData.ChartOption?.LineMarkerWidth || 0.5
                                }
                            }
                        } as any
                    ]);

                    plotLayoutSetValue((plotLayout: any) => {
                        return {
                            ...plotLayout,
                            height: (parseInt(panelData.ChartOption?.Height ?? '0') * 0.9) || 500,
                            width: (parseInt(panelData.ChartOption?.Width ?? '0') * 0.9) || 500,
                            title: __t(panelData.ChartOption, "Title"),
                            margin: {
                                l: 0,
                                r: 0,
                                b: 0,
                                t: isEmpty(panelData.ChartOption.Title) ? 0 : 50,
                                pad: 0,
                                autoexpand: true
                            },
                            geo: {
                                scope: panelData.ChartOption?.Scope || "world",
                                showframe: false,
                                showcoastlines: false,
                                showland: true,
                                landcolor: "#dae3eb",
                                showcountries: true,
                                countrycolor: "white",
                                bgwidth: '100%'
                            }
                        };
                    });
                }
            }
        } else if (panelData.ChartOption.Type?.toLocaleLowerCase() === "scattergeo") {

            let dataLocation = panelData.Fields.find((field) => field.ChartField === "DataLocation");
            let dataLabel = panelData.Fields.find((field) => field.ChartField === "DataLabel");
            let dataValue = panelData.Fields.find((field) => field.ChartField === "DataValue");
            let dataLatitude = panelData.Fields.find((field) => field.ChartField === "DataLatitude");
            let dataLongitude = panelData.Fields.find((field) => field.ChartField === "DataLongitude");
            let dataColor = panelData.Fields.find((field) => field.ChartField === "DataColor");
            let dataMarkerSize = panelData.Fields.find((field) => field.ChartField === "MarkerSize");
            let dataBorderColor = panelData.Fields.find((field) => field.ChartField === "BorderColor");

            if (dataLabel && dataValue && dataLatitude && dataLongitude && dataLabel?.FieldName && dataValue?.FieldName) {
                
                mapDataSetValue(data);

                const locations = !isEmpty(dataLocation) ? data.map((row: any) => row[dataLocation?.FieldName!]) : undefined
                const labels = data.map((row: any) => row[dataLabel?.FieldName!])
                const values = data.map((row: any) => row[dataValue?.FieldName!])
                const latitudes = data.map((row: any) => parseFloat(row[dataLatitude?.FieldName!].replace(',', '.')))
                const longitudes = data.map((row: any) => parseFloat(row[dataLongitude?.FieldName!].replace(',', '.')))

                const citySize = [];
                const hoverText = [];
                const divisorScale = 50000;

                const dataSet = []

                // Function to generate a random number between min and max (inclusive)
                const getRandomNumber = (min:number, max:number) => {
                    return Math.floor(Math.random() * (max - min + 1) + min);
                }

                // Function to generate a random RGB color
                const generateRandomColor = () => {
                    const red = getRandomNumber(0, 255);
                    const green = getRandomNumber(0, 255);
                    const blue = getRandomNumber(0, 255);

                    return `rgb(${red}, ${green}, ${blue})`;
                }

        
                for ( var i = 0 ; i < values.length; i++) {
                    let currentSize = values[i] / (divisorScale * chartScale);
                    if (!isEmpty(dataMarkerSize)) {
                        if (!isEmpty(data[i][dataMarkerSize?.FieldName!])) {
                            currentSize = data[i][dataMarkerSize?.FieldName!]
                        }
                    }

                    var currentText = labels[i] + " -> " + values[i];
                    citySize.push(currentSize);
                    hoverText.push(currentText);

                    dataSet.push(
                        {
                            type: "scattergeo",
                            text: [currentText],
                            lat: [latitudes[i]],
                            lon: [longitudes[i]],
                            latField: dataLatitude?.FieldName!,
                            lonField: dataLongitude?.FieldName!,
                            value: values[i] || 0 ,
                            locationmode: panelData.ChartOption?.LocationMode || "ISO-3",
                            colorscale: panelData.ChartOption?.ColorScale || "Blues",
                            showscale: panelData.ChartOption?.ShowScale || false,
                            reversescale: panelData.ChartOption?.ReverseScale || true,
                            rawData: data[i],
                            marker: {
                                sizemode: 'diameter',
                                originalSize: currentSize,
                                size: [currentSize],
                                color: isEmpty(dataColor) ? generateRandomColor() : data[i][dataColor?.FieldName!] ?? generateRandomColor(),
                                line: {
                                    color: isEmpty(dataBorderColor) ? generateRandomColor() : data[i][dataBorderColor?.FieldName!] ?? generateRandomColor(),
                                    width: panelData.ChartOption?.LineMarkerWidth || 2//0.5
                                }
                            }
                        } as any
                    )
                }

                plotDataSetValue(dataSet);

                plotLayoutSetValue((plotLayout: any) => {
                    return {
                        ...plotLayout,
                        height: (parseInt(panelData.ChartOption?.Height ?? '0') * 0.9) || 500,
                        width: (parseInt(panelData.ChartOption?.Width ?? '0') * 0.9) || 500,
                        title: __t(panelData.ChartOption, "Title"),
                        showlegend: panelData.ChartOption?.ShowLegend || false,
                        margin: {
                            l: 0,
                            r: 0,
                            b: 0,
                            t: isEmpty(panelData.ChartOption.Title) ? 0 : 50,
                            pad: 0,
                            autoexpand: true
                        },
                        geo: {
                            scope: panelData.ChartOption?.Scope || "world",
                            showframe: false,
                            showcoastlines: false,
                            showland: true,
                            landcolor: "#dae3eb",
                            showcountries: true,
                            countrycolor: "white",
                            bgwidth: '100%',
                            subunitwidth: 1,
                            countrywidth: 1,
                        }
                    };
                });
            }
        } else if(panelData.ChartOption.Type?.toLocaleLowerCase() === "treemap") {
            if(Array.isArray(data)){
                let dataParent = panelData.Fields.find((field) => field.ChartField === "DataParent");
                let dataLabel = panelData.Fields.find((field) => field.ChartField === "DataLabel");
                let dataValue = panelData.Fields.find((field) => field.ChartField === "DataValue");
                let dataColor = panelData.Fields.find((field) => field.ChartField === "DataColor");

                if (dataParent && dataLabel && dataValue) {

                    const labels = data.map((row: any) => row[dataLabel?.FieldName!])
                    const values = data.map((row: any) => row[dataValue?.FieldName!])
                    const parents = data.map((row: any) => row[dataParent?.FieldName!])
                    const colors = data.map((row: any) => row[dataColor?.FieldName!])
              
                    plotDataSetValue([
                        {
                            type: 'treemap',
                            values: values,
                            labels: labels,
                            ...(!isEmpty(panelData.ChartOption.TextInfo) && {
                                textinfo: panelData.ChartOption.TextInfo
                            }),
                            parents: parents,
                            marker: { colors }
                        } as any
                    ]);
    
                    plotLayoutSetValue((plotLayout:any) => {
                        return {
                            ...plotLayout,
                            height: panelData.ChartOption?.Height || 1000,
                            width: panelData.ChartOption?.Width || 1000,
                            autosize: false
                        };
                    });

                }
                
            } else {
                console.info("Data Is Not Array")
            }

        }

        if (!skipDataSet) setData(data);

        setTitle(data)
    };

    const setTitle = (data: any[]) => {        
        var titleVariables  = __t(panelData.ChartOption, "Title").match(/\${([^}]+)\}/g);

        if (titleVariables && Array.isArray(titleVariables)) {

            let dataValue = panelData.Fields.find(
                (field) => field.ChartField === "DataValue"
            );

            let value = 0;

            if (dataValue) {
                const fieldValue = dataValue.FieldName!;
                (data.length > 0 ? data : currentData || []).forEach((datum: any, index: any) => {
                    var strValue = (datum[fieldValue] || 0 ).toString()
                     value += parseFloat(strValue.replace(/[.]/g,","));
                })
            }

            var title = __t(panelData.ChartOption, "Title");

            let datas: any = [
                {
                    DataSource: "CURRENT_DATE",
                    EmittedValue :  moment().format('DD/MM/yyyy')
                },
                {
                    DataSource: "CURRENT_DATETIME",
                    EmittedValue : moment().format('DD/MM/yyyy HH:mm')
                },
                {
                    DataSource: "CURRENT_YEAR",
                    EmittedValue : moment().format('yyyy')
                },
                {
                    DataSource: "CURRENT_MONTH",
                    EmittedValue : moment().format('MM')
                },
                {
                    DataSource: "CURRENT_DAY",
                    EmittedValue : moment().format('DD')
                },
                {
                    DataSource: "SUM_DATA_VALUE",
                    EmittedValue : value || 0
                }
            ]

            var cacheItem = sessionStorage.getItem("BEACON_" + chartName) || ""
            var cacheData = isEmpty(cacheItem) ? null : JSON.parse(cacheItem);
    
            if (cacheData != null) {
               datas = datas.concat(cacheData);
            }

            titleVariables.forEach(variable => {
                if (panelData.ChartOption.TitleLabels) {
                    panelData.ChartOption.TitleLabels?.forEach(field => {
                        let value: any;
                        var fetchedData = datas.find((emittedData: any) => (
                            emittedData.DataSource === field?.FieldName
                        ));
                        if (fetchedData) {
                            value = fetchedData.EmittedValue || "";
                        }

                        if (field?.DataType === "Date" && field?.FieldFormat) {
                            if (field.FieldFormat) {
                                value = moment.utc(value).format(field.FieldFormat)
                            }
                        }

                        var tmpVar = variable.replace("${","").replace("}","");                                                        
                        value = value ? (field?.Position || "Prefix") === "Prefix" ? 
                                __t(field, "FieldLabel") + " " + value : 
                                value + " " + __t(field, "FieldLabel") : "";

                        if (tmpVar === field?.FieldName) {
                            title = title?.replaceAll(variable, value);
                        }
                    });
                } else {
                    title = title?.replaceAll(variable, "");
                }
            });

            plotLayoutSetValue((plotLayout : any) => {
                return {
                    ...plotLayout,
                    ...{
                        title: title,
                    }
                }
            });
        }
    }

    const reRender = () => {
        setCanChartRender(false);

        setTimeout(() => {
            setCanChartRender(true);
        }, 10);
    };

    const resetPlotData = () => {
        panelMessageSetValue("");
        panelSilentMessageSetValue("");
        plotDataSetValue([]);
        treeDataSetValue([]);
        mapDataSetValue([]);
    };

    const resetSubscriberChart = () => {
        if (!isEmpty(beaconSelector) && !isEmpty(panelData.SubscribeComponents)) {
            panelData.SubscribeComponents.forEach((subscribeInfo) => {
                const beaconSource = `${subscribeInfo?.Source?.WidgetName}_${subscribeInfo?.Source?.PanelName}`;
                if (
                    (
                        panelItemSelector?.isLoadingGetTableData &&
                        (
                            Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(beaconSource)) ?
                                panelItemSelector?.loadingData?.targetPanel.includes(beaconSource) :
                                panelItemSelector?.loadingData?.targetPanel === beaconSource
                        )
                    ) ||
                    (
                        panelItemSelector?.isResettingGetTableData &&
                        (
                            Array.isArray(panelItemSelector?.resetData?.targetPanel.includes(beaconSource)) ?
                                panelItemSelector?.resetData?.targetPanel.includes(beaconSource) :
                                panelItemSelector?.resetData?.targetPanel === beaconSource
                        )
                    )
                ) {
                    dispatch({
                        type: "API_RESET_TABLE_DATA",
                        payload: {
                            targetPanel: chartName,
                            postActions: null
                        }
                    });
                    resetEmitData(dispatch, chartName).then(() => resetPlotData());
                }
            })
        }
    }

    useEffect(() => {
        // Load plot data
        setTimeout(() => {
            if (panelData?.LoadData?.APIName) {
                fetchPlotData();
                if (panelData.LoadData?.RefreshInterval) {
                    // RefreshInterval is in second, so we need to times it with 1000

                    setInterval(
                        () => fetchPlotData(true),
                        panelData.LoadData?.RefreshInterval * 1000
                    );
                }
            }
        }, 1000);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isEmpty(beaconSelector)) {
            if (
                beaconSelector.source === "UniversalSearchPanel" &&
                beaconSelector.data?.hasOwnProperty("inputParameterValues") &&
                beaconSelector.data?.targetPanel === chartName
            ) {

                var data =  (beaconSelector.data.inputParameterValues as IFieldValue[]).map(p => {
                    return {
                        DataSource: p.fieldName,
                        EmittedValue : p.value
                    }
                });
                sessionStorage.setItem("BEACON_" + chartName, JSON.stringify(data));

                inputParameterValuesSetValue(
                    beaconSelector.data.inputParameterValues as IFieldValue[]
                );

              
                resetSubscriberChart()
            }

            if (
                beaconSelector.source === "RequestChartLoadData" &&
                beaconSelector.data?.targetPanel === chartName
            ) {
                dispatch({
                    type: "API_GET_TABLE_DATA",
                    payload: {
                        targetPanel: chartName,
                        postActions: null
                    }
                });
                fetchPlotData(true);
            }

            if (!isEmpty(panelData.SubscribeComponents)) {
                const subscribeComponents = panelData.SubscribeComponents;

                subscribeComponents.forEach((subscribeInfo) => {
                    const beaconSource = `${subscribeInfo?.Source?.WidgetName}_${subscribeInfo?.Source?.PanelName}`;
                    const APIParameters : PanelFieldData[] = []

                    if (beaconSelector.source === beaconSource) {
                        if (subscribeInfo?.Actions && !isEmpty(beaconSelector?.data)) {
                            subscribeInfo.Actions.forEach((action) => {
                                if (action.ActionType === "FetchChartData") {
                                    if (action?.FetchOption?.APIParameters) {
                                        let inputParameterValues: any = [];

                                        action.FetchOption.APIParameters.forEach((param) => {
                                            let value;

                                            APIParameters.push({
                                                FieldName: param.FieldName,
                                                DataType: param.DataType,
                                                DefaultValue: param.DefaultValue
                                            } as  PanelFieldData)

                                            if (param.DefaultValue) {
                                                value = param.DefaultValue;
                                            }

                                            if (param?.ValueSource === "TableSelectedItem" ||
                                                param?.ValueSource === "MapSelectedItem" ||
                                                param?.ValueSource === "ChartSelectedItem" || 
                                                param?.ValueSource === "PieChartSelectedItem" ||
                                                param?.ValueSource === "SunburstSelectedItem") {
                                                let fetchedData;

                                                if (param?.ValueSource === "MapSelectedItem" && _.get(beaconSelector.data, "UseArray", false)) {
                                                    inputParameterValues = beaconSelector.data.data.map((dataItem:any) => {
                                                        const convertedData = action.FetchOption.APIParameters.map((p:any) => {
                                                          return {
                                                            fieldName: p.FieldName,
                                                            value: dataItem[p.FieldName],
                                                            dataType: p.DataType
                                                          };
                                                        });
                                                        return convertedData;
                                                      })    
                                                } else {
                                                    fetchedData = beaconSelector.data.find((emittedData: any) => (
                                                        emittedData.DataName === param.ValueSource &&
                                                        emittedData.DataSource === param.FieldName
                                                    ));
    
                                                    if (fetchedData) {
                                                        value = fetchedData.EmittedValue;
                                                        if (param.DefaultValue) {
                                                            value = param.DefaultValue;
                                                        }
                                                    }

                                                    inputParameterValues.push({
                                                        fieldName: param.FieldName,
                                                        value,
                                                        dataType: param.DataType
                                                    });
                                                }
                                            }
                                            
                                        });

                                        sessionStorage.setItem(chartName, JSON.stringify(inputParameterValues));
                                        sessionStorage.setItem("BEACON_" + chartName, JSON.stringify(beaconSelector.data));

                                        panelData.LoadData = {
                                            APIName: action.FetchOption?.APIName,
                                            RefreshInterval: panelData.LoadData?.RefreshInterval || undefined,
                                            APIParameters: APIParameters
                                        }

                                        inputParameterValuesSetValue(inputParameterValues);

                                        setTimeout(() => {
                                            emitData(dispatch, "RequestChartLoadData", {
                                                targetPanel: chartName
                                            });
                                        }, 100); // to fix multiple panel can't do asnychronous data request
                                    }
                                }
                            });
                        }

                        if (beaconSelector.type === "RESET_DATA") {
                            resetPlotData();
                            resetSubscriberChart();
                        }
                    }

                    if (beaconSelector.source === "UniversalSearchPanel" && beaconSelector?.data?.targetPanel === beaconSource) {
                        resetPlotData();
                        resetSubscriberChart();
                    }
                });
            }
        }

        // eslint-disable-next-line
    }, [beaconSelector]);

    useEffect(() => {
        let sockets: SocketIOClient.Socket[] = [];

        panelData?.SubscribeComponents?.forEach((subscribeInfo) => {
            if (subscribeInfo?.SourceType === "Socket") {
                subscribeInfo?.SocketTopics?.forEach((topic) => {
                    const socket = openSocket(
                        process.env.REACT_APP_WS_URL!,
                        {
                            path: process.env.REACT_APP_WS_PATH ?? '/circlebi.io',
                            transports: ['websocket', 'polling'],
                            query: `TopicName=${topic}`
                        }
                    );

                    socket.on("connect", () => {
                        socket.emit("subscribeToEvent", 1000);
                    });

                    socket.on("NewDataNotification", (data: any) => {
                        console.log("New Data Notification");
                        const timeDiff = new Date().getTime() - lastUpdate.getTime();

                        if (isEmpty(subscribeInfo.RefreshInterval)) {
                            emitData(dispatch, "RequestChartLoadData", {
                                targetPanel: chartName
                            });
                        } else if (
                            isEmpty(lastUpdate) ||
                            timeDiff > subscribeInfo.RefreshInterval * 1000
                        ) {
                            lastUpdateSetValue(new Date());
                            emitData(dispatch, "RequestChartLoadData", {
                                targetPanel: chartName
                            });
                        }
                    });

                    sockets.push(socket);
                });
            }
        });

        return () => {
            // close socket on component unMount
            if (!isEmpty(sockets)) {
                sockets.forEach((socket) => {
                    try {
                        socket.close();
                    } catch (e) {
                        console.error("error closing socket", e);
                    }
                });
            }
        };
        // eslint-disable-next-line
    }, [panelData]);

    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            if (
                (
                    panelItemSelector?.isLoadingGetTableData &&
                    (
                        Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(chartName)) ?
                            panelItemSelector?.loadingData?.targetPanel.includes(chartName) :
                            panelItemSelector?.loadingData?.targetPanel === chartName
                    )
                ) ||
                (
                    panelItemSelector?.isResettingGetTableData &&
                    (
                        Array.isArray(panelItemSelector?.resetData?.targetPanel.includes(chartName)) ?
                            panelItemSelector?.resetData?.targetPanel.includes(chartName) :
                            panelItemSelector?.resetData?.targetPanel === chartName
                    )
                )
            ) {
                resetSubscriberChart()
                resetPlotData();
            }

            if (
                panelItemSelector.isSuccessGetTableData &&
                panelItemSelector.successData.targetPanel === chartName
            ) {
                // Receive data from a search panel
                onLoading(false);
                if (panelItemSelector.successData) {
                    if (panelItemSelector.successData.done) {

                    } else {
                        if ((panelItemSelector.successData.data ?? []).length === 0) {
                            panelMessageSetValue("Empty data");
                        } else {
                            generatePlotData(panelItemSelector.successData.data);
                        }
                    }
                }

            }
        }
        // eslint-disable-next-line
    }, [panelItemSelector, chartName]);

    useEffect(() => {
        // init plot
        let chartWidth: number = 0, chartHeight: number = 0;

        if (panelData.ChartOption.Width && !isEmpty(panelWidth)) {
            if (typeof panelData.ChartOption.Width === "string") {
                if (panelData.ChartOption.Width.includes("px")) {
                    chartWidth = parseInt(panelData.ChartOption.Width.replace("px", ""));
                } else if (panelData.ChartOption.Width.includes("%")) {
                    // Add more process here
                    chartWidth = parseInt(panelData.ChartOption.Width.replace("%", ""));
                    chartWidth = panelWidth * (chartWidth / 100);
                } else {
                    alert("Define 'Width' of ChartOption with pixel or percent unit");
                }
            } else {
                chartWidth = panelData.ChartOption.Width;
            }
        } else {
            chartWidth = panelWidth
        }

        if (panelData.ChartOption.Height) {
            if (typeof panelData.ChartOption.Height === "string") {
                if (panelData.ChartOption.Height.includes("px")) {
                    chartHeight = parseInt(panelData.ChartOption.Height.replace("px", ""));
                } else {
                    alert("Define 'Height' of ChartOption with pixel unit");
                }
            } else {
                chartHeight = panelData.ChartOption.Height;
            }
            setChartHeight(chartHeight);
        }
        
        // panelData.ChartOption.TitleCurrentTime = "END_DATE_OF_YEAR"
        if (panelData?.ChartOption?.Title) {
            const tempTitle = {Title: getTitleWithTime(panelData?.ChartOption)};
            plotLayoutSetValue((plotLayout : any) => {
                return {
                    ...plotLayout,
                    ...{
                        title: __t(tempTitle, "Title"),
                        width: chartWidth,
                        height: chartHeight,
                        autosize: true,
                        showlegend: panelData.ChartOption.ShowLegend,
                        legend: panelData.ChartOption.LegendOption
                    }
                }
            });
            setTitle([]);
            reRender();
        }
                
        // eslint-disable-next-line
    }, [panelWidth]);

    function getTitleWithTime(option: any): string {
        let datas: any = [
            {
                DataSource: "CURRENT_DATE",
                EmittedValue: moment().format('DD/MM/yyyy')
            },
            {
                DataSource: "CURRENT_DATETIME",
                EmittedValue: moment().format('DD/MM/yyyy HH:mm')
            },
            {
                DataSource: "CURRENT_YEAR",
                EmittedValue: moment().format('yyyy')
            },
            {
                DataSource: "CURRENT_MONTH",
                EmittedValue: moment().format('MM')
            },
            {
                DataSource: "CURRENT_DAY",
                EmittedValue: moment().format('DD')
            },
            {
                DataSource: "START_DATE_OF_YEAR",
                EmittedValue: moment().startOf('year').format('DD/MM/yyyy')
            },
            {
                DataSource: "END_DATE_OF_YEAR",
                EmittedValue: moment().endOf('year').format('DD/MM/yyyy')
            }
        ];
        let title = option.Title;
        const dataSourceKey = option.TitleCurrentTime;
    
        const matchedData = datas.find((d:any) => d.DataSource === dataSourceKey);
        if (matchedData) {
            title = `${title} ${matchedData.EmittedValue}`;
        }
        return title;
    }

    function relayoutChart(event: Readonly<import("plotly.js").PlotRelayoutEvent>) {
        if (panelData?.ChartOption.Type === 'scattergeo') {                       
            let scale = _.get(event, 'geo.projection.scale')

            if (scale) {
                setChartScale(scale)
            
                const data = plotData as any[]
                
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    const marker = element.marker;
                    let currentSize = marker.originalSize                                                            
  
                    if (scale >=1) {
                        currentSize = marker.originalSize
                    } else {
                        const baseScale = 50000
                        const scaleFactor = (baseScale * scale)
                        const newScale = baseScale + ((baseScale - scaleFactor) * 3)

                        currentSize  =  element.value / newScale
                    }

                    Object.assign(element, {
                        marker: {
                             ...marker,
                            size: [currentSize]
                        }
                    })
                }
                                                                                                                         
            }    
        }
    }


    //#region Chart Filtering
    const form = useForm({ mode: 'all' });
    const { control, register, handleSubmit, formState: { errors } }  = form
    const op = useRef<OverlayPanel>(null);

    const filter = (targetArray: any[], filters: any) => {        
        if (!isEmpty(filters)) {
            var filterKeys = Object.keys(filters);
            return targetArray.filter(function (eachObj) {
                return filterKeys.every(function (eachKey) {
                    if(isEmpty(filters[eachKey])){
                        return true;
                    }
                    return filters[eachKey].includes(eachObj[eachKey]);
                });
            });
        }
    };

    const filterChart = () => {
        const params = control._formValues; //control.getValues();        
        const data = filter((currentData ?? []), params)
        
        generatePlotData(data, true);
    }

    const renderFilterColumns = (index:number) => {
        const field = panelData?.Fields![index]
        const params = control._formValues; //control.getValues();
                
        if (!isEmpty(field) && !isEmpty(field.InputType) && !field.HideFilter) {

            if ((field?.InputType ?? '').toLowerCase() === 'dropdown') {

                if (isEmpty(field?.ListOfValues)) {
                    field!.ListOfValues = []
                }

                const data = [...currentData]

                const grouped = _(data ?? []).groupBy(field?.FieldName ?? '__Field__').map(it => ({
                    LabelValue: it[0][field?.FieldName!],
                    LabelDescription: it[0][field?.FieldName!],
                })).value()
                
                field!.ListOfValues = grouped
            }

            const dialogField: DialogField = new DialogField(
                field!,
                false, params![field.FieldName]
            );
    
            let customProps = {
                chartName: chartName,
                container: {
                    form: form
                },
                style: {
                    width: field?.FieldWidth? field.FieldWidth: "initial"
                }
            };
    
            return (
                <div key={index} className="p-col">
                    <div
                        key={index}
                        style={{
                            whiteSpace: "nowrap",
                            display: field!.Hidden? "none":"flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <label style={{marginRight:'20px'}} htmlFor={field!.FieldName}>{field!.FieldLabel}</label>
                        <Controller
                            name={dialogField.FieldName}
                            control={control}
                            defaultValue={dialogField.value}
                            render={({ field, fieldState }) => {   
                                return (<FieldGenerator field={dialogField} reference={field} customProps={customProps} fieldState />);
                            }}
                        />
                    </div>
                </div>
            );
        }
        
        
    }

    const renderFilters = (): JSX.Element => {
        const filterNames = new Set()
        if (!isEmpty(panelData?.Fields) && !isEmpty(panelData?.Fields.find(x => x.HideFilter === false))) { 

            const filters = (
                (panelData?.Fields ?? []).map((y, yIndex) => {                    
                    filterNames.add(y.FieldName)
                    return (<div key={yIndex} className="p-grid p-justify-between">
                        {renderFilterColumns(yIndex)}
                    </div>)
                }
                )
            )

            const onValidSubmit = (data: any) => { console.log("DATA", data); };

            return <form onSubmit={handleSubmit(onValidSubmit)}>
                <div className="p-grid p-justify-end">
                    <span className="p-buttonset">
                        <Button type="button" icon="pi pi-search" onClick={(e) => op!.current!.toggle(e)} />
                    </span>
                    
                    <OverlayPanel ref={op} showCloseIcon dismissable={false}>
                        {filters}
                        <Button type="submit" icon="pi pi-search" label="Filter" onClick={filterChart} />
                    </OverlayPanel>
                </div></form>;
        }
        return <></>
    };
    //#endregion

    const emptyChart = () => {
        //init empty plot
        let chartWidth: number = 0, chartHeight: number = 0;

        if (panelData.ChartOption.Width && !isEmpty(panelWidth)) {
            if (typeof panelData.ChartOption.Width === "string") {
                if (panelData.ChartOption.Width.includes("px")) {
                    chartWidth = parseInt(panelData.ChartOption.Width.replace("px", ""));
                } else if (panelData.ChartOption.Width.includes("%")) {
                    // Add more process here
                    chartWidth = parseInt(panelData.ChartOption.Width.replace("%", ""));
                    chartWidth = panelWidth * (chartWidth / 100);
                }
            } else {
                chartWidth = panelData.ChartOption.Width;
            }
        } else {
            chartWidth = panelWidth
        }

        if (panelData.ChartOption.Height) {
            if (typeof panelData.ChartOption.Height === "string") {
                if (panelData.ChartOption.Height.includes("px")) {
                    chartHeight = parseInt(panelData.ChartOption.Height.replace("px", ""));
                }
            } else {
                chartHeight = panelData.ChartOption.Height;
            }
        }

        return (
            <Plot
                divId={chartName}
                data={[]}
                layout={{
                    width: chartWidth || 0,
                    height: chartHeight || 0,
                    autosize: true,
                    "xaxis": {
                        "visible": false
                    },
                    "yaxis": {
                        "visible": false
                    },
                    "annotations": [
                        {
                            "text": "",
                            "xref": "paper",
                            "yref": "paper",
                            "showarrow": false,
                            "font": {
                                "size": 28
                            }
                        }
                    ]
                }}
                config={plotConfig}
            /> 
        )
    }

    return (
        <div style={{ width: '100%' }}>
            {/* {
                !isEmpty(panelSilentMessage) && isEmpty(panelMessage) ?
                    <h4 id="chart-silent-message"
                        style={{
                            display: "initial",
                            textAlign: "left"
                        }}
                    >
                        {panelSilentMessage}
                    </h4> : null
            } */}

            <div
                id="chart-panel"
                style={{
                    ...(chartHeight && {
                        minHeight: chartHeight
                    }),
                    ...(!isEmpty(panelMessage) && {
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    })
                }}
            >
                {
                    panelData.ChartOption.Type?.toLocaleLowerCase() === "tree" ?
                        (<TreeChart key={panelData.PanelName} onClick={handleChartClick} panelName={panelData.PanelName} panelWidth={panelWidth} valueType={panelData.ChartOption.ValueType} treeData={treeData} ></TreeChart>) :

                        panelData.ChartOption.Type?.toLocaleLowerCase() === "map" ?
                            (<MapChart panelData={panelData} panelWidth={panelWidth} widgetData={widgetData} 
                                onClick={handleChartClick} silentLoad={silentLoad} mapData={mapData} 
                                center={panelData.ChartOption?.CenterMap} panelName={chartName} 
                                hideMapPanel={panelData?.ChartOption.HideMapPanel}></MapChart>) :
                            !isEmpty(plotData) && isEmpty(panelMessage) && canChartRender ?
                                    <div onMouseMove={(event) => { 
                                        (document.getElementById(chartName) as any).on("plotly_hover", function (event:any) {
                                            if (event && event.hasOwnProperty('points') && event.hasOwnProperty('yvals')) {
                                                let targetpoint = 0;
                                                let leastdiff = Infinity;
                                                // Find the closest point to the vals returned
                                                for (let i = 0; i < event.points.length; i++) {
                                                    let diff = Math.abs(event.points[i].y - event.yvals[0]);
                                                    if (diff < leastdiff) {
                                                        targetpoint = i;
                                                        leastdiff = diff;
                                                    }
                                                };
                                                if (event.points != null) {
                                                    setSelectedPoint(event.points[targetpoint]);
                                                }
                                            }
                                        })
                                    }}>
                                        
                                        {
                                            renderFilters()
                                        }

                                        <Plot
                                            divId={chartName}
                                            data={plotData}
                                            layout={plotLayout}
                                            config={plotConfig}
                                            onClick={handleChartClick}
                                            onDoubleClick={() => {
                                                if (panelData.ChartOption.Type?.toLocaleLowerCase() === "bar") {
                                                    setTimeout(() => {
                                                        reRender();
                                                    }, 10);
                                                }
                                            }}
                                            onRelayout={(event) => {
                                                relayoutChart(event)
                                            }}
                                        /> 
                                    </div>: <div>
                                        {
                                            isEmpty(panelMessage) ? renderFilters() : null
                                        }
                                        {
                                            isEmpty(panelMessage) ? emptyChart() : null
                                        }
                                    </div>
                }
                {
                    !isEmpty(panelMessage) ?
                        <h4 id="chart-message">
                            {panelMessage}
                        </h4> : null
                }
            </div>

        </div>
    );
}

export default PanelChartComponent;