/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import api from '../../../../../common/api';

import { ActionData } from '../../../../../models/classes/ActionData';
import { PanelFieldData } from '../../../../../models/classes/PanelFieldData';
import { IAPIOption } from '../../../../../models/interfaces/IAPIOption';
import { IConsumeAPI } from '../../../../../models/interfaces/IConsumeAPI';
import { IPostAction } from '../../../../../models/interfaces/IPostAction';

import { consumeAPI, consumeCheckData, consumeGenerateReport } from '../../../../../redux/actions/panelItem';
import { isEmpty, isNull } from '../../../../../utils/validation';
import { IFieldValue } from '../../../../../models/interfaces/IFieldValue';

const generateActionOptions = (
    targetPanel: string,
    APIName: string,
    panelFieldData: PanelFieldData[],
    rowData: any,
    dataTableSelection?: any,
    fieldParameters?: any,
    ReportName?: string,
    directDownload?: boolean,
    useArray?: boolean
): IConsumeAPI => {
    const parseInputParameterValues = (rowData: any): IFieldValue[] => {
        let inputParameterValues: IFieldValue[] = [];

        if (panelFieldData?.length > 0) {
            panelFieldData.forEach((field: PanelFieldData) => {
                if (rowData?.hasOwnProperty(field.FieldName)) {
                    if (!isEmpty(fieldParameters) && Array.isArray(fieldParameters)) {
                        fieldParameters.forEach(param => {
                            if (param?.hasOwnProperty(field.FieldName)) {
                                inputParameterValues.push({
                                    fieldName: field.FieldName,
                                    fieldType: field.DataType,
                                    value: rowData[field.FieldName],
                                    dataType: field.DataType,
                                });
                            }
                        });
                    } else {
                            inputParameterValues.push({
                            fieldName: field.FieldName,
                            fieldType: field.DataType,
                            value: rowData[field.FieldName],
                            dataType: field.DataType,
                        });
                    }
                }
            });
        }

        if (!isEmpty(fieldParameters) && Array.isArray(fieldParameters)) {
            fieldParameters.forEach(param => {
                if (rowData?.hasOwnProperty(param?.FieldName)) {
                    panelFieldData.forEach((field: PanelFieldData) => {
                        if(field?.FieldName === param?.FieldName){
                            inputParameterValues.push({
                                fieldName: field.FieldName,
                                fieldType: field.DataType,
                                value: rowData[field.FieldName],
                                dataType: field.DataType,
                            });
                        }
                    });
                }
            });
        }        

        // MongoDB always have _id information on it's row
        if (rowData?.hasOwnProperty("_id") && inputParameterValues.find(e => e.fieldName === "_id") === undefined) {
            inputParameterValues.push({
                fieldName: "_id",
                fieldType: "InputText",
                value: rowData._id,
                dataType: "Text",
            });
        }

        return inputParameterValues;
    };

    let actionOptions: IConsumeAPI;

    if(isNull(dataTableSelection)) {
        actionOptions = {
            targetPanel,
            APIName,
            inputParameterValues: parseInputParameterValues(rowData),
            UseQueue: false,
            postActions: undefined
        };
    } else {
        let multiInputParameterValues: IFieldValue[][] = [];

        for (const rowData of dataTableSelection) {
            multiInputParameterValues.push(parseInputParameterValues(rowData));
        }

        actionOptions = {
            targetPanel,
            APIName,
            inputParameterValues: multiInputParameterValues,
            UseQueue: true,
            UseArray: useArray || false, 
            postActions: undefined
        };
    }

    if(!isEmpty(ReportName)) {
        actionOptions["ReportName"] = ReportName;
        if(directDownload) {
            actionOptions["DirectDownload"] = directDownload;
        } else {
            actionOptions["DirectDownload"] = false;
        }
    }

    return actionOptions;
};

interface ApiResponseItem {
    [key: string]: any;
    HPO_ROW_STATUS: 'added' | 'updated' | 'deleted';
}

interface ProcessedItem {
    [key: string]: any;
    ROW_INDEX: number;
}

interface RefreshFieldParameter {
    FieldName: string;
    PrimaryKey: boolean;
}

const processDataChanges = async (
    apiResponse: ApiResponseItem[], 
    rawData: any, 
    actionButton: any,
    rowData?: any,
    setTableData?: Function
    ) => {
    const getPrimaryKeyField = (): string => {
        const refreshParams = actionButton?.PostActions?.[0]?.RefreshFieldParameters || [];
        const primaryKeyParam = refreshParams.find(
            (param: RefreshFieldParameter) => param.PrimaryKey === true
        );
        return primaryKeyParam?.FieldName;
    };

    const primaryKeyField = await getPrimaryKeyField();
    let processedData = [...rawData];
    
    const getInsertionIndex = () => {
        if (rowData) {
            const rowDataIndex = processedData.findIndex(
                item => item[primaryKeyField] === rowData[primaryKeyField]
            );
            return rowDataIndex !== -1 ? rowDataIndex + 1 : processedData.length;
        }
        return processedData.length;
    };

    const addedItems = await apiResponse.filter(item => item.HPO_ROW_STATUS === 'added');
    const insertionIndex = await getInsertionIndex();
    
    addedItems.forEach((item, idx) => {
        const existingIndex = processedData.findIndex(
            raw => raw[primaryKeyField] === item[primaryKeyField]
        );
        
        if (existingIndex === -1) {            
            const newItem: ProcessedItem = {
                ...item,
                ROW_INDEX: insertionIndex + idx + 1
            };
            delete newItem.HPO_ROW_STATUS;
            processedData.splice(insertionIndex + idx, 0, newItem);
        }
    });

    const updatedItems = await apiResponse.filter(item => item.HPO_ROW_STATUS === 'updated');
    updatedItems.forEach(item => {
        const existingIndex = processedData.findIndex(
            raw => raw[primaryKeyField] === item[primaryKeyField]
        );
        
        if (existingIndex !== -1) {            
            const updatedItem: ProcessedItem = {
                ...processedData[existingIndex],
                ...item
            };
            delete updatedItem.HPO_ROW_STATUS;
            processedData[existingIndex] = updatedItem;
        }
    });

    const deletedItems = await apiResponse.filter(item => item.HPO_ROW_STATUS === 'deleted');
    deletedItems.forEach(item => {
        const existingIndex = processedData.findIndex(
            raw => raw[primaryKeyField] === item[primaryKeyField]
        );
        
        if (existingIndex !== -1) {
            processedData.splice(existingIndex, 1);
        }
    });

    processedData = await processedData.map((item, index) => ({
        ...item,
        ROW_INDEX: index + 1
    }));
    
    if (typeof setTableData === 'function') {
        try {            
            await new Promise((resolve, reject) => {
                setTableData(processedData);
                const nextPageButton = document.querySelector('button.p-paginator-next.p-paginator-element.p-link');
                const triggerPaginationClick = (retryCount = 0) => {
                    const currentPageButton = document.querySelector('button.p-paginator-page.p-paginator-element.p-link.p-highlight');
                    (nextPageButton as HTMLElement).click();
                    if (currentPageButton) {
                        (currentPageButton as HTMLElement).click();
                        resolve(true);
                    } else if (retryCount < 5) {
                        setTimeout(() => triggerPaginationClick(retryCount + 1), 500 * (retryCount + 1));
                    } else {
                        resolve(false);
                    }
                }
                setTimeout(() => triggerPaginationClick(), 100);
            });
        } catch (error) {
            console.error('Error updating table data:', error);
        }
    }
    return processedData;
};

const submitActionButtonData = (
    dispatch: Function,
    actionButton: ActionData,
    tableName: string,
    tableFields: PanelFieldData[],
    rowData: any,
    dataTableSelection?: any,
    useArray: boolean = false,
    rawData?: any,
    setTableData?: Function,
    setRawData?: any,
    setDataTableFirstRowIndex?: any
) => {
    if (actionButton.OnClickAction === "CallAPI") {
        let actionOptions: IConsumeAPI;
        let postActions: IPostAction[] = [];

        if(!isNull(rowData)) { // from dialog
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, rowData, null, null, undefined,
                undefined);
        } else if(useArray) {
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, null, dataTableSelection, null, undefined,
                undefined, useArray);
        } else if (dataTableSelection?.length === 1) {
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, dataTableSelection[0], undefined,
                undefined);
        } else {
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, null, dataTableSelection, null, undefined,
                undefined);
        }

        if(dataTableSelection) {
            dataTableSelection.forEach((rowData: any) => {
                if (actionButton?.PostActions && !isEmpty(actionButton?.PostActions)) {

                    actionButton.PostActions.forEach((postAction: IPostAction, idx: number) => {
                        if (postAction?.RefreshField) {
                            if(actionButton.PostActions) {
                                actionButton.PostActions[idx]["RefreshFieldRowInfo"] = rowData;
                            }
                        }
                    });

                    postActions.push(JSON.parse(JSON.stringify(actionButton.PostActions)));
                }
            });
        } else {
            if(actionButton?.PostActions && !isEmpty(actionButton?.PostActions)) {
                actionButton.PostActions.forEach((postAction: IPostAction, idx: number) => {
                    if (postAction?.RefreshField) {
                        if(actionButton.PostActions) {
                            actionButton.PostActions[idx]["RefreshFieldRowInfo"] = rowData;
                        }
                    }
                });

                postActions = actionButton.PostActions;
            }
        }

        actionOptions["postActions"] = postActions;
        actionOptions["responseType"] = actionButton.ResponseType;
        consumeAPI(dispatch, actionOptions, (result: any) => {
            if(result.success) {
                if(!rawData) return
                processDataChanges(result.data, rawData, actionButton, rowData, setTableData);
            }
        });
    } else if (actionButton.OnClickAction === "GenerateReport"){
        let actionOptions: IConsumeAPI;
        let postActions: IPostAction[] = [];
        if(!isNull(rowData)) { // from dialog
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, rowData, null, null, actionButton.PDFReport,
                actionButton.DirectDownload);
        } else if (dataTableSelection?.length === 1) {
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, dataTableSelection[0], actionButton.PDFReport,
                actionButton.DirectDownload);
        } else {
            actionOptions = generateActionOptions(tableName, actionButton.APIName, tableFields, null, dataTableSelection, null, actionButton.PDFReport,
                actionButton.DirectDownload);
        }

        if(dataTableSelection) {
            dataTableSelection.forEach((rowData: any) => {
                if (actionButton?.PostActions && !isEmpty(actionButton?.PostActions)) {

                    actionButton.PostActions.forEach((postAction: IPostAction, idx: number) => {
                        if (postAction?.RefreshField) {
                            if(actionButton.PostActions) {
                                actionButton.PostActions[idx]["RefreshFieldRowInfo"] = rowData;
                            }
                        }
                    });

                    postActions.push(JSON.parse(JSON.stringify(actionButton.PostActions)));
                }
            });
        } else {
            if(actionButton?.PostActions && !isEmpty(actionButton?.PostActions)) {
                actionButton.PostActions.forEach((postAction: IPostAction, idx: number) => {
                    if (postAction?.RefreshField) {
                        if(actionButton.PostActions) {
                            actionButton.PostActions[idx]["RefreshFieldRowInfo"] = rowData;
                        }
                    }
                });

                postActions = actionButton.PostActions;
            }
        }

        actionOptions["postActions"] = postActions;
        consumeGenerateReport(dispatch, actionOptions);
    }
};

const checkDataAPI = async (
  dispatch: Function,
  actionButton: ActionData,
  tableName: string,
  tableFields: PanelFieldData[],
  rowData: any,
  dataTableSelection?: any
) => {
  if (actionButton.OnClickAction === "CallAPI") {
    let actionOptions: IConsumeAPI;
    if (!isNull(rowData)) {
      // from dialog
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        rowData,
        null,
        null,
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    } else if (dataTableSelection?.length === 1) {
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        dataTableSelection[0],
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    } else {
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        null,
        dataTableSelection,
        null,
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    }

    const responseData: any = await consumeCheckData(dispatch, actionOptions);
    
    return responseData;
    
  } else if (actionButton.OnClickAction === "GenerateReport") {
    let actionOptions: IConsumeAPI;

    if (!isNull(rowData)) {
      // from dialog
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        rowData,
        null,
        null,
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    } else if (dataTableSelection?.length === 1) {
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        dataTableSelection[0],
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    } else {
      actionOptions = generateActionOptions(
        tableName,
        actionButton.ActionControllerAPI,
        tableFields,
        null,
        dataTableSelection,
        null,
        actionButton.PDFReport,
        actionButton.DirectDownload
      );
    }
    const responseData: any = await consumeCheckData(dispatch, actionOptions);
    return responseData;
  }
};

const getDropdownValues = async (apiName: string, isReturnLabel: boolean) => {
    let requestBody = {
        APIName: apiName,
        inputParameterValues: [
            {
                fieldName: "isReturnLabel",
                value: isReturnLabel,
                dataType: "Boolean",
            }
        ]
    };
    const apiOption: IAPIOption = {
        path: "query_management",
        method: "POST",
        data: requestBody
    };
    return await api(apiOption);
};

const getDropdownValuesByParams = async (apiName: string, inputParameterValues: any[]) => {
    let requestBody = {
        APIName: apiName,
        inputParameterValues
    };
    const apiOption: IAPIOption = {
        path: "query_management",
        method: "POST",
        data: requestBody
    };
    return await api(apiOption);
};

const getDefaultValues = async (apiName: string, APIParams: any) => {
    let inputParameterValues: any = [];

    APIParams.forEach((param: any) => {
        inputParameterValues.push({
            fieldName: param.FieldName,
            value: param.Value,
            dataType: param.DataType
        });
    });

    let requestBody = {
        APIName: apiName,
        inputParameterValues
    };

    const apiOption: IAPIOption = {
        path: "query_management",
        method: "POST",
        data: requestBody
    };

    return await api(apiOption);
};

export {
    generateActionOptions,
    submitActionButtonData,
    getDropdownValues,
    getDefaultValues,
    checkDataAPI,
    getDropdownValuesByParams
};