/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

// Libraries
import React, { useEffect, useState, CSSProperties, useRef, useCallback } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import openSocket from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowReorderEvent } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Styles
import './styles/table.css';

// Models & Interfaces
import { ActionData } from '../../../../../../models/classes/ActionData';
import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';
import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';

// Elements
import TableColumnElement from './TableColumnElement';
import TableHeaderElement from './TableHeaderElement';
import TableActionButtonElement from './TableActionButtonElement';
import TableRowExpansionElement from './TableRowExpansionElement';
import TableFooterElement from './TableFooterElement';
import TableDialogElement from './TableDialogElement';

// Redux modules
import { State } from '../../../../../../redux/reducers';
import { emitData, resetEmitData } from '../../../../../../redux/actions/beacon';
import {
    consumeAPI,
    consumeAPISuccess,
    consumeAPIError,
    exportDataError,
    exportDataSuccess,
    onBatchActionButtonClicked,
    resetTableData
} from '../../../../../../redux/actions/panelItem';
import { ACTION_SUBMIT_DATA, ACTION_SUBMIT_DATA_ERROR, ACTION_SUBMIT_DATA_SUCCESS } from '../../../../../../redux/constants/panelItem';

// Utils
import api from '../../../../../../common/api';
import { openDialog } from './utils/dialog';
import { addIfNotExist } from '../../../../../../utils/array';
import { isUrlParamExist, getUrlParam } from '../../../../../../utils/url';
import { isEmpty, isNull } from '../../../../../../utils/validation';

import { submitActionButtonData, generateActionOptions, getDropdownValues, getDefaultValues, checkDataAPI } from '../../common/action';
import { addDialogQueueItem, removeDialogQueueItem } from '../../../../../../redux/actions/dialog';
import { IPostAction } from '../../../../../../models/interfaces/IPostAction';
import { IAPIOption } from '../../../../../../models/interfaces/IAPIOption';
import { PanelData } from '../../../../../../models/classes/PanelData';
import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
import { checkPermission } from '../../../../../../utils/permission';
import { Button } from 'primereact/button';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { ThemedStyledComponentsModule, createGlobalStyle } from 'styled-components';
import { getTodayDate } from '../../../../../../utils/date';
import { CsvDataService } from '../../../../../../utils/csv_data_utils';
import { DialogField } from '../../../../../../models/classes/DialogField';
import { confirmBeforeSubmit } from './utils/confirmDialog';
import __t from '../../../../../../utils/translation';
import { useGlobalState } from '../../../../../../App';
import _ from 'lodash';
import useWindowDimensions from '../../../../../../utils/hooks';
import { ContextMenu } from "primereact/contextmenu";
import { ArrayManipulator } from './utils/table';
import { Permissionable } from '../../../../../../models/classes/Permissionable';


interface IPanelTable {
    actions?: ActionData[];
    workspaceName: string;
    widgetName: string;
    panelData: PanelData;
    hideGlobalSearch?: boolean;
    hideLineSeparator?: boolean;
    showLoadingText?: boolean;
    footerActionButton?: ActionData[];
    style?: CSSProperties;
    onLoading?: Function;
}

interface IExpandedRowsValue {
    tabName: string;
    rowName: string;
    rowValues: IFieldValue[];
}

interface IActiveTabIndex {
    rowName: string;
    index: number;
}

function PanelTableComponent(props: IPanelTable) {
    let {
        actions,
        workspaceName,
        widgetName,
        footerActionButton,
        panelData,
        hideGlobalSearch,
        hideLineSeparator,
        showLoadingText,
        onLoading
    } = props;

    let {
        PanelName: panelName,
        Fields: panelFields,  // Fields = table fields
        TableOption: tableOption,
        ActionsColumnWidth: actionsColumnWidth,
        SubscribeComponents: subscribeComponents,
        PublishComponents: publishComponents,
        LoadData: loadData
    } = panelData;

    if (isEmpty(tableOption)) {
        tableOption = {
            TableType: "Normal",
            UseLazyLoad: false,
            LazyLoadOption: {
                APIName: ""
            }
        }
    }

    if (panelData?.TableOption?.ShowTableOnEmptyData == null || panelData?.TableOption?.ShowTableOnEmptyData == undefined) {
        tableOption.ShowTableOnEmptyData = false //TODO: revert later to true
        panelData.TableOption = tableOption;
    }

    const GlobalStyle = createGlobalStyle`.p-datatable-scrollable-body {
        overflow: scroll !important; max-height: ${panelData.PanelHeight} !important;
    }`

    const {
        TableType: tableType,
        UseLazyLoad: isTableUseLazyLoad,
        LazyLoadOption: lazyLoadOption
    } = tableOption;

    const tableName = `${widgetName}_${panelName}`;
    let tableRowsPerPageOptions = window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE") ? window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE")?.toString().split(",").map(Number) : [5000, 10000, 20000]
    let tableExportOptions = window.localStorage.getItem("REACT_APP_EXPORT_TYPE") ? window.localStorage.getItem("REACT_APP_EXPORT_TYPE")?.toString().split(",").map(String) : ['csv']

    const [activeTabIndexes, setActiveTabIndexes] = useState<IActiveTabIndex[]>([]);
    const [batchActionButtons, setBatchActionButtons] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    //const [dataTable, setDataTable] = useState<DataTable<any> | null>(null);
    const [dataTableFirstRowIndex, setDataTableFirstRowIndex] = useState<number | undefined>(0);
    const [dataTableSelection, setDataTableSelection] = useState<any>([]);
    const [dialog, setDialog] = useState<any>(null);
    const [formRef, setFormRef] = useState<any>(null);
    const [expandedRows, setExpandedRows] = useState<object[]>([]);
    const [expandedRowsValue, setExpandedRowsValue] = useState<IExpandedRowsValue[]>([]);
    const [globalFilter, setGlobalFilter] = useState<string | null>(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [rawData, setRawData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [rowsCount, setRowsCount] = useState(0);
    const [tableFields, setTableFields] = useState<PanelFieldData[]>([]);
    const [showSectionBreak, setShowSectionBreak] = useState(true);
    const [isEditingRow, setIsEditingRow] = useState<boolean>(false);
    const [isOnPublishing, setIsOnPublishing] = useState<boolean>(false);
    const [isReOrderEvent, setIsReorderEvent] = useState<boolean>(false);
    const [lastDropIndex, setLastDropIndex] = useState<number>(0);
    const [editingRows, setEditingRows] = useState({});
    const [editingRowData, setEditingRowData] = useState({});
    const [widgetConfig, setWidgetConfig] = useState<any>(null);
    const [beaconData, setBeaconData] = useState({});
    const [appData, setAppData] = useGlobalState('appData');
    const dataTable = useRef<DataTable<any>>(null)
    const [saveRowStateButton, setSaveRowStateButton] = useState<ActionData | null>(null)
    const [orderedRowData, setOrderedRowData] = useState<any>([]);

    // #region Lazy load
    const [totalData, setTotalData] = useState<number>(0);
    const [isTableFetchingData, setTableLoadingState] = useState<boolean>(false);
    const [isInvalidLazyData, setIsInvalidLazyData] = useState<string | null>(null);
    // #endregion

    // #region component re-render triggers
    const [dummyState, triggerReRender] = useState(false);
    // #endregion

    const previousDataRef = useRef<any[]>([]);

    const form = useForm();
    const { control, register, handleSubmit, formState: { errors } } = form

    const beaconSelector = useSelector((state: State) => state.beaconItem);
    const dialogQueueSelector = useSelector((state: State) => state.dialogItem);
    const panelItemSelector = useSelector((state: State) => state.panelItem);
    const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);
    const navbarItemSelector = useSelector((state: State) => state.navbarItem);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { browserHeight } = useWindowDimensions();
    const cm = useRef<ContextMenu>(null);
    const [mounted, setMounted] = useState(false)

    if (!mounted) {
        // Code for componentWillMount here
        // This code is called only one time before intial render
        sessionStorage.removeItem(panelName);
    }

    const moveElement = (arr: any[], fromIndex: number, toIndex: number): any[] => {
        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    };

    const menuModel = useCallback(() => {
        if (!selectedItem) return [];
        
        const currentIndex = rawData.findIndex((item:any) => item === selectedItem);
        const isFirstRow = currentIndex === 0;
        const isLastRow = currentIndex === rawData.length - 1;
        
        return [
            // Move to first row - only show if not first row
            ...(!isFirstRow ? [{
                label: "Move to first row",
                icon: "pi pi-fw pi-angle-double-up",
                command: () => {
                    const previousData = [...rawData];
                    try {
                        const arrayManipulator = new ArrayManipulator(rawData);
                        const currentIndex = rawData.indexOf(selectedItem);
                        const dropedIndex = arrayManipulator.moveToTop(selectedItem);
                        const tempPreviousData = moveElement([...rawData], dropedIndex, currentIndex);
                        
                        const event = {
                            dragIndex: currentIndex,
                            dropIndex: dropedIndex,
                            value: rawData,
                        } as DataTableRowReorderEvent<any>;
                        
                        onRowReorder(event, tempPreviousData);
                    } catch (error) {
                        console.error("Error moving to first row:", error);
                        setRawData([...previousData]);
                    }
                },
            }] : []),
    
            // Move up - only show if not first row
            ...(!isFirstRow ? [{
                label: "Move up",
                icon: "pi pi-fw pi-angle-up",
                command: () => {
                    const previousData = [...rawData];
                    try {
                        const arrayManipulator = new ArrayManipulator(rawData);
                        const currentIndex = rawData.indexOf(selectedItem);
                        const dropedIndex = arrayManipulator.moveUpOneRow(selectedItem);
                        const tempPreviousData = moveElement([...rawData], dropedIndex, currentIndex);
        
                        const event = {
                            dragIndex: currentIndex,
                            dropIndex: dropedIndex,
                            value: rawData,
                        } as DataTableRowReorderEvent<any>;
        
                        onRowReorder(event, tempPreviousData);
                    } catch (error) {
                        console.error("Error moving up:", error);
                        setRawData([...previousData]);
                    }
                },
            }] : []),
    
            // Move down - only show if not last row
            ...(!isLastRow ? [{
                label: "Move down",
                icon: "pi pi-fw pi-angle-down",
                command: () => {
                    const previousData = [...rawData];
                    try {
                        const arrayManipulator = new ArrayManipulator(rawData);
                        const currentIndex = rawData.indexOf(selectedItem);
                        const dropedIndex = arrayManipulator.moveDownOneRow(selectedItem);
                        const tempPreviousData = moveElement([...rawData], dropedIndex, currentIndex);
        
                        const event = {
                            dragIndex: currentIndex,
                            dropIndex: dropedIndex,
                            value: rawData,
                        } as DataTableRowReorderEvent<any>;
        
                        onRowReorder(event, tempPreviousData);
                    } catch (error) {
                        console.error("Error moving down:", error);
                        setRawData([...previousData]);
                    }
                },
            }] : []),
    
            // Move to last row - only show if not last row
            ...(!isLastRow ? [{
                label: "Move to last row",
                icon: "pi pi-fw pi-angle-double-down",
                command: () => {
                    const previousData = [...rawData];
                    try {
                        const arrayManipulator = new ArrayManipulator(rawData);
                        const currentIndex = rawData.indexOf(selectedItem);
                        const dropedIndex = arrayManipulator.moveToLast(selectedItem);
                        const tempPreviousData = moveElement([...rawData], dropedIndex, currentIndex);
        
                        const event = {
                            dragIndex: currentIndex,
                            dropIndex: dropedIndex,
                            value: rawData,
                        } as DataTableRowReorderEvent<any>;
        
                        onRowReorder(event, tempPreviousData);
                    } catch (error) {
                        setRawData([...previousData]);
                    }
                },
            }] : []),
        ];
    }, [selectedItem, rawData]);
    

    useEffect(() => {
        setMounted(true)
        setFormRef(form)
    }, [form])

    const setNullData = (message: string) => {
        initTableData();
        setIsInvalidLazyData(message); // this variable name needs to be rename
    }

    const setTableData = (newDataTableValue: any) => {
        if (!isEmpty(newDataTableValue)) {
            let newTableFields = [];

            if (isTableUseLazyLoad) {
                if (
                    newDataTableValue.hasOwnProperty("Documents") &&
                    newDataTableValue.hasOwnProperty("TotalDocuments")
                ) {
                    setTotalData(newDataTableValue.TotalDocuments);
                    if (isEmpty(newDataTableValue.Documents)) {
                        console.error('data : ', newDataTableValue);
                        //setNullData("Empty data.");
                        setNullData("");
                        return
                    } else {
                        newDataTableValue = newDataTableValue.Documents;
                    }
                } else {
                    console.error("Make sure your API use this format {TotalDocuments, Documents} when using lazy loading on the table");
                    setNullData("Make sure your API use this format {TotalDocuments, Documents} when using lazy loading on the table");
                    return
                }
            }

            if (
                typeof newDataTableValue === "object" &&
                newDataTableValue !== null &&
                !Array.isArray(newDataTableValue)
            ) {
                if (isTableUseLazyLoad && newDataTableValue?.Documents && newDataTableValue?.TotalDocuments) {
                    setNullData("Please use lazy load option on the table panel configuration");
                } else {
                    if (newDataTableValue?.Documents && newDataTableValue?.TotalDocuments) {
                        newDataTableValue = newDataTableValue.Documents;
                        newDataTableValue.forEach((dtv: any, dtvIdx: number) => {
                            if (!isEmpty(newDataTableValue[dtvIdx])) {
                                newDataTableValue[dtvIdx]["RowNumber"] = dtvIdx + 1;
                            }
                        });
                    } else {
                        console.error('data : ', newDataTableValue);
                        setNullData("Invalid data, check logs for more info.");
                        return
                    }
                }
            } else {
                newDataTableValue.forEach((dtv: any, dtvIdx: number) => {
                    if (!isEmpty(newDataTableValue[dtvIdx])) {
                        newDataTableValue[dtvIdx]["RowNumber"] = dtvIdx + 1;
                    }
                });
            }

            if (tableType === "Generic" && isEmpty(panelFields)) {
                for (const fieldName in newDataTableValue[0]) {
                    newTableFields = addIfNotExist(newTableFields, "FieldName", fieldName, {
                        FieldName: fieldName,
                        FieldLabel: fieldName,
                        FilterType: "Text",
                        Hidden: false,
                    }, newTableFields.length);
                }
            }

            newTableFields = addIfNotExist(
                tableType === "Generic" && isEmpty(panelFields) ? newTableFields : panelFields,
                "FieldName",
                "RowNumber",
                {
                    FieldName: "RowNumber",
                    FieldLabel: "No",
                    FilterType: "Text",
                    Hidden: true,
                },
                0 // add RowNumber to the first index
            );

            newTableFields.forEach((tableField: PanelFieldData) => {
                if (!isEmpty(tableField.FieldFormat)) {
                    const fieldFormat = tableField.FieldFormat;

                    newDataTableValue.forEach((dtv: any, dtvIdx: number) => {
                        if (!isEmpty(newDataTableValue[dtvIdx])) {
                            if (tableField.DataType === "Date") {
                                // .utc() makes moment don't convert value that comes from BE to another timezone
                                if (!isEmpty(newDataTableValue[dtvIdx][tableField.FieldName])) {
                                    newDataTableValue[dtvIdx][tableField.FieldName] = moment
                                        .utc(newDataTableValue[dtvIdx][tableField.FieldName])
                                        .format(fieldFormat);
                                }
                            }

                            if (tableField.DataType === "Decimal") {
                                newDataTableValue[dtvIdx][tableField.FieldName] =
                                    isEmpty(newDataTableValue[dtvIdx][tableField.FieldName]) ? "" :
                                        numeral(newDataTableValue[dtvIdx][tableField.FieldName])
                                            .format(fieldFormat);
                            }

                            if (tableField.DataType === "Percentage") {
                                newDataTableValue[dtvIdx][tableField.FieldName] =
                                    isEmpty(newDataTableValue[dtvIdx][tableField.FieldName]) ? "" :
                                        numeral(numeral(newDataTableValue[dtvIdx][tableField.FieldName]).value() / 100)
                                            .format(fieldFormat);
                            }

                        }
                    });
                }
            });

            setTableFields(newTableFields);
            setRawData(newDataTableValue);
            triggerReRender(!dummyState);

            if (_.get(panelData, "TableOption.EditableMode", "") === "row"
                && _.get(panelData, "TableOption.EnableEditMode", false) === true) {

                let _data = [...newDataTableValue];
                if (!isEmpty(_data)) {
                    let _editingRows = {}
                    for (let index = 0; index < _data.length; index++) {
                        _editingRows = { ..._editingRows, ...{ [`${_data[index][panelData?.TableOption?.DataKey ?? "RowNumber"]}`]: true } };
                    }
                    setEditingRows(_editingRows);
                }
            }

        } else {
            setNullData("");
        }
    };

    const refreshTableData = (currentPage: number, ItemsPerPage: number, isDataForExport: boolean = false, silentLoad: boolean = false) => {
        if (lazyLoadOption?.APIName || panelData?.LoadData?.APIName) {
            if (onLoading) onLoading(true)
            setTableLoadingState(true);
            let inputParameterValues: IFieldValue[] = [
                {
                    fieldName: "Page",
                    fieldType: "PositiveNumberInputText",
                    dataType: "Number",
                    value: currentPage
                } as IFieldValue,
                {
                    fieldName: "ItemsPerPage",
                    fieldType: "PositiveNumberInputText",
                    dataType: "Number",
                    value: ItemsPerPage
                } as IFieldValue,
                {
                    fieldName: "RequestAllData",
                    fieldType: "InputCheckbox",
                    dataType: "Boolean",
                    value: isDataForExport
                } as IFieldValue
            ];

            if (silentLoad) {
                var cacheItem = sessionStorage.getItem(tableName) || ""
                var cacheData = isEmpty(cacheItem) ? null : JSON.parse(cacheItem);

                if (!isEmpty(cacheData)) {
                    let cacheInputParameterValues: any = [];

                    panelData?.LoadData?.APIParameters?.forEach((param) => {
                        let value;

                        if (param.DefaultValue) {
                            value = param.DefaultValue;
                        }

                        value = cacheData.find((emittedData: any) => (
                            emittedData.fieldName === param.FieldName
                        ))

                        cacheInputParameterValues.push({
                            fieldName: param.FieldName,
                            value: typeof value === 'object' && value !== null ? value.value : value,
                            dataType: param.DataType
                        });

                    });
                    inputParameterValues = [...inputParameterValues, ...cacheInputParameterValues]
                }
            }


            if (isUrlParamExist("InputParameterValues")) {
                let cachedInputParameterValues: any = getUrlParam("InputParameterValues");
                cachedInputParameterValues = decodeURIComponent(cachedInputParameterValues);
                cachedInputParameterValues = JSON.parse(cachedInputParameterValues);
            
                cachedInputParameterValues.forEach((param: any) => {
                    const isParamExist = inputParameterValues.some(existingParam => existingParam.fieldName === param.fieldName);
                    if (!isParamExist) {
                        inputParameterValues.push({
                            fieldName: param.fieldName,
                            fieldType: param.fieldType,
                            value: param.value,
                            dataType: param.dataType
                        });
                    }
                });
            }            

            let requestBody = {
                APIName: lazyLoadOption?.APIName ? lazyLoadOption.APIName : panelData?.LoadData?.APIName ? panelData?.LoadData?.APIName : "",
                inputParameterValues
            };

            const apiOption: IAPIOption = {
                path: "query_management",
                method: "POST",
                data: requestBody
            }

            api(apiOption).then((response: any) => {
                if (response.success) {
                    setTableData(response.data);
                    setTableLoadingState(false);
                    if (isDataForExport) {
                        setTimeout(() => {
                            exportCSV(false);
                            exportDataSuccess(dispatch);
                            refreshTableData(0, rowsCount); // Back to page one and reset the data
                            setDataTableFirstRowIndex(0);
                        }, 100);
                    }
                } else {
                    console.error('error data : ');
                    console.table(response);
                    setNullData("Invalid data, check logs for more info.");
                }
                if (onLoading) onLoading(false)
            });
        } //else {
        //     console.error("APIName of LoadData or lazyLoadOption is not defined.")
        // }
    }

    const onPage = (event: any) => {
        // this function handles how the table pagination controlled
        const startIndex = event.first;
        const currentPage = event.page;
        const ItemsPerPage = event.rows;

        window.localStorage.setItem(panelName + "_ItemsPerPage", ItemsPerPage)

        setCurrentPage(currentPage);
        setRowsCount(ItemsPerPage);
        setDataTableFirstRowIndex(startIndex);

        if (isTableUseLazyLoad) {
            refreshTableData(currentPage, ItemsPerPage);
        }
    };

    const onRowClickHandler = (event: any) => {
        event.originalEvent?.preventDefault();

        if (!(event.originalEvent?.target.toString() === "[object SVGSVGElement]")) {
            setSelectedRow(event?.data);

            if (actions) {
                publishOnRowClick(event?.data)
            }
        }
    };

    const publishOnRowClick = async (data: any) => {
        setSaveRowStateButton(null)
        const allPromise: Promise<any>[] = [];
        if (actions) {
            if (isOnPublishing) return;

            setIsOnPublishing(true);
            actions.forEach(action => {
                if ((action.OnClickAction === "EmitData" ||
                    action.OnClickAction === "EmitLOVData") && data) {
                    if ((action.OnClickAction === "EmitData" ||
                        action.OnClickAction === "EmitLOVData")) {
                        allPromise.push(
                            new Promise(async (resolve, reject) => {
                                try {
                                    let fields: { DataName: string, DataSource: string, EmittedValue: any }[] = [];
                                    Object.keys(data).forEach(fieldName => {
                                        fields.push({
                                            DataName: action.ActionName,
                                            DataSource: fieldName,
                                            EmittedValue: data[fieldName]
                                        });
                                    });

                                    let dataApp = appData as any;
                                    if (isEmpty(dataApp)) {
                                        dataApp = {
                                            'LOV': [],
                                            lovData: null
                                        };
                                    }

                                    dataApp.lovData = fields;
                                    setAppData(dataApp)

                                    await emitData(dispatch, ((action.OnClickAction === "EmitLOVData") ? "__LOV__" : "") + tableName, fields);
                                } catch (error) {
                                    console.error(error);
                                    reject(error);
                                }

                                resolve(true);
                            })
                        )
                    }
                }
            });
            if (allPromise.length > 0) {
                await Promise.all(allPromise);
            }
            setIsOnPublishing(false);
        }
    }

    const onSelectionChangeHandler = (e: Partial<{ value: any, type: string }>) => {
        if (e.type === 'checkbox' || e.type === 'all') {
            setDataTableSelection(e?.value)
        }
    };

    const onRowToggleHandler = (e: Partial<{ data: any }>) => setExpandedRows(e.data);

    const checkRowCondition = (action: ActionData, rowData: any) => {
        if (!rowData) return false;
        const key = `HPO_ACTION_HIDDEN_${action.ActionName}`;
        const value = rowData[key];
        return value === 1 || value === "1" || value === true || value === "true";
    };

    const batchActionButtonCommand = async (actionButton: ActionData) => {
        if (dataTableSelection) {
            const filterData = (dataTableSelection || []).filter((row: any) => !checkRowCondition(actionButton, row));
            let response: any;
            let dataTemp: any = filterData;

            // CHECK FOR AVAILABLE ACTIONCONTROLLERAPI
            if (!isEmpty(actionButton.ActionControllerAPI)) {
                dataTemp = [];
                response = await checkDataAPI(dispatch, actionButton, tableName, tableFields, null, filterData);

                if (!isEmpty(response) && Array.isArray(response)) {
                    response?.forEach((temp: any) => {
                        if (temp.success) {
                            temp.data!.forEach((data: any) => {
                                dataTemp.push(data);
                            });
                        }
                    });
                }
            }

            if (dataTemp!.length > 0) {
                if (!isEmpty(actionButton.Fields)) {
                    dataTemp!.forEach((rowData: any) => {
                        addDialogQueueItem(dispatch, { action: actionButton, tableFields: panelFields, rowData });
                    });
                } else {
                    const onAccept = () => {
                        submitActionButtonData(dispatch, actionButton, tableName, panelFields, null, dataTemp,
                            (actionButton.SubmitParameterMode || '').toUpperCase() === 'ARRAY');
                    }

                    const onReject = () => { }

                    if (actionButton.RequiredConfirmation) {
                        confirmBeforeSubmit(actionButton.ConfirmationMessage, onAccept, onReject);
                    } else {
                        submitActionButtonData(dispatch, actionButton, tableName, panelFields, null, dataTemp,
                            (actionButton.SubmitParameterMode || '').toUpperCase() === 'ARRAY');
                    }
                }
            } else dispatch({
                type: ACTION_SUBMIT_DATA_ERROR,
                payload: {
                    targetPanel: tableName,
                    message: "No rows selected."
                }
            });
        } else {
            dispatch({
                type: ACTION_SUBMIT_DATA_ERROR,
                payload: {
                    targetPanel: tableName,
                    message: "No rows selected."
                }
            });
        }
    };

    const initBatchActionButtons = () => {
        // it consist of action buttons with ActionSupportMultipleSelect option set to true.
        let newBatchActionButtons: any[] = [];
        console.log("actions", actions);
        
        if (actions) actions.forEach((actionButton: ActionData) => {
            if (actionButton.ActionSupportMultipleSelect && !checkPermission(actionButton)) {
                newBatchActionButtons = addIfNotExist(newBatchActionButtons, "name",
                    actionButton.ActionName,
                    {
                        name: actionButton.ActionName,
                        label: __t(actionButton, "ActionLabel"),
                        command: () => onBatchActionButtonClicked(dispatch, tableName, actionButton)
                    },
                    newBatchActionButtons.length
                );
                setBatchActionButtons(newBatchActionButtons);
            }
        });
    };

    const initTableData = () => {
        setEditingRows({})
        setRawData([]);
        setOrderedRowData([]);
        //setDataTable(null);
        setDataTableFirstRowIndex(0);
        setDataTableSelection(null);
        setExpandedRows([]);
        setExpandedRowsValue([]);
        setBatchActionButtons([]);
        initBatchActionButtons();
        setGlobalFilter(null);
        setIsInvalidLazyData(null);
        setAppData({
            ...appData,
            Action: null,
            Type: null,
            WidgetName: widgetName,
            PanelName: panelName,
            APIName: null
        })
        setLastDropIndex(0);
        setIsReorderEvent(false);
    };

    const hasExpandedPanels = (actionButtons: ActionData[] | undefined) => {
        let actionButtonCount = 0;

        if (actionButtons) {
            actionButtons.forEach((actionButton) => {
                if (actionButton.ShowOnExpandedTab && !checkPermission(actionButton)) actionButtonCount++;
            });
        }

        return actionButtonCount > 0;
    };

    // #region This cannot be placed on another component
    const rowExpansionTemplate = (data: any): JSX.Element => {
        if (expandedRows) {
            for (let [row, rowValue] of Object.entries(expandedRows)) { // ex: {0006324: true, 0006544: true}
                if (rowValue) {
                    // init expandedRowsValue
                    if (!expandedRowsValue.some(obj => obj.rowName === row) && actions) { // if expandedRowsValue has not got value from expandedRows
                        actions.forEach((actionButton) => {
                            if (actionButton.Fields) {
                                if (actionButton.Fields) {
                                    let fields: IFieldValue[] = actionButton.Fields.map((obj: PanelFieldData) => ({
                                        fieldName: obj.FieldName,
                                        value: null,
                                        fieldType: obj.InputType,
                                        dataType: obj.DataType
                                    }));
                                    let newExpandedRowsValue = expandedRowsValue;
                                    newExpandedRowsValue.push({
                                        tabName: actionButton.ActionName,
                                        rowName: row,
                                        rowValues: fields
                                    });
                                    setExpandedRowsValue(newExpandedRowsValue);
                                }
                            }
                        });
                    }
                    // init activeTabIndexes
                    if (!activeTabIndexes.some((obj) => obj.rowName === row)) {
                        let newActiveTabIndex = activeTabIndexes;
                        newActiveTabIndex.push({
                            rowName: row,
                            index: 0
                        });
                        setActiveTabIndexes(newActiveTabIndex);
                    }
                }
            }
        }

        return TableRowExpansionElement(
            data,
            tableName,
            workspaceName,
            widgetName,
            panelData,
            actions ? actions : [],

            activeTabIndexes,
            setActiveTabIndexes,

            dummyState,
            triggerReRender,

            expandedRowsValue,
            setExpandedRowsValue,

            batchActionButtons.length > 0,

            dispatch,
            form
        );
    };
    // #endregion

    const setTableFieldsProperties = async () => {
        for (let index = 0; index < panelData?.Fields.length; index++) {
            const field = panelData.Fields[index];

            // dropdown prefetch data
            if (
                (field.InputType === "Dropdown" || field.InputType === "MultiSelect") &&
                field.ListOfValuesAPI
            ) {
                const response: any = await getDropdownValues(field.ListOfValuesAPI, false);
                if (response?.success && response?.data) {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        field["ListOfValues"] = response.data;
                    } else if (response.data.ListOfValues) {
                        field["ListOfValues"] = response.data.ListOfValues;
                    }
                }
            }

            if (field.DataType === "Date" && !isEmpty(field.DefaultValue)) {
                field.DefaultValue = getTodayDate(field.DefaultValue);
            }

            if (field.DataType === "Datetime" && !isEmpty(field.DefaultValue)) {
                field.DefaultValue = getTodayDate(field.DefaultValue);
            }

            if (field.DefaultValueAPI) {
                const response: any = await getDefaultValues(
                    field.DefaultValueAPI,
                    field.DefaultValueAPIParams
                );

                if (response?.success) {
                    if (!isNull(response?.data)) {
                        field["DefaultValue"] = response.data;
                    }
                }
            }
        }
        return Promise.resolve();
    }

    useEffect(() => {
        if (isEmpty(hideLineSeparator)) hideLineSeparator = true // force hideLineSeparator = true as default value
        if (hideLineSeparator) setShowSectionBreak(!hideLineSeparator);
        if (panelData?.TableOption?.HideLineSeparator) setShowSectionBreak(!panelData.TableOption.HideLineSeparator);

        const socket = openSocket(
            process.env.REACT_APP_WS_URL!,
            {
                path: process.env.REACT_APP_WS_PATH ?? '/circlebi.io',
                transports: ['websocket', 'polling']
            }
        );

        socket.on('connect', () => {
            socket.emit('subscribeToEvent', 1000);
        });

        publishComponents?.forEach((publishInfo) => {
            if (publishInfo.DataName === "TableSelectedItem") {
                const viewDetails = actions?.find(action => action.ActionLabel === "View Details");

                if (isEmpty(viewDetails)) {
                    const newAction = {
                        ActionName: publishInfo.DataName,
                        ActionLabel: "View Details",
                        ShowOnExpandedTab: false,
                        OnClickAction: "EmitData",
                        Fields: panelFields,
                        Hidden: true
                    } as ActionData;

                    if (actions) {
                        actions.push(newAction);
                    } else { // eslint-disable-next-line
                        actions = [newAction];
                    }
                }
            }
        });

        // Initialize table fields
        if (panelData?.Fields) {
            setTableFieldsProperties().then(() => setTableFields(panelData.Fields));
        }

        // Load table data
        if (panelData?.LoadData?.APIName) {
            let inputParameterValues: IFieldValue[] = [];

            panelData?.LoadData?.APIParameters?.forEach(
                (APIParam: PanelFieldData) => {
                    inputParameterValues.push({
                        fieldName: APIParam.FieldName,
                        dataType: APIParam.DataType,
                        value: APIParam.Value,
                    } as IFieldValue);
                }
            );

            const options: IConsumeAPI = {
                targetPanel: tableName,
                APIName: panelData.LoadData.APIName,
                inputParameterValues,
                UseQueue: false,
                isAPIForDataTable: true
            };

            setTimeout(() => {
                consumeAPI(dispatch, options);
            }, 500);

            setTimeout(() => {
                if (panelData.LoadData?.RefreshInterval) {
                    // RefreshInterval is in second, so we need to times it with 1000
                    setInterval(() => {
                        refreshTableData(currentPage, rowsCount, false, true);
                    }, panelData.LoadData?.RefreshInterval * 1000);
                }
            }, 1000);
        }

        return (() => {
            const options: Partial<IConsumeAPI> = {
                targetPanel: tableName,
                UseQueue: false,
                isAPIForDataTable: true
            };

            emitData(dispatch, "UniversalSearchPanel", options);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isEmpty(beaconSelector) && !isEmpty(subscribeComponents)) {
            setSaveRowStateButton(null)
            subscribeComponents.forEach((subscribeInfo) => {
                const beaconSource = `${subscribeInfo?.Source?.WidgetName}_${subscribeInfo?.Source?.PanelName}`;

                if (beaconSelector.source === beaconSource) {
                    if (!isEmpty(beaconSelector?.data)) {

                        const data = beaconData;

                        Object.assign(data, {
                            [beaconSource]: beaconSelector?.data
                        })

                        setBeaconData(data)

                        if (subscribeInfo?.Actions) {
                            subscribeInfo.Actions.forEach((action) => {
                                if (action.ActionType === "FetchTableData") {
                                    if (action?.FetchOption?.APIParameters) {
                                        let inputParameterValues: any = [];

                                        action.FetchOption.APIParameters.forEach((param) => {
                                            let value;

                                            if (param.DefaultValue) {
                                                value = param.DefaultValue;
                                            }

                                            if (
                                                param?.ValueSource === "TableSelectedItem" ||
                                                param?.ValueSource === "ChartSelectedItem" ||
                                                param?.ValueSource === "PieChartSelectedItem" ||
                                                param?.ValueSource === "BarChartSelectedItem" ||
                                                param?.ValueSource === "MapSelectedItem"
                                            ) {
                                                /**
                                                 * beaconSelector.data: [
                                                 *  {
                                                 *      DataName: "TableSelectedItem",
                                                 *      DataSource: "TaskId" // emmited table field name
                                                 *      EmittedValue: 1 // emmited table field value
                                                 *  }
                                                 * ]
                                                 */
                                                let fetchedData;
                                                if (param.ValueSource === "MapSelectedItem") {
                                                    if (_.get(beaconSelector.data, "UseArray", false)) {
                                                        inputParameterValues = beaconSelector.data.data.map((dataItem: any) => {
                                                            const convertedData = action.FetchOption.APIParameters.map((p: any) => {
                                                                return {
                                                                    fieldName: p.FieldName,
                                                                    value: dataItem[p.FieldName],
                                                                    dataType: p.DataType
                                                                };
                                                            });
                                                            return convertedData;
                                                        })
                                                    } else {
                                                        fetchedData = beaconSelector.data.map((emittedData: any) => {
                                                            let result = emittedData.map((a: any) => a[param.FieldName]);
                                                            if (!isEmpty(result) && Array.isArray(result)) {
                                                                result = result.join(",")
                                                            }
                                                            return result
                                                        });
                                                    }
                                                } else {
                                                    fetchedData = beaconSelector.data.find((emittedData: any) => (
                                                        emittedData.DataName === param.ValueSource &&
                                                        emittedData.DataSource === param.FieldName
                                                    ));
                                                }
                                                if (fetchedData && param.ValueSource !== "MapSelectedItem") {
                                                    value = fetchedData.EmittedValue;
                                                } else {
                                                    value = isEmpty(fetchedData) ? null : fetchedData[0]
                                                }
                                            }

                                            if (!_.get(beaconSelector.data, "UseArray", false)) {
                                                inputParameterValues.push({
                                                    fieldName: param.FieldName,
                                                    value,
                                                    dataType: param.DataType
                                                });
                                            }
                                            sessionStorage.setItem(tableName, JSON.stringify(inputParameterValues));
                                        });

                                        const options: IConsumeAPI = {
                                            targetPanel: tableName,
                                            APIName: action.FetchOption?.APIName,
                                            inputParameterValues,
                                            UseQueue: false,
                                            isAPIForDataTable: true
                                        };
                                        resetSubscriberTable();
                                        resetEmitData(dispatch, tableName).then(() => consumeAPI(dispatch, options));
                                    }
                                } else if (action.ActionType === "FetchTableRowDetails") {
                                    const filteredData = beaconSelector.data?.filter((emittedData: any) => (
                                        emittedData.DataName === subscribeInfo?.DataToFetch[0]?.DataName
                                    ));

                                    if (isEmpty(filteredData)) {
                                        setTableLoadingState(false);
                                        setTableData(beaconSelector.data);
                                    } else {
                                        const mappedData = filteredData?.reduce((obj: any, data: any) =>
                                            Object.assign(obj, { [data?.DataSource]: data?.EmittedValue }, {})
                                        );

                                        if (!isEmpty(mappedData)) {
                                            setTableLoadingState(false);
                                            setTableData([mappedData]);
                                        }
                                    }
                                    resetSubscriberTable();
                                    resetEmitData(dispatch, tableName);
                                }
                            })
                        }
                    } else { // publisher is receiving new data, so current panel should be reset
                        //initTableData()
                        resetEmitData(dispatch, tableName).then(() => initTableData());
                    }
                }

                if (beaconSelector.source === "UniversalSearchPanel" && beaconSelector?.data?.targetPanel.includes(beaconSource)) {
                    //initTableData();
                    sessionStorage.removeItem(tableName)
                    resetEmitData(dispatch, tableName).then(() => { initTableData(); resetSubscriberTable(); });
                }
            });
        }
        // eslint-disable-next-line
    }, [beaconSelector]);

    useEffect(() => {
        // Auto click the first row of the current table after it's first load
        if (!isReOrderEvent && !isEmpty(dataTable) && !isEmpty(dataTable.current)
            && !isEmpty(dataTable.current!.props?.value)
            && dataTable?.current!.props?.value && loadData?.AutoFirstRowSelection) {
            onRowClickHandler({ data: dataTable.current!.props.value[lastDropIndex] } as any);
        }
        // eslint-disable-next-line
    }, [dataTable, rawData]);

    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            // reset table's value on every time user click search button
            if (
                (
                    panelItemSelector?.isLoadingGetTableData &&
                    (
                        Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(tableName)) ?
                            panelItemSelector?.loadingData?.targetPanel.includes(tableName) :
                            panelItemSelector?.loadingData?.targetPanel === tableName
                    )
                ) ||
                (
                    panelItemSelector?.isResettingGetTableData &&
                    (
                        Array.isArray(panelItemSelector?.resetData?.targetPanel.includes(tableName)) ?
                            panelItemSelector?.resetData?.targetPanel.includes(tableName) :
                            panelItemSelector?.resetData?.targetPanel === tableName
                    )
                )
            ) {
                resetSubscriberTable();
                initTableData();
                setTableLoadingState(true);
            } else {
                setTableLoadingState(false);
            }

            if (
                panelItemSelector?.isSuccessSubmitActionData &&
                (
                    Array.isArray(panelItemSelector?.successData?.targetPanel.includes(tableName)) ?
                        panelItemSelector?.successData?.targetPanel.includes(tableName) :
                        panelItemSelector?.successData?.targetPanel === tableName
                )
            ) {
                setDialog(null);

                if (panelItemSelector?.successData) {
                    // #region Batch Action
                    if (panelItemSelector.successData?.dialogQueueItemIndexToRemove > -1) {
                        removeDialogQueueItem(
                            dispatch,
                            dialogQueueSelector,
                            panelItemSelector.successData.dialogQueueItemIndexToRemove
                        );
                    }
                    // #endregion

                    // #region Post Action
                    let postActionList = panelItemSelector?.successData?.postActions;
                    if (postActionList) {
                        const fetchPostActionData = async (apiOption: IAPIOption, postAction: IPostAction) => {
                            setTableLoadingState(true);
                            triggerReRender(!dummyState);
                            await api(apiOption).then((response: any) => {
                                setTableLoadingState(false);
                                setTimeout(() => {
                                    triggerReRender(!dummyState);
                                }, 300);

                                if (response?.success) {
                                    if (response?.data?.length > 0) {

                                        if (!isEmpty(rawData)) {
                                            const fieldToEditIdx: number = rawData.findIndex((row: any) => row?._id === response.data[0]._id);

                                            if (fieldToEditIdx > -1) {
                                                let newData = rawData;

                                                newData[fieldToEditIdx] = response.data[0];
                                                newData[fieldToEditIdx]["RowNumber"] = fieldToEditIdx + 1;

                                                if (response.data.length === 1) {
                                                    setDataTableSelection([newData[fieldToEditIdx]]);
                                                }

                                                if (isTableUseLazyLoad) {
                                                    setTableData({ TotalDocuments: totalData, Documents: newData });
                                                } else {
                                                    setTableData(newData);
                                                }
                                            }
                                        }

                                    } else if (response.data.length === 0) { // post action of a deleted document

                                        if (dataTable?.current!.props?.value) {
                                            const fieldIndexToEdit = dataTable?.current!.props?.value
                                                .findIndex((row: any) => row._id === postAction?.RefreshFieldRowInfo?._id);

                                            // console.log("PostAction field info: ", postAction?.RefreshFieldRowInfo, fieldIndexToEdit);
                                            let data = dataTable?.current!.props?.value.filter((row: any) => row?._id !== postAction?.RefreshFieldRowInfo?._id);
                                            if (dataTable?.current!.props?.value.length > 1) {



                                                let newDT = dataTable;
                                                if (newDT?.current!.props?.value?.length) {
                                                    newDT.current!.props!.value.splice(fieldIndexToEdit, 1);
                                                }

                                                // if (!panelData.LoadData) {
                                                //     panelData.LoadData = {
                                                //         APIName: postAction?.RefreshFieldAPI
                                                //     }
                                                // }

                                                // refreshTableData(0, 5, false);
                                                // setTableData(newDT?.props?.value);
                                                setTableData(data);

                                            }
                                        }
                                    }
                                } else {
                                    console.error("Failed to update field.");
                                    console.table(response);
                                    setTableLoadingState(false);
                                }

                                setTimeout(() => {
                                    triggerReRender(!dummyState);
                                    setDataTableSelection(null)
                                }, 300);
                            });
                        }

                        const generateApiOption = (postAction: IPostAction): IAPIOption => {
                            const actionOptions = generateActionOptions(
                                tableName,
                                postAction?.RefreshFieldAPI,
                                panelFields,
                                postAction?.RefreshFieldRowInfo,
                                null,
                                postAction?.RefreshFieldParameters
                            );

                            let requestBody = {
                                APIName: actionOptions.APIName,
                                inputParameterValues: postAction?.IgnoreInputParameter === true ? null : actionOptions.inputParameterValues
                            };

                            const apiOption: IAPIOption = {
                                path: "query_management",
                                method: "POST",
                                data: requestBody
                            }

                            return apiOption;
                        }

                        for (const postActions of postActionList) {
                            if (!isEmpty(postActions)) {
                                if (Array.isArray(postActions)) {
                                    for (const postAction of postActions) {
                                        if (postAction?.RefreshField && postAction?.RefreshFieldRowInfo) {
                                            const apiOption: IAPIOption = generateApiOption(postAction);

                                            fetchPostActionData(apiOption, postAction);
                                        }
                                    }
                                } else {
                                    if (postActions?.RefreshField) {
                                        const apiOption: IAPIOption = generateApiOption(postActions);

                                        fetchPostActionData(apiOption, postActions);
                                    }
                                }
                            }
                        };
                        setDataTableSelection(null);
                    }
                    // #endregion
                }
            }
        }

        // eslint-disable-next-line
    }, [panelItemSelector]);

    useEffect(() => {
        if (!isEmpty(dialogQueueSelector)) {
            const dialogData = dialogQueueSelector[0];
            let newDataTableSelection = dataTableSelection;

            if (newDataTableSelection && newDataTableSelection.length > 0) {
                if (!Array.isArray(newDataTableSelection)) {
                    newDataTableSelection = [newDataTableSelection];
                }
                newDataTableSelection = newDataTableSelection.filter((dts: any) => dialogQueueSelector.find((dqs: any) => dqs?.rowData?._id === dts?._id));
                setDataTableSelection(newDataTableSelection);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const promise = openDialog(
                    setDialog,
                    dialogData.action,
                    dialogData.tableFields,
                    dialogData.rowData,
                    dialogQueueSelector,
                    appData,
                    setAppData
                );
            }
        } else {
            if (dataTableSelection?.length > 0) {
                setDataTableSelection([]);
            }
        }
        // eslint-disable-next-line
    }, [dialogQueueSelector]);

    useEffect(() => {
        // Reset data on route change
        resetTableData(dispatch, tableName);
        // eslint-disable-next-line
    }, [location.pathname]);

    const resetSubscriberTable = () => {
        if (!isEmpty(beaconSelector) && !isEmpty(subscribeComponents)) {
            subscribeComponents.forEach((subscribeInfo) => {
                const beaconSource = `${subscribeInfo?.Source?.WidgetName}_${subscribeInfo?.Source?.PanelName}`;
                if (
                    (
                        panelItemSelector?.isLoadingGetTableData &&
                        (
                            Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(beaconSource)) ?
                                panelItemSelector?.loadingData?.targetPanel.includes(beaconSource) :
                                panelItemSelector?.loadingData?.targetPanel === beaconSource
                        )
                    ) ||
                    (
                        panelItemSelector?.isResettingGetTableData &&
                        (
                            Array.isArray(panelItemSelector?.resetData?.targetPanel.includes(beaconSource)) ?
                                panelItemSelector?.resetData?.targetPanel.includes(beaconSource) :
                                panelItemSelector?.resetData?.targetPanel === beaconSource
                        )
                    )
                ) {
                    setBeaconData({});
                    dispatch({
                        type: "API_RESET_TABLE_DATA",
                        payload: {
                            targetPanel: tableName,
                            postActions: null
                        }
                    });
                    resetEmitData(dispatch, tableName).then(() => initTableData());
                }
            })
        }

        emitData(dispatch, "RESET_LOV", []);
    }


    const filter = (targetArray: any[], filters: any) => {
        if (!isEmpty(filters)) {
            var filterKeys = Object.keys(filters);
            return targetArray.filter(function (eachObj) {
                return filterKeys.every(function (eachKey) {
                    if (!filters[eachKey].length) {
                        return true;
                    }
                    return filters[eachKey].includes(eachObj[eachKey]);
                });
            });
        }
    };


    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            // Data init

            resetSubscriberTable()

            if (
                panelItemSelector.isLoadingGetTableData &&
                (
                    Array.isArray(panelItemSelector.loadingData.targetPanel.includes(tableName)) ?
                        panelItemSelector.loadingData.targetPanel.includes(tableName) :
                        panelItemSelector.loadingData.targetPanel === tableName
                )
            ) {
                initTableData();
            }

            // Sets table data
            if (
                panelItemSelector.isSuccessGetTableData &&
                (
                    Array.isArray(panelItemSelector.successData.targetPanel.includes(tableName)) ?
                        panelItemSelector.successData.targetPanel.includes(tableName) :
                        panelItemSelector.successData.targetPanel === tableName
                )
            ) {
                setTableLoadingState(false); // finished lazy loading
                if ((panelItemSelector.successData.data ?? []).length === 0) {
                    panelItemSelector.successData.data = [{}];
                    setTableData([])
                } else {
                    setTableData(panelItemSelector.successData.data);
                }
            }

            if (
                panelItemSelector.isErrorGetTableData &&
                (
                    Array.isArray(panelItemSelector.errorData.targetPanel.includes(tableName)) ?
                        panelItemSelector.errorData.targetPanel.includes(tableName) :
                        panelItemSelector.errorData.targetPanel === tableName
                )
            ) {
                initTableData();
            }

            // Export data
            if (
                panelItemSelector.isExporting &&
                (
                    Array.isArray(panelItemSelector.loadingData.targetPanel.includes(tableName)) ?
                        panelItemSelector.loadingData.targetPanel.includes(tableName) :
                        panelItemSelector.loadingData.targetPanel === tableName
                )
            ) {
                if (dataTable) {
                    if (isTableUseLazyLoad) {
                        refreshTableData(0, 0, true);
                    } else {
                        exportCSV(false);
                        exportDataSuccess(dispatch);
                    }
                } else exportDataError(dispatch, "No data to export.");
            }

            if (
                panelItemSelector.isBatchActionClicked &&
                (
                    Array.isArray(panelItemSelector.targetPanel.includes(tableName)) ?
                        panelItemSelector.targetPanel.includes(tableName) :
                        panelItemSelector.targetPanel === tableName
                )
            ) {
                batchActionButtonCommand(panelItemSelector.actionData);
            }

            // Handle Dialog Action
            if (panelItemSelector.isSuccessSubmitActionData &&
                (
                    Array.isArray(panelItemSelector.successData.targetPanel.includes(tableName)) ?
                        panelItemSelector.successData.targetPanel.includes(tableName) :
                        panelItemSelector.successData.targetPanel === tableName
                )) {
                let postActionList = panelItemSelector?.successData?.postActions;
                let newData = {} as any
                let hasDataKeys = false;
                //const fieldToEditIdx: number = rawData.findIndex((row: any) => row?._id ===  panelItemSelector?.successData?.data?.response?.ops[0]?._id);
                if (panelItemSelector?.successData?.data?.hasOwnProperty('response')) {

                    if (panelItemSelector?.successData?.data?.response?.hasOwnProperty('ops') &&
                        panelItemSelector?.successData?.data?.response?.ops.length > 0) {
                        newData = panelItemSelector?.successData?.data?.response?.ops
                    }

                } else if (panelItemSelector?.successData?.hasOwnProperty('response')) {

                    if (panelItemSelector?.successData?.response?.hasOwnProperty('DataKeys') &&
                        !isEmpty(panelItemSelector?.successData?.response?.DataKeys)) {
                        if (Array.isArray(panelItemSelector?.successData?.response?.DataKeys) &&
                            panelItemSelector?.successData?.response?.DataKeys?.length > 0) {
                            hasDataKeys = true;
                            newData = panelItemSelector?.successData?.response?.DataKeys;
                        } else {
                            if (!isEmpty(panelItemSelector?.successData?.response?.DataKeys)) {
                                hasDataKeys = true;
                                newData = panelItemSelector?.successData?.response?.DataKeys;
                            }
                        }
                    } else if (panelItemSelector?.successData?.response?.hasOwnProperty('data') &&
                        !isEmpty(panelItemSelector?.successData?.response?.data)) {

                        if (Array.isArray(panelItemSelector?.successData?.response?.data) &&
                            panelItemSelector?.successData?.response?.data?.length > 0) {
                            newData = panelItemSelector?.successData?.response?.data;
                        } else {
                            if (!isEmpty(panelItemSelector?.successData?.response?.data)) {
                                newData = panelItemSelector?.successData?.response?.data;
                            }
                        }
                    } else {
                        if (Array.isArray(panelItemSelector?.successData?.data) && panelItemSelector?.successData?.data?.length > 0) {
                            newData = panelItemSelector?.successData?.data;
                        } else {
                            if (!isEmpty(panelItemSelector?.successData.data)) {
                                newData = panelItemSelector?.successData?.data;
                            }
                        }
                    }

                } else {
                    if (Array.isArray(panelItemSelector?.successData?.data) && panelItemSelector?.successData?.data?.length > 0) {
                        newData = panelItemSelector?.successData?.data;
                    } else {
                        if (!isEmpty(panelItemSelector?.successData.data)) {
                            newData = panelItemSelector?.successData?.data;
                        }
                    }
                }

                let responseData: any[] = [];

                if (!isEmpty(newData) && !Array.isArray(newData)) {
                    responseData = [newData]
                } else if (!isEmpty(newData)) {
                    responseData = newData;
                }

                const updateRowData = (sourceData: any[], action: any) => {
                    let data = rawData ?? [];

                    if (!isEmpty(sourceData) && Array.isArray(sourceData)) {
                        for (let i = 0; i < sourceData.length; i++) {
                            const element = sourceData[i];

                            let params = {} as any;
                            //Parse Data Parameter
                            if (!isEmpty(action?.RefreshFieldParameters)) {
                                action?.RefreshFieldParameters.forEach((e: any) => {
                                    if (e.PrimaryKey) {
                                        if (!isEmpty(element[e.FieldName])) {
                                            Object.assign(params, {
                                                [e.FieldName]: [element[e.FieldName]]
                                            })
                                        }
                                    }
                                });
                            }
                            //Filter data to be update by params key value
                            var result = filter(data, params);
                            if (result && result.length > 0) {
                                var index = data.indexOf(result[0]);

                                if (index >= 0) {
                                    // eslint-disable-next-line no-loop-func
                                    Object.keys(data[index]).forEach((o: any) => {
                                        Object.keys(element).forEach((oK: any) => {
                                            data[index][oK] = element[oK];
                                        });
                                    })
                                }
                            }

                        }
                    }
                }

                const invokeAction = async (action: any) => {

                    //Override Data if current action has DataSource
                    if (!isEmpty(action.DataSource)) {
                        if (panelItemSelector?.successData && panelItemSelector?.successData.hasOwnProperty(action.DataSource)) {
                            responseData = panelItemSelector?.successData[action.DataSource] ?? []
                        }
                    }

                    if (action?.AddRowData) {
                        let data = rawData;

                        await new Promise((resolve, _) => {

                            if (!data || data.length <= 0) {
                                if (!panelData.LoadData && action?.RefreshField && !isEmpty(action?.RefreshFieldAPI)) {
                                    panelData.LoadData = {
                                        APIName: action?.RefreshFieldAPI
                                    }
                                    refreshTableData(0, 5, false);
                                } else {
                                    setTableData(responseData);
                                }
                            } else {

                                if (responseData) {
                                    responseData.forEach(element => {
                                        data.push(element);
                                    });
                                }

                                if (isTableUseLazyLoad) {
                                    refreshTableData(0, 5, false);
                                } else {
                                    setTableData(data);
                                }
                            }
                            resolve(true);
                        })

                        if (responseData && responseData.length > 0) {
                            setSelectedRow(responseData[0]);
                        }

                        const doc = document.getElementById(panelData.PanelName);
                        if (!isEmpty(doc)) {
                            const button = doc?.getElementsByClassName("p-paginator-last")[0] as any;
                            if (!isEmpty(button)) {
                                button.click();
                            }
                        }

                        setTimeout(() => {
                            const tableWrapper = document.getElementsByClassName("p-datatable-wrapper");
                            if (!isEmpty(tableWrapper)) {
                                tableWrapper[0].scrollTop = 10000;
                            }
                        }, 500);

                        if (responseData && responseData.length > 0) {
                            publishOnRowClick(responseData[0]);
                        }

                    } else if (action?.UpdateRowData) {
                        let data = rawData;

                        if (!data || data.length <= 0) {
                            if (!panelData.LoadData) {
                                panelData.LoadData = {
                                    APIName: action?.RefreshFieldAPI
                                }
                            }
                            refreshTableData(0, 5, false);
                        } else {

                            if (responseData) {
                                updateRowData(responseData, action)
                            }

                            if (isTableUseLazyLoad) {
                                refreshTableData(0, 5, false);
                            } else {
                                setTableData(data);
                            }
                        }
                    } else if (action?.DeleteRowData) {
                        let data = rawData;
                        if (responseData) {

                            for (let i = 0; i < responseData.length; i++) {
                                const item = responseData[i];

                                let params = {} as any;
                                //Parse Data Parameter
                                if (!isEmpty(action?.RefreshFieldParameters)) {
                                    action?.RefreshFieldParameters.forEach((e: any) => {
                                        if (e.PrimaryKey) {
                                            if (!isEmpty(item[e.FieldName])) {
                                                Object.assign(params, {
                                                    [e.FieldName]: [item[e.FieldName]]
                                                })
                                            }
                                        }
                                    });
                                }
                                //Filter data to be update by params key value
                                var resultDeleted = filter(data, params);

                                if (resultDeleted && resultDeleted.length > 0) {
                                    var indexDeleted = data.indexOf(resultDeleted[0]);

                                    if (indexDeleted >= 0) {
                                        data.splice(indexDeleted, 1);
                                    }
                                }

                            }
                        }

                        if (hasDataKeys && !isEmpty(panelItemSelector?.successData?.data)) {
                            let dataSource = panelItemSelector?.successData?.data;
                            if (!Array.isArray(dataSource)) {
                                dataSource = [dataSource]
                            }
                            updateRowData(dataSource, action)
                        }

                        if (isTableUseLazyLoad) {
                            refreshTableData(0, 5, false);
                        } else {
                            setTableData(data);
                        }

                        publishOnRowClick({
                            ResetSubscriber: true
                        });
                    } else if (action?.PanelToRefresh) {
                        if (Array.isArray(action?.PanelToRefresh)) {
                            for (let index = 0; index < action?.PanelToRefresh.length; index++) {
                                const panel = action?.PanelToRefresh[index];
                                
                                if (panelItemSelector?.successData?.hasOwnProperty(panel.DataSource)) {
                                    // Use existing AddRowData, UpdateRowData, or DeleteRowData logic
                                    if (panel?.AddRowData || panel?.UpdateRowData || panel?.DeleteRowData) {
                                        const data = panelItemSelector?.successData[panel.DataSource] ?? [];
                                        await new Promise((resolve) => {
                                            dispatch({
                                                type: ACTION_SUBMIT_DATA_SUCCESS,
                                                payload: {
                                                    targetPanel: `${panel.TargetWidget}_${panel.TargetPanel}`,
                                                    data: data,
                                                    postActions: [{
                                                        ...(panel?.AddRowData && {
                                                            TargetWidget: panel.TargetWidget,
                                                            TargetPanel: panel.TargetPanel,
                                                            AddRowData: true,
                                                            DataSource: panel.DataSource,
                                                            RefreshFieldParameters: panel.RefreshFieldParameters
                                                        }),
                                                        ...(panel?.UpdateRowData && {
                                                            TargetWidget: panel.TargetWidget,
                                                            TargetPanel: panel.TargetPanel,
                                                            UpdateRowData: true,
                                                            DataSource: panel.DataSource,
                                                            RefreshFieldParameters: panel.RefreshFieldParameters
                                                        }),
                                                        ...(panel?.DeleteRowData && {
                                                            TargetWidget: panel.TargetWidget,
                                                            TargetPanel: panel.TargetPanel,
                                                            DeleteRowData: true,
                                                            DataSource: panel.DataSource,
                                                            RefreshFieldParameters: panel.RefreshFieldParameters
                                                        })
                                                    }]
                                                }
                                            });
                                            resolve(true);
                                        });
                                    } else {
                                        const data = panelItemSelector?.successData[panel.DataSource ?? ""] ?? [];
                                        // Reset Emit Data for the target panel
                                        resetEmitData(dispatch, `${panel.TargetWidget}_${panel.TargetPanel}`);
                                        
                                        // Default refresh behavior if no specific row action is defined
                                        await new Promise((resolve) => {
                                            dispatch({
                                                type: "API_GET_TABLE_DATA",
                                                payload: {
                                                    targetPanel: `${panel.TargetWidget}_${panel.TargetPanel}`,
                                                    postActions: null
                                                }
                                            });
                                            resolve(true);
                                        });
                    
                                        dispatch(
                                            consumeAPISuccess(`${panel.TargetWidget}_${panel.TargetPanel}`, data, true, -1, [], null, null)
                                        );
                                    }
                                }
                            }
                        }
                    }
                }

                const processAction = async () => {
                    for (const postActions of postActionList) {
                        if (!isEmpty(postActions)) {
                            if (Array.isArray(postActions)) {
                                for (const postAction of postActions) {
                                    await invokeAction(postAction);
                                }
                            } else {
                                if (postActions?.RefreshField) {
                                    refreshTableData(0, 5, false);
                                } else {
                                    await invokeAction(postActions);
                                }
                            }
                        }
                    };
                }

                if (!isEmpty(postActionList))
                    processAction();

            }
        }
        // eslint-disable-next-line
    }, [panelItemSelector, tableName]);

    useEffect(() => {
        if (!isEmpty(sidebarItemSelector)) {
            if (!isEmpty(sidebarItemSelector.isSidebarVisible)) {
                sidebarItemSelector.isSidebarVisible ? setIsSidebarVisible(true) : setIsSidebarVisible(false);
            }
        }
    }, [sidebarItemSelector]);

    var rowsPerPageOptions: any = (tableOption.RowsPerPage || tableRowsPerPageOptions || null)

    useEffect(() => {
        if (rowsCount <= 0) {
            if (rowsPerPageOptions != null) {
                setRowsCount(rowsPerPageOptions[0])
            }
        }
    }, [rowsPerPageOptions])

    const filterData = (data: any, fields: PanelFieldData[]) => {
        if (isEmpty(data)) {
            return []
        }
        return data.map((item: any) => {
            const filteredItem = { ...item };
            fields.forEach((fieldConfig) => {
                if (fieldConfig.Hidden) {
                    delete filteredItem[fieldConfig.FieldName];
                }
            });
            return filteredItem;
        });
    }

    const exportColumns = panelFields?.filter((c) => !c.Hidden).map(col => (col.FieldName));

    const exportCSV = (selectionOnly: any) => {

        const orderKey = (obj: any, keyOrder: any) => {
            keyOrder.forEach((k: any) => {
                const field = panelFields?.find(x => x.FieldName === k);
                if (field !== null && field?.InputType === "Dropdown" && field?.ExportType === "Description") {
                    const options = [...field.ListOfValues];
                    let displayValue = obj[k];
                    if (options) {
                        for (const option of options) {
                            const values = Object.keys(option).filter(key => key !== "HPO_DEFAULT_VALUE").map(key => option[key]);
                            if (displayValue === values[0]) {
                                obj[k] = values.length > 1 ? values[1] : values[0];
                            }
                        }
                    }
                }
                const v = obj[k]
                delete obj[k]
                obj[k] = v
            })
        }

        const dataToExport = [...filterData(rawData, panelFields)]
        if (dataToExport) {
            dataToExport.forEach(function (v: any) {
                if (v.hasOwnProperty('_id')) {
                    delete v._id
                }
                orderKey(v, exportColumns)
            });
        }

        CsvDataService.exportToCsv(`${widgetName}_${moment().format('MM_DD_YYYY hh.mm.ss')}.csv`, dataToExport);
        //dataTable?.exportCSV({ selectionOnly: false });
    }

    const exportPdf = () => {
        const doc = new jsPDF('landscape');
        let array = []
        const filtered = filterData(rawData, panelFields)
        for (let i in filtered) {
            let temp = [];
            for (let j in filtered[i]) {
                temp.push(filtered[i][j]);
            }
            array.push(temp);
        }
        autoTable(doc, {
            head: [exportColumns],
            body: array,
        })
        doc.save(`${panelName}.pdf`);
    }

    const getDataColumnHeader = (panelFields: PanelFieldData[]) => {
        const headerArray: any[] = [];
        panelFields.forEach(field => {
            if (!field.Hidden) {
                headerArray.push({ header: field.FieldLabel || field.FieldName, key: field.FieldName, name: field.FieldName })
            }
        });
        return headerArray
    }

    const parseTableData = (columns: any[], rows: any[]) => {
        return (Array.isArray(rows) ? rows : Array(rows)).map(row => {
            let obj = {} as any;
            columns.forEach((column) => {
                if (typeof column === 'object') {
                    obj[column.key] = typeof row[column.header] === 'undefined' ? row[column.key] : row[column.header];
                }
                if (typeof column === 'string') {
                    obj[column] = row[column];
                }
            });
            return obj;
        });
    }

    const rgbToHex = (rgb: any) => {

        if (isEmpty(rgb)) return ""

        const hex = rgb.match(/\d+/g).map((num: any) => {
            const hex = parseInt(num).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        });
        return `FF${hex.join('')}`;
    };

    const applyColors = (ws: any, data: any) => {
        // Track columns to remove
        const columnsToRemove = new Set();

        data.forEach((row: any, rowIndex: number) => {
            const rowNumber = rowIndex + 2; // Adjusting for 1-based index and header row
            const rowObject = ws.getRow(rowNumber);
            let rowTextColor: any, rowBackgroundColor: any;

            for (const key in row) {
                if (row.hasOwnProperty(key) &&
                    !key.endsWith('_HPO_TEXT_COLOR') &&
                    !key.endsWith('_HPO_CELL_COLOR') &&
                    !key.includes('HPO_ROW_TEXT_COLOR') &&
                    !key.includes('HPO_ROW_BACKGROUND_COLOR')) {

                    const colIndex = ws.columns.findIndex((col: any) => {
                        return col.key === key
                    });
                    if (colIndex === -1) continue; // Skip if column not found
                    const cell = rowObject.getCell(colIndex + 1); // Adjusting for 1-based index
                    const textColorKey = `${key}_HPO_TEXT_COLOR`;
                    const cellColorKey = `${key}_HPO_CELL_COLOR`;

                    if (row[textColorKey]) {
                        const rgb = row[textColorKey];
                        cell.font = { color: { argb: rgbToHex(rgb) } };
                        columnsToRemove.add(textColorKey);
                    }
                    if (row[cellColorKey]) {
                        const rgb = row[cellColorKey];
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: rgbToHex(rgb) }
                        };
                        columnsToRemove.add(cellColorKey);
                    }
                }

                // Row-level styles
                if (key.includes('HPO_ROW_TEXT_COLOR')) {
                    rowTextColor = rgbToHex(row[key]);
                    columnsToRemove.add(key);
                }
                if (key.includes('HPO_ROW_BACKGROUND_COLOR')) {
                    rowBackgroundColor = rgbToHex(row[key]);
                    columnsToRemove.add(key);
                }
            }

            // Apply row-level styles
            if (rowTextColor) {
                rowObject.eachCell((cell: any) => {
                    const currentFont = cell.font || {};
                    cell.font = { ...currentFont, color: { argb: rowTextColor } };
                });
            }
            if (rowBackgroundColor) {
                rowObject.eachCell((cell: any) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: rowBackgroundColor }
                    };
                });
            }
        });

        // Remove columns
        columnsToRemove.forEach(colKey => {
            const colIndex = ws.columns.findIndex((col: any) => col.key === colKey);
            if (colIndex !== -1) {
                ws.spliceColumns(colIndex + 1, 1);
            }
        });
    };

    // Convert numeral format to Excel number format
    const numeralToExcelFormat = (numeralFormat: string) => {
        const formats = {
            '0,0.00': '#,##0.00',
            '$0,0.00': '$#,##0.00',
            '0.00': '0.00',
            '0,0': '#,##0'
        } as any;
        return formats[numeralFormat] || numeralFormat;
    };

    // Convert moment format to Excel format
    const momentToExcelFormat = (momentFormat: string) => {
        const formatMappings = {
            'MM-DD-YYYY': 'mm/dd/yyyy',
            'YYYY-MM-DD': 'yyyy-mm-dd',
            'DD/MM/YYYY': 'dd/mm/yyyy',
            'DD/MM/YYYY HH:mm:ss': 'dd/mm/yyyy hh:mm:ss'
        } as any;
        return formatMappings[momentFormat] || momentFormat;
    };


    const exportExcel = async () => {

        const ExcelJS = require('exceljs');
        const filename = panelName;
        const columns = getDataColumnHeader(panelFields);
        const rows = parseTableData(columns, rawData);
        const workbook = new ExcelJS.Workbook();
        const ws = workbook.addWorksheet(filename);
        ws.columns = columns;
        ws.addRows(rows);

        // Auto size columns based on content
        ws.columns.forEach((column: any) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell: any) => {
                maxLength = Math.max(maxLength, cell.value ? cell.value.toString().length : 0);
            });
            column.width = maxLength + 2;
        });

        // Bold the header
        ws.getRow(1).font = { bold: true };

        // Add borders to all cells
        ws.eachRow({ includeEmpty: true }, (row: any, rowNumber: number) => {
            row.eachCell({ includeEmpty: true }, (cell: any) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };

                // Apply format based on DataType and FieldFormat
                const field = panelFields.find(x => x.FieldName === cell._column._key);

                if (rowNumber > 1 && field && field.DataType) {
                    switch (field.DataType.toLowerCase()) {
                        case 'number':
                            if (!isEmpty(cell.value)) {
                                cell.value = numeral(cell.value).value(); // Convert value to number
                            }
                            cell.numFmt = '0'; // General number format
                            break;
                        case 'percentage':
                            if (!isEmpty(cell.value)) {
                                // Convert the value to a percentage (e.g., 10% should be stored as 0.10)
                                cell.value = numeral(cell.value).value();
                            }
                            cell.numFmt = field.FieldFormat ? numeralToExcelFormat(field.FieldFormat) : '0.00%'; // Percentage format
                            break;
                        case 'decimal':
                            if (!isEmpty(cell.value)) {
                                cell.value = numeral(cell.value).value(); // Convert value to number
                            }
                            cell.numFmt = field.FieldFormat ? numeralToExcelFormat(field.FieldFormat) : '$#,##0.00'; // Decimal format
                            break;
                        case 'date':
                            if (!isEmpty(cell.value)) {
                                cell.value = new Date(cell.value); // Convert value to date
                            }
                            cell.numFmt = field.FieldFormat ? momentToExcelFormat(field.FieldFormat) : 'mm/dd/yyyy'; // Date format
                            break;
                        case 'datetime':
                            if (field.FieldFormat) {
                                cell.value = moment(cell.value, field.FieldFormat).toDate();
                            }
                            cell.numFmt = field.FieldFormat ? momentToExcelFormat(field.FieldFormat) : 'mm/dd/yyyy hh:mm:ss'; // Date and time format
                            break;
                        default:
                            cell.numFmt = '@'; // Text format
                            break;
                    }
                }
            });
        });

        // Apply colors to cells
        applyColors(ws, rawData);

        // write to a new buffer
        const xlsxData = await ws.workbook.xlsx.writeBuffer()
        saveAsExcelFile(xlsxData, panelName);

    }

    const saveAsExcelFile = (buffer: any, fileName: String) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }


    const exportButtons = (
        <div className="p-d-flex export-buttons">
            {tableExportOptions !== undefined && tableExportOptions.includes('csv') ? <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" /> : null}
            {tableExportOptions !== undefined && tableExportOptions.includes('excel') ? <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" /> : null}
            {tableExportOptions !== undefined && tableExportOptions.includes('pdf') ? <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" /> : null}
        </div>
    );

    useEffect(() => {
        if (panelItemSelector.isErrorSubmitActionData && panelItemSelector.errorData.targetPanel === tableName) {
            if (panelItemSelector.errorData?.postActions && panelItemSelector.errorData?.postActions.length > 0) {
                if (panelItemSelector.errorData?.postActions[0] != undefined &&
                    panelItemSelector.errorData?.postActions[0].RefreshFieldRowInfo != undefined &&
                    panelItemSelector.errorData?.postActions[0].RefreshFieldRowInfo != null) {
                    const field = panelItemSelector.errorData?.postActions[0].RefreshFieldRowInfo;
                    if (field.hasOwnProperty("__RowIndex")) {
                        const data = field.__RowIndex;
                        setIsEditingRow(true);
                        setActiveRowIndex(data)
                    }
                }
            }
        }
    }, [panelItemSelector])

    const onRowEditComplete = (e: any) => {
        console.log("EEE", e);

        let _data = [...rawData];
        let { newData, index } = e;
        let inputParameterValues: IFieldValue[] = [];
        Object.assign(newData, {
            __RowIndex: index
        })

        Object.keys(newData).forEach(fieldName => {
            const field = tableFields.find(f => f.FieldName === fieldName);
            let dataType = field?.DataType ?? 'Text';
            let value = newData[fieldName];
            if (dataType === "Date") {
                const formattedDate = moment(value, field?.FieldFormat).format("YYYY-MM-DD");

                if (formattedDate !== "Invalid date") { // if date hasn't formatted before
                    value = formattedDate;
                }
            }

            if (dataType === "Datetime") {
                const formattedDate = moment(value, field?.FieldFormat).format("YYYY-MM-DD HH:mm:ss");

                if (formattedDate !== "Invalid date") { // if date hasn't formatted before
                    value = formattedDate;
                }
            }

            inputParameterValues.push(
                {
                    fieldName,
                    dataType,
                    value
                } as IFieldValue
            );
        });

        const refreshFieldInfo = {
            RefreshFieldRowInfo: newData
        } as IPostAction;

        // Post action
        const options: IConsumeAPI = {
            targetPanel: tableName,
            APIName: panelData.TableOption.OnSaveAPIName!,
            inputParameterValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: [refreshFieldInfo]
        };

        consumeAPI(dispatch, options);

        Object.keys(newData).forEach(fieldName => {
            const field = tableFields.find(f => f.FieldName === fieldName);
            let dataType = field?.DataType ?? 'Text';
            if (dataType === "Date" || dataType === "Datetime") {
                const formattedDate = moment(newData[fieldName]).format(field?.FieldFormat);
                if (formattedDate !== "Invalid date") { // if date hasn't formatted before
                    newData[fieldName] = formattedDate;
                }
            }
        });

        _data[index] = newData;
        setRawData(_data);
        setIsEditingRow(false);
    }

    const onRowEditChange = (e: any) => {
        setEditingRows(e.data);
    }

    const onRowEditCancel = (e: any) => {
        let _data = [...rawData];
        _data[e.index] = editingRowData;
        setRawData(_data);
        setIsEditingRow(false);
    }

    const setActiveRowIndex = (index: number) => {
        let _data = [...rawData];
        let _editingRows = { ...editingRows, ...{ [`${_data[index][panelData?.TableOption?.DataKey ?? "RowNumber"]}`]: true } };
        setEditingRows(_editingRows);
    }

    useEffect(() => {

        if (navbarItemSelector != null && Array.isArray(navbarItemSelector)) {
            if (navbarItemSelector.filter(x => x.PerformSave).length > 0) {
                console.log("WIDGET CONFIG", widgetConfig);
                api({
                    path: `dashboard/widget/save-config`,
                    data: widgetConfig,
                    method: "POST"
                })
            }
        }
    }, [navbarItemSelector])

    const onColReorder = (e: any) => {

        dispatch({
            type: 'ON_SAVE_CONFIG_BUTTON',
            payload: {
                PerformSave: false
            }
        })

        const config = {
            PanelName: panelName,
            WorkspaceName: workspaceName,
            WidgetName: widgetName,
            Type: "Table",
            Config: {
                Columns: [
                ] as any[]
            }
        };

        e.columns.forEach((col: any) => {
            if (col.props.field != null) {
                config.Config.Columns.push({
                    FieldName: col.props.field
                })
            }
        });

        setWidgetConfig(config);

        dispatch({
            type: 'TOGGLE_SAVE_CONFIG_BUTTON',
            payload: [
                {
                    SaveConfigEnable: true
                }
            ]
        })
    }

    const updateExistingData = (existingData: any[], updateResults: any[], parameters: any[]) => {
        return existingData.map((existingRow: any) => {
            const matchingUpdate: any = updateResults.find(updateRow => {
                return parameters.every((param: any) => {
                    if (param.PrimaryKey) {
                        return updateRow[param.FieldName] === existingRow[param.FieldName];
                    }
                    return true;
                });
            });

            return matchingUpdate ? { ...existingRow, ...matchingUpdate } : existingRow;
        });
    }

    const onRowReorder = (e: any, tempPreviousData?:any) => {
        previousDataRef.current = tempPreviousData ? [...tempPreviousData] : [...rawData];
        const data = e.value[e.dropIndex] as any
        let _data = [...e.value] as any[];
        setIsReorderEvent(true);
        setSelectedRow(null);

        // Post action single draged row
        if ((!isEmpty(panelData.TableOption.ReOrderSaveMode) &&
            panelData.TableOption.ReOrderSaveMode!.toLocaleLowerCase() === 'single') ||
            !isEmpty(panelData.TableOption.OnReOrderSaveAPIName)) {

            let inputParameterValues: IFieldValue[] = [];

            inputParameterValues.push(
                {
                    fieldName: 'NEW_ROW_INDEX',
                    dataType: 'Number',
                    value: e.dropIndex + 1
                } as IFieldValue
            );

            Object.keys(data).forEach(fieldName => {
                const field = tableFields.find((f: any) => f.FieldName === fieldName);
                let dataType = field?.DataType ?? 'Text';
                let inputValue = data[fieldName];
                inputParameterValues.push(
                    {
                        fieldName,
                        dataType,
                        value: inputValue
                    } as IFieldValue
                );
            });

            const options: IConsumeAPI = {
                targetPanel: tableName,
                APIName: panelData.TableOption.OnReOrderSaveAPIName!,
                inputParameterValues,
                UseQueue: false,
                isAPIForDataTable: false,
            };
            console.log("REORDER.....");

            consumeAPI(dispatch, options, (response: any) => {
                setIsReorderEvent(false);
                if(response && response.success) {
                    if (!isEmpty(response.data)) {
                        if (panelData.TableOption.DeleteAllRows) {
                            setRawData([])
                        }
                        const dataKey = panelData?.TableOption?.RefreshFieldParameters ?? [{
                            PrimaryKey: true,
                            FieldName: panelData?.TableOption?.DataKey ?? "RowNumber"
                        }]
                        const updatedData = updateExistingData((_data ?? []), (response.data ?? []), dataKey)
                        setRawData(updatedData);
                        setLastDropIndex(e.dropIndex)
                        onRowClickHandler({ data: dataTable.current!.props.value[e.dropIndex] } as any);
                    }
                } else {
                    return setRawData([...previousDataRef.current]);
                }
            });

        } else {

            Object.assign(data, {
                NEW_ROW_INDEX: e.dropIndex + 1
            })
            const newData = orderedRowData.length > 0 ? [...orderedRowData] : [data]

            const dataKey = panelData?.TableOption?.DataKey ?? "RowNumber"

            addIfNotExist(newData, dataKey, data[dataKey], data, newData.length)

            setOrderedRowData(newData)
            setLastDropIndex(e.dropIndex)
            onRowClickHandler({ data: dataTable.current!.props.value[e.dropIndex] } as any);
            setIsReorderEvent(false);
        }

        setRawData(_data);
    }

    const saveOrederedData = () => {

        let payloadData = orderedRowData;
        if (isEmpty(payloadData) || payloadData.length <= 0) {
            payloadData = [...rawData]
            setOrderedRowData(payloadData)
        } else {
            const dataKey = panelData?.TableOption?.DataKey ?? "RowNumber"
            payloadData = rawData.map((item2: any) => {
                const matchingItem2 = orderedRowData.find((item1: any) => item2[dataKey] === item1[dataKey]);
                return matchingItem2 ? { ...item2, ...matchingItem2 } : item2;
            });
        }

        if (!isEmpty(payloadData) && (!isEmpty(appData.APIName) ||
            !isEmpty(panelData.TableOption.OnReOrderSaveAPIName) ||
            !isEmpty(panelData.TableOption.OnSaveAPIName))) {

            let multiInputParameterValues: IFieldValue[][] = [];
            for (let index = 0; index < payloadData.length; index++) {
                const data = payloadData[index];
                let inputParameterValues: IFieldValue[] = [];

                Object.keys(data).forEach(fieldName => {
                    const field = tableFields.find((f: any) => f.FieldName === fieldName);
                    let dataType = fieldName === 'NEW_ROW_INDEX' ? 'Number' : (field?.DataType ?? 'Text');
                    let inputValue = data[fieldName];
                    inputParameterValues.push(
                        {
                            fieldName,
                            dataType,
                            value: inputValue
                        } as IFieldValue
                    );
                });

                multiInputParameterValues.push(inputParameterValues)
            }

            const apiName = appData.APIName ?? panelData.TableOption.OnReOrderSaveAPIName ?? panelData.TableOption.OnSaveAPIName

            const options: IConsumeAPI = {
                targetPanel: tableName,
                APIName: apiName!,
                inputParameterValues: multiInputParameterValues,
                UseQueue: ((appData.SubmitParameterMode ?? panelData.TableOption.SubmitParameterMode) || 'QUEUE') === 'QUEUE',
                UseArray: (appData.SubmitParameterMode ?? panelData.TableOption.SubmitParameterMode) === 'ARRAY',
                isAPIForDataTable: false,
            };

            consumeAPI(dispatch, options, (params?: any) => {
                if (!isEmpty(params) && _.get(params, 'targetPanel', '') === tableName) {
                    if (params.success) {
                        setOrderedRowData([])
                        setSaveRowStateButton(null)
                        delete appData[panelData.PanelName + widgetName]
                        setAppData({
                            ...appData,
                            Action: null,
                            Type: null,
                            WidgetName: widgetName,
                            PanelName: panelName,
                            APIName: null
                        })

                        if (!isEmpty(params!.data)) {
                            if (panelData.TableOption.DeleteAllRows) {
                                setRawData([])
                            }
                            const dataKey = panelData?.TableOption?.RefreshFieldParameters ?? [{
                                PrimaryKey: true,
                                FieldName: panelData?.TableOption?.DataKey ?? "RowNumber"
                            }]
                            const updatedData = updateExistingData((rawData ?? params!.data ?? []), (params!.data ?? []), dataKey)
                            setRawData(updatedData);
                        }

                        resetSubscriberTable();
                        resetEmitData(dispatch, tableName);
                    }
                }
            });

        } else {
            setAppData({
                ...appData,
                Action: null,
                Type: null,
                WidgetName: widgetName,
                PanelName: panelName,
                APIName: null
            })
        }
    }

    useEffect(() => {
        if (panelData?.TableOption?.EditableMode! === 'row' && _.get(panelData, "TableOption.EnableEditMode", false) === false) {
            if (isEmpty(actions)) {
                actions = [];
            }
            actions?.push({ ActionName: "EditRow", ActionLabel: "Edit", OnClickAction: "EditRow" } as ActionData);
        }
    }, [actions]);

    useEffect(() => {
        async function fetchFieldControllerAPI() {
            if (!isEmpty(beaconSelector) && !isEmpty(beaconSelector.data) &&
                !Array.isArray(beaconSelector.data) && beaconSelector.data.hasOwnProperty("EventName")) {

                setIsEditingRow(true);

                let controlFields: any = {};

                if (!isEmpty(panelData?.TableOption?.FieldControllerAPI)) {


                    dispatch({
                        type: ACTION_SUBMIT_DATA,
                        payload: {
                            targetPanel: tableName,
                            postActions: []
                        }
                    });

                    let fieldValues: IFieldValue[] = [];

                    panelData?.Fields.forEach(f => {
                        fieldValues.push(
                            {
                                fieldName: f.FieldName,
                                dataType: f.DataType,
                                value: beaconSelector.data.Data.hasOwnProperty(f.FieldName) ? beaconSelector.data.Data[f.FieldName] : null
                            } as IFieldValue
                        );
                    });

                    let requestBody = {
                        APIName: panelData?.TableOption?.FieldControllerAPI,
                        inputParameterValues: fieldValues
                    };

                    const apiOption: IAPIOption = {
                        path: "query_management",
                        method: "POST",
                        data: requestBody
                    }

                    const response = await api(apiOption);
                    if (response && response.success) {
                        if (!isEmpty(response.data)) {
                            let data = response.data;

                            if (Array.isArray(response.data)) {
                                data = response.data[0];
                            }

                            Object.keys(data).forEach(o => {
                                Object.assign(controlFields, {
                                    [o.replace("_EDITABLE", "")]: (data[o] === 'false' || data[o] === false || data[o] === 0 || data[o] === '0') ? true : false
                                })
                            });

                            Object.assign(beaconSelector.data.Data, {
                                controlFields: controlFields
                            })
                        }

                        setEditingRowData(beaconSelector.data.Data);
                        setActiveRowIndex(beaconSelector.data.Data.RowNumber - 1);
                        setTableLoadingState(false);
                    }

                    dispatch({
                        type: ACTION_SUBMIT_DATA_SUCCESS,
                        payload: {
                            targetPanel: tableName,
                            postActions: []
                        }
                    });
                } else {
                    setEditingRowData(beaconSelector.data.Data);
                    setActiveRowIndex(beaconSelector.data.Data.RowNumber - 1);
                    setTableLoadingState(false);
                }
            }
        }

        fetchFieldControllerAPI()

    }, [beaconSelector]);


    useEffect(() => {
        if (_.get(appData, 'WidgetName', '') === widgetName
            && _.get(appData, 'PanelName', '') === panelName
            && _.get(appData, 'Action', '') === 'SUBMIT') {

            saveOrederedData();
        }
    }, [appData]);

    const rowClass = (data: any) => {
        const focusClass = data === selectedRow ? 'p-row-focus' : '';
        if (data.HPO_ROW_TEXT_COLOR || data.HPO_ROW_BACKGROUND_COLOR) {
            const dynamicClass = `row-${data.RowNumber}`;
            return `${dynamicClass} ${focusClass}`;
        }
        return `${focusClass}`;
    }

    const reorderableRows = !checkPermission(panelData.TableOption as Permissionable) 
                            && panelData?.TableOption.ReOrderRow;

    const reorderableRowsProps = reorderableRows ? {
        onContextMenu: (e: any) => cm.current?.show(e.originalEvent),
        contextMenuSelection: selectedItem,
        onContextMenuSelectionChange: (e: any) => setSelectedItem(e.value)
    } : {};

    useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
    
        const cssRules = rawData
            .filter((rowData: any) => 
                rowData.HPO_ROW_TEXT_COLOR || rowData.HPO_ROW_BACKGROUND_COLOR
            )
            .map((rowData: any) => {
                if (rowData.HPO_ROW_TEXT_COLOR || rowData.HPO_ROW_BACKGROUND_COLOR) {
                    const rowTextColorKey = 'HPO_ROW_TEXT_COLOR';
                    const rowBackgroundColorKey = 'HPO_ROW_BACKGROUND_COLOR';
                    const textColor = rowData[rowTextColorKey];
                    const backgroundColor = rowData[rowBackgroundColorKey];
        
                    return `.row-${rowData.RowNumber} { 
                        ${textColor ? `color: ${textColor} !important;` : ''} 
                        ${backgroundColor ? `background-color: ${backgroundColor} !important;` : ''} 
                    }`;
                }
            })
            .filter(Boolean)
            .join('\n');
    
        if (cssRules) {
            styleSheet.innerText = cssRules;
            document.head.appendChild(styleSheet);
        }
    
        return () => {
            if (styleSheet.parentNode) {
                styleSheet.parentNode.removeChild(styleSheet);
            }
        };
    }, [rawData]);    

    return (
        <div
            style={{
                ...((!isEmpty(rawData) && !isEmpty(rawData[0])) ? {
                    width: "100%"
                } : {
                    ...{
                        maxWidth: isSidebarVisible ? "calc(100vw - 23em)" : "calc(100vw - 5.2em)"
                    },
                    ...props.style
                })
            }}
        >
            <GlobalStyle />
            {
                isInvalidLazyData ? (
                    <>
                        {showSectionBreak && <hr />}
                        <p style={{ textAlign: "center" }}>
                            {isInvalidLazyData}
                        </p>
                    </>
                ) : null
            }
            {
                (
                    panelItemSelector?.isLoadingGetTableData &&
                    (
                        Array.isArray(panelItemSelector?.loadingData?.targetPanel.includes(tableName)) ?
                            panelItemSelector?.loadingData?.targetPanel.includes(tableName) :
                            panelItemSelector?.loadingData?.targetPanel === tableName
                    ) &&
                    showLoadingText
                ) ? (
                    <p style={{ textAlign: "center" }}>
                        Loading...
                    </p>
                ) : null
            }
            {
                !isEmpty(rawData) || (panelData?.TableOption?.ShowTableOnEmptyData === true) ? (
                    <>
                        {showSectionBreak && <hr />}
                        <ContextMenu
                            model={menuModel()}
                            ref={cm}
                            onHide={() => setSelectedItem(null)}
                        />
                        <DataTable
                            ref={dataTable}
                            {
                            ...(reorderableRows && {
                                reorderableRows: true
                            })
                            }

                            {...reorderableRowsProps}

                            reorderableColumns
                            onColReorder={onColReorder}
                            exportFilename={`${widgetName}_${moment().format('MM_DD_YYYY hh.mm.ss')}`}
                            responsiveLayout="scroll"
                            globalFilter={(panelData?.TableOption?.HideGlobalSearch || hideGlobalSearch) ? null : globalFilter}
                            header={(panelData?.TableOption?.HideGlobalSearch || hideGlobalSearch) ? null : TableHeaderElement(batchActionButtons, setGlobalFilter)}
                            id={panelData.PanelName}
                            //resizableColumns={!isEmpty(rawData)}

                            scrollable={!isEmpty(rawData)}
                            scrollHeight={`${panelData.PanelHeight || 300}px`}

                            tableStyle={{
                                //innerHeight: `${panelData.PanelHeight || 300}px`,
                                // width: '600px',
                                overflowX: "auto"
                            }}

                            editingRows={editingRows}
                            onRowEditChange={onRowEditChange}
                            onRowEditComplete={onRowEditComplete}
                            onRowEditCancel={onRowEditCancel}
                            onRowReorder={onRowReorder}
                            onChangeCapture={(e) => {
                                const data = appData[panelData.PanelName + widgetName];
                                if (!isEmpty(data)) {
                                    const keys = Object.keys(data)
                                    const filteredData = rawData.filter((item: any) => {
                                        return keys.includes(item.RowNumber.toString())
                                    });
                                    setOrderedRowData(filteredData)
                                }
                            }}

                            paginatorLeft={exportButtons}
                            paginator={!isEmpty(rawData)}
                            paginatorTemplate={"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink " + ((rowsPerPageOptions === null || (rowsPerPageOptions.length <= 1)) ? "" : "RowsPerPageDropdown")}
                            lazy={isTableUseLazyLoad}
                            //loading={isTableFetchingData}
                            totalRecords={isTableUseLazyLoad ? totalData : rawData.length}
                            rows={rowsCount}
                            rowsPerPageOptions={rowsPerPageOptions}

                            value={rawData}

                            first={dataTableFirstRowIndex}
                            onPage={onPage}
                            rowClassName={rowClass}
                            // // #region For expanded cell
                            expandedRows={expandedRows}
                            onRowToggle={onRowToggleHandler}
                            rowExpansionTemplate={rowExpansionTemplate}
                            // selectionMode="checkbox"
                            dataKey={panelData?.TableOption?.DataKey ?? "RowNumber"}
                            // #endregion
                            onRowClick={onRowClickHandler}
                            // #region For cell selection
                            selection={dataTableSelection}
                            {
                            ...(
                                (
                                    (actions?.length === 1 && actions[0].OnClickAction === "EmitData") ||
                                    batchActionButtons?.length === 0
                                ) &&
                                { selectionMode: "single" }
                            )
                            }
                            {
                            ...(
                                (
                                    panelData?.TableOption?.EditableMode && panelData?.TableOption?.EditableMode! === 'row'
                                ) &&
                                { editMode: panelData?.TableOption?.EditableMode }
                            )
                            }
                            onSelectionChange={onSelectionChangeHandler}
                            // #endregion

                            footer={(!isEmpty(footerActionButton) && footerActionButton ?
                                TableFooterElement(footerActionButton, setDialog, isEmpty(tableFields) ? panelData.Fields : tableFields, widgetName, panelName) : null)}
                        >

                            {
                                (reorderableRows && (panelData?.TableOption.DragableSign === undefined || panelData?.TableOption.DragableSign === true)) ?
                                    <Column 
                                        field="_row_controller" 
                                        reorderable={false} 
                                        rowReorder 
                                        className='p-datatable-reorderablerow-handle' 
                                        style={{ width: '3rem' }} 
                                    /> 
                                : null
                            }

                            {
                                hasExpandedPanels(actions) ? (
                                    <Column
                                        field="_row_expander_controller"
                                        expander={true}
                                        reorderable={false}
                                        className="expander-column"
                                        style={{
                                            width: '50px',
                                            padding: 0,
                                            textAlign: 'center',
                                            maxWidth: '50px'
                                        }}
                                    />
                                ) : null
                            }
                            {
                                batchActionButtons?.length > 0 ? (
                                    <Column
                                        field="_column_controller"
                                        selectionMode="multiple"
                                        reorderable={false}
                                        style={{
                                            width: '30px',
                                            maxWidth: '30px !important',
                                            textAlign: 'center',
                                            cursor: "pointer"
                                        }}
                                    />
                                ) : null
                            }
                            {TableColumnElement(tableFields, (panelData?.TableOption?.EditableMode !== null && panelData?.TableOption?.EditableMode! === 'row'), appData, setAppData, tableName, dataTable)}
                            {
                                actions ?
                                    (actions?.length === 1 && ["EmitData", "EmitLOVData"].includes(actions[0].OnClickAction)) ?
                                        null : TableActionButtonElement(
                                            actions,
                                            actionsColumnWidth,
                                            tableFields,
                                            tableName,
                                            setDialog,
                                            dispatch,
                                            rawData,
                                            setTableData
                                        ) : null
                            }
                            {
                                isEditingRow && (_.get(panelData, "TableOption.EnableEditMode", false) === false) ? (
                                    <Column reorderable={false}
                                        field="_row_editor_controller"
                                        rowEditor style={{
                                            width: '30px',
                                            padding: 0,
                                            textAlign: 'center',
                                            cursor: "pointer"
                                        }}></Column>
                                ) : null
                            }
                        </DataTable>
                    </>
                ) : null
            }
            {
                (!isEmpty(saveRowStateButton)) ?
                    <div className="p-grid" style={{ marginTop: '10px', marginLeft: '0px' }}>
                        <Button label="Save Row State" onClick={saveOrederedData}></Button>
                    </div> : null
            }
            {
                ((isEmpty(rawData) || isEmpty(rawData[0])) && (!isEmpty(footerActionButton) && footerActionButton)) ?
                    TableFooterElement(footerActionButton, setDialog, isEmpty(tableFields) ? panelData.Fields : tableFields, widgetName, panelName) : null
            }
            {
                dialog ?
                    TableDialogElement(actions, dialog, setDialog, register, control, handleSubmit, tableName, dispatch, errors, subscribeComponents, beaconData, browserHeight, {
                        widgetName, panelName, form, formRef, setFormRef
                    }) : null
            }
        </div>
    );
};

export default PanelTableComponent;
