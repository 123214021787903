/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React from "react";

interface Props {
    userLogo: string;
    height?: string;
    width?: string;
    wrapperStyle?: any;
    imageStyle?: any;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void; // Added onClick prop
}

const ProfilePicture: React.FC<Props> = (props) => {
    const { userLogo, height, width, wrapperStyle, imageStyle, onClick } = props;
    
    return (
        <div
            className="profile-picture"
            style={{
                height: height ? height : "50px",
                width: width ? width : "50px",
                ...wrapperStyle
            }}
            onClick={onClick} // Added onClick handler
        >
            <img
                src={userLogo}
                alt="Profile"
                style={{
                    maxHeight: "90%",
                    objectFit: "cover",
                    ...imageStyle
                }}
            />
        </div>
    );
};

export default ProfilePicture;