import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RESET_LOGIN_STATE } from "../redux/constants/auth";
import EventEmitter from "../common/EventEmitter";

export const SignOut: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        // Clear local storage and session storage
        Promise.resolve().then(() => {

            dispatch({
                type: RESET_LOGIN_STATE,
                payload: {}
            });

            EventEmitter.emit('LOGOUT', {})

        }).then(() => {
            if (process.env.REACT_APP_LOGIN_TYPE === 'DIRECT_SAML') {

                const nameId = localStorage.getItem('nameId')
                const sessionIndex = localStorage.getItem('sessionIndex');

                sessionStorage.clear();
                localStorage.clear();

                window.location.replace(process.env.REACT_APP_API_URL + `/users/saml/logout?nameId=${nameId}&sessionIndex=${sessionIndex}`)
            } else {

                sessionStorage.clear();
                localStorage.clear();

                // Redirect to signin page
                const currentPath = location.pathname;
                const defaultRedirect = "/dashboard";
                const isSignOutOrSignIn = currentPath === "/signout" || currentPath === "/signin";
                const hashRedirect = window.location.hash === "#/" ? defaultRedirect : window.location.hash;
                const redirectUrl = isSignOutOrSignIn ? `/signin?redirect=${defaultRedirect}` : `/signin?redirect=${hashRedirect}`;
                window.location.hash = redirectUrl;
            }
        });
    }, [dispatch, location]);

    const Title: React.FC = () => {
        return (
            <div className="title">
                <span></span>
            </div>
        );
    };

    return (
        <div className="sign-in">
            <div className="container">
                <Title />
                <p style={{ textAlign: 'center', margin: '10px' }}>Signing Out...</p>
            </div>
        </div>
    );
};