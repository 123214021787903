/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

import {LOGIN, LOGIN_ERROR, LOGIN_SUCCESS, LOGIN_CONFIG, LOGIN_CONFIG_ERROR, LOGIN_CONFIG_SUCCESS, RESET_LOGIN_STATE} from "../constants/auth";

import {IAction} from '../../models/interfaces/IAction';

import {errorLogin, startLogin, successLogin, errorLoginConfig, startLoginConfig, successLoginConfig} from "../utils/auth";


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action: IAction) => {
    let loadingData, successData, errorData;
    switch (action.type) {
        case LOGIN:
            loadingData = action.payload;
            return Object.assign({}, startLogin(), {loadingData});
        case LOGIN_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorLogin(), {errorData});
        case LOGIN_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successLogin(), {successData});
        case LOGIN_CONFIG:
            loadingData = action.payload;
            return Object.assign({}, startLoginConfig(), {loadingData});
        case LOGIN_CONFIG_ERROR:
            errorData = action.payload;
            return Object.assign({}, errorLoginConfig(), {errorData});
        case LOGIN_CONFIG_SUCCESS:
            successData = action.payload;
            return Object.assign({}, successLoginConfig(), {successData});
        case RESET_LOGIN_STATE:
            successData = action.payload;
            return Object.assign({}, {}, {});
        default:
            return state;
    }
}