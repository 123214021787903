/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGIN_CONFIG = "LOGIN_CONFIG";
export const LOGIN_CONFIG_SUCCESS = "LOGIN_CONFIG_SUCCESS";
export const LOGIN_CONFIG_ERROR = "LOGIN_CONFIG_ERROR";

export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";