/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef } from 'react';

import './styles/index.css';

import ReactResizeDetector from 'react-resize-detector';
import WidgetContainer from '../WidgetContainer';

import { WorkspaceData } from '../../models/classes/WorkspaceData';

import api from '../../common/api';

import { isNull, isEmpty } from '../../utils/validation';
import { useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';

interface Props {
    jsonPath?: string[];
    workspaceData?: WorkspaceData;
    isEmbedded?: boolean;
}

const Workspace: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [workspaceData, setWorkspaceData] = useState<WorkspaceData>(new WorkspaceData());
    const dispatch = useDispatch();
    const workspaceRef = useRef<HTMLDivElement>(null);

    const loadWorkspaceData = (): Promise<boolean | null> => {
        if (!isEmpty(props.workspaceData)) {
            setIsLoading(true);
            setIsError(false);
            console.log("Workspace data:");
            console.log(props.workspaceData);
            setWorkspaceData(props.workspaceData!);
            setIsLoading(false);
            return Promise.resolve(true);
        }
        setIsLoading(true);
        setIsError(false);
        dispatch({
            type: 'TOGGLE_HELP_BUTTON',
            payload: null
        })
        return api({path: `dashboard/workspace/${props.jsonPath!.length > 1?props.jsonPath![1]:props.jsonPath![0]}`})
            .then((response: any) => {
                if (isNull(workspaceRef.current)) return null;

                if (response?.success) {
                    console.log("Workspace data:");
                    console.log(response?.data);
                    setWorkspaceData(response?.data);
                } else {
                    setIsError(true);
                }

                setIsLoading(false);

                return true;
            })
            .catch((err:any)=>{
                setIsLoading(false);
                setIsError(true)
                return null;
            });
    };

    useEffect(() => {
        loadWorkspaceData();

        // eslint-disable-next-line
    }, []);

    const renderColumns =(width: number) => {
        return (
            workspaceData.Widgets.map((widget, index) => {
                if (!isEmpty(widget.DisplayPosition)) {
                    widget.WorkspaceName = workspaceData.WorkspaceName;
                    let col: number,
                        row: number,
                        colSpan: number = 1,
                        rowSpan: number = 1;

                    if (typeof widget.DisplayPosition === "string") {
                        const displayPosition = widget.DisplayPosition.split(",")
                        col = parseInt(displayPosition[0]) + 1
                        row = parseInt(displayPosition[1]) + 1
                    } else {
                        col = widget.DisplayPosition.Column + 1
                        row = widget.DisplayPosition.Row + 1
                        colSpan = widget.DisplayPosition.ColumnSpan? widget.DisplayPosition.ColumnSpan: 1
                        rowSpan = widget.DisplayPosition.RowSpan? widget.DisplayPosition.RowSpan: 1
                    }

                    return (
                        <div
                            key={index}
                            style={{
                                gridColumn: `${col} / span ${colSpan}`,
                                gridRow: `${row} / span ${rowSpan}`
                            }}
                        >
                            {
                                widget?
                                    <WidgetContainer Width={width} widgetData={widget} />:
                                    null
                            }
                        </div>
                    )
                }

                return <></>
            })
        )
    };

    const renderWidgets = (width: number) => {
        const columns = workspaceData.NumberOfColumns
        const rows = workspaceData.NumberOfRows
        let gridTemplateColumnsValue = ""
        setTimeout(() => {
            dispatch({
                type: 'TOGGLE_HELP_BUTTON',
                payload: workspaceData?.HelpCenter || null
            })
        }, 300);

        if (workspaceRef.current && !isEmpty(columns)) {

            gridTemplateColumnsValue = workspaceRef.current?.offsetWidth?
                `repeat(${columns}, calc(${((width - (columns > 4 ? 30 : 0)) / columns)}px ))`:
                `repeat(${columns}, auto)`
        } 

        //gridTemplateColumnsValue = `repeat(auto-fit, minmax(100px, 1fr))`

        return (
            <div
                style={{
                    display: "grid",
                    width: '100%',// !isEmpty(gridTemplateColumnsValue)? "auto": "98%",
                    gridGap: "5px",
                    gridTemplateColumns: gridTemplateColumnsValue,
                    gridTemplateRows: `repeat(${rows}, auto)`
                }}
            >
                {renderColumns(width)}
            </div>
        )
    };

    return (
        <ReactResizeDetector handleWidth>
            {({ width }: any) => {
                return (
                    <div
                        ref={workspaceRef}
                        id="workspace"
                        style={{
                            padding: props.isEmbedded ? "0.5em" : "0.5em 2em",
                            width: "100%",
                            justifyContent: "left",
                            display: "flex",
                        }}
                    >
                        <ConfirmDialog />
                        {
                            isLoading ?
                                <span>Loading workspace...</span> : isError ? "No workspace data" : renderWidgets(width)
                        }
                    </div>
                )
            }}
        </ReactResizeDetector>
    );
};

export default Workspace;
