/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useState, useEffect, useRef } from "react";

import "./styles/index.css";

import userLogo from "../../assets/user_logo.png";
import ProfilePicture from "../ProfilePicture";

import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "../../redux/actions/sidebarItem";
import { State } from "../../redux/reducers";
import { IHelpButton } from "../../models/classes/PanelData";
import { isEmpty } from "../../utils/validation";
import { OpenHelp } from "../../utils/help_center";
import { Menu } from 'primereact/menu';
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

interface NavbarProps {
    isSidebarVisible: boolean;
    setIsSidebarVisible: (visible: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ isSidebarVisible, setIsSidebarVisible }) => {
    const dispatch = useDispatch();
    const menuRef = useRef<Menu>(null);
    const navigate = useNavigate()
    // const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const navbarItemSelector = useSelector((state: State) => state.navbarItem as IHelpButton[]);
    const onClick = () => {
        OpenHelp(navbarItemSelector)
    }


    const items = [
        {
            label: 'Sign Out',
            icon: 'pi pi-power-off',
            command: () => {
                navigate('/signout');
            }
        }
    ];


    const handleMenuClick = () => {
        if (window.innerWidth < 991) {
            setIsSidebarVisible(true);
            toggleSidebarMenu(dispatch, true);
            return
        }
        setIsSidebarVisible(!isSidebarVisible);
        toggleSidebarMenu(dispatch, !isSidebarVisible);
    };

    useEffect(() => {
        // init state
        setTimeout(() => {
            toggleSidebarMenu(dispatch, window.innerWidth < 991 ? false : true);
        }, 100);
        // eslint-disable-next-line
    }, []);

    const onSave = () => {

        dispatch({
            type: 'TOGGLE_SAVE_CONFIG_BUTTON',
            payload: [
                {
                    SaveConfigEnable : false
                }
            ]
        })

        dispatch({
            type: 'ON_SAVE_CONFIG_BUTTON',
            payload: [{
                PerformSave : true
            }]
        })

    }
    

    return (
        <div className="navbar" style={isSidebarVisible ? { marginLeft: "250px", width: `${window.innerWidth < 991 ? 'calc(100%)' : 'calc(100% - 250px)'}`, background: !isEmpty( process.env.REACT_APP_COLOR_HEADER) ? process.env.REACT_APP_COLOR_HEADER : undefined } : {background: !isEmpty( process.env.REACT_APP_COLOR_HEADER) ? process.env.REACT_APP_COLOR_HEADER : undefined } }>
            <div className="menu" onClick={handleMenuClick}>
                <i className="pi pi-bars" />
            </div>
            {navbarItemSelector && navbarItemSelector?.filter(x => x?.HelpEnable).length > 0 ? <div onClick={() => onClick()} style={{ position: 'absolute', right: '70px', top: '15px', cursor: 'pointer' }}>Help</div> : <></>}
            {navbarItemSelector && navbarItemSelector?.filter(x => x?.SaveConfigEnable).length > 0 ? <div onClick={() => onSave()} style={{ position: 'absolute', right: '70px', top: '15px', cursor: 'pointer' }}>Save Config</div> : <></>}
            <ProfilePicture userLogo={userLogo} onClick={(event) => menuRef.current!.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
            <Menu model={items} popup ref={menuRef} id="popup_menu_left" />
        </div>
    );
};

export default Navbar;
