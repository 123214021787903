/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
    Routes,
    Route,
    useNavigate
} from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/primeflex.css';
import './styles/prime_custom_style.css';
import './styles/react-datetime.css';

import store from './redux';

import Dashboard from './pages/Dashboard';
import HelpViewer from './pages/HelpCenter';
import Signin from './pages/Signin';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { createGlobalState } from 'react-hooks-global-state';
import { isEmpty } from 'lodash';
import { getUrlParam, isUrlParamExist } from './utils/url';
import { SignOut } from './pages/SignOut';
import EventEmitter from './common/EventEmitter';
import { RedirectProvider } from './RedirectContext';

const initialState = {appData: {
    'LOV': [{}]
} as any};
export const { useGlobalState } = createGlobalState(initialState);

const App: React.FC = () => {
    const [loginType, setLoginType] = useState<String>('FORM');
    const [token, setToken] = useState<String | undefined>()
    const [isEmbedded, setIsEmbedded] = useState<boolean>(false);
    const navigate = useNavigate();

    EventEmitter.on('LOGOUT', () => {
        setToken(undefined)
    });

    useEffect(() => {
        console.log("Version 0.8.0");
        console.log('API requests are forwarded to: ', process.env.REACT_APP_API_URL);
        const jwtToken: string | null = window.localStorage.getItem("jwtToken");
        
        if (isUrlParamExist('embedded') && getUrlParam('embedded').replace(/\/+$/, '') === 'true') {
            setIsEmbedded(true);
        }

        console.log("LOGIN TYPE", process.env.REACT_APP_LOGIN_TYPE)
        setLoginType(process.env.REACT_APP_LOGIN_TYPE || "FORM")
        
        const paramToken = getUrlParam('token');
        
        if (!isEmpty(paramToken)) {
            setLoginType('SAML')
            
            const nameId = getUrlParam('nameId');
            const sessionIndex = getUrlParam('sessionIndex')

            localStorage.setItem('nameId', nameId)
            localStorage.setItem('sessionIndex', sessionIndex)
        }


        if (process.env.REACT_APP_ROWS_PER_PAGE) {
            if (window.localStorage.getItem("REACT_APP_ROWS_PER_PAGE") !== process.env.REACT_APP_ROWS_PER_PAGE) {
                window.localStorage.setItem("REACT_APP_ROWS_PER_PAGE", process.env.REACT_APP_ROWS_PER_PAGE)
            }
        }

        if (process.env.REACT_APP_EXPORT_TYPE) {
            window.localStorage.setItem("REACT_APP_EXPORT_TYPE", process.env.REACT_APP_EXPORT_TYPE)
        } else {
            window.localStorage.setItem("REACT_APP_EXPORT_TYPE", "csv")
        }

        if (jwtToken) {
            // If the URL includes "signin" or is exactly "#/", redirect to the Dashboard
            if ((window.location.hash.includes("signin") || window.location.hash === "#/")) {
                navigate('dashboard', {
                    replace: true
                })
            }
        } else if (!jwtToken && !paramToken && process.env.REACT_APP_LOGIN_TYPE === "DIRECT_SAML") {
            setLoginType('DIRECT_SAML');
            const defaultRedirect = '/dashboard';
            const currentHash = window.location.hash;
            const redirect = currentHash !== "#/" ? currentHash : defaultRedirect;
            localStorage.setItem('redirectUrl', encodeURIComponent(redirect));
            const redirectUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            window.location.replace(`${process.env.REACT_APP_API_URL}/users/saml/login?redirectUrl=${redirectUrl}&redirect=${encodeURIComponent(redirect)}`);
        } else {
            // Check if the parameter token is not empty
            if (!isEmpty(paramToken)) { 
                setLoginType('SAML');
                setToken(paramToken || '');    
            } else {
              
                // If no token is available, clear session storage and redirect to signin
                window.sessionStorage.clear();
                if (!window.location.hash.includes('signin')) {
                    const defaultRedirect = '/dashboard';
                    const currentHash = window.location.hash;
                    const redirect = currentHash !== "#/" ? currentHash : defaultRedirect;
                    navigate(`signin?redirect=${encodeURIComponent(redirect)}`, {
                        replace: true
                    });    
                } else {
                    navigate(`signin`, {
                        replace: true
                    });
                }
            }
        }        
    }, [isEmbedded, loginType, token]);

    return (
        <RedirectProvider>
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Routes>
                        <Route path="/" element={<Dashboard isEmbedded={isEmbedded} />} />
                        <Route path="/signin" element={<Signin loginType={loginType} token={token} />} />
                        <Route path="/Dashboard" element={<Dashboard isEmbedded={isEmbedded} />} />
                        <Route path="/Dashboard/*" element={<Dashboard isEmbedded={isEmbedded} />} />
                        <Route path="/HelpViewer" element={<HelpViewer />} />
                        <Route path="/signout" element={<SignOut />} />
                    </Routes>
                </MuiPickersUtilsProvider>
            </Provider>
        </RedirectProvider>
    );
};

export default App;
