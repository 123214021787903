import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { PanelFieldData } from '../../../../../../../models/classes/PanelFieldData';
import { isEmpty } from '../../../../../../../utils/validation';

// Type definitions
interface FilterOption {
  label: string;
  value: any;
}

interface FilterComponentProps {
  fieldName: string;
  filterType?: string;
  filterItems?: FilterOption[];
  column?: PanelFieldData;
  dt?: React.RefObject<DataTable<any>>;
  filterOptions: any;
}

// Main Filter Component
const FilterComponent: React.FC<FilterComponentProps> = React.memo(({ 
  fieldName, 
  filterType, 
  filterItems, 
  column, 
  filterOptions 
}) => {
  const normalizedFilterType = filterType?.toLowerCase() || "text";
  
  // Safe filter callback wrapper
  const handleFilterChange = (value: any) => {
    if (filterOptions && filterOptions.filterCallback) {
      if (normalizedFilterType === 'number') {
        if (filterOptions.filterModel.matchMode === "startsWith" || filterOptions.filterModel.matchMode === "endsWith") {
          filterOptions.filterModel.matchMode = 'equals'
        }
      }
      filterOptions.filterCallback(value, filterOptions.index);
    }
  };

  const getTagOptions = (field: PanelFieldData): FilterOption[] => {
    if (!isEmpty(field?.FieldColors)) {
      return field.FieldColors.map((color: any) => ({
        label: color.value,
        value: color.value
      }));
    }
    return [];
  };

  const renderFilterInput = () => {
    const currentValue = filterOptions?.value;

    switch (normalizedFilterType) {
      case 'text':
        return (
          <InputText
            value={currentValue || ''}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="p-column-filter"
            placeholder={`Search ${fieldName}`}
          />
        );

      case 'number':
        return (
          <InputNumber
            value={currentValue}
            onChange={(e) => handleFilterChange(e.value)}
            className="p-column-filter"
            placeholder={`Filter ${fieldName}`}
          />
        );

      case 'dropdown':
        return (
          <Dropdown
            value={currentValue}
            options={filterItems}
            onChange={(e) => handleFilterChange(e.value)}
            className="p-column-filter"
            placeholder={`Select ${fieldName}`}
            showClear
          />
        );

      case 'boolean':
        return (
          <Dropdown
            value={currentValue}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            onChange={(e) => handleFilterChange(e.value)}
            className="p-column-filter"
            placeholder={`Select ${fieldName}`}
            showClear
          />
        );

      case 'tags':
        if (!column) return null;
        const tagOptions = getTagOptions(column);
        return (
          <Dropdown
            value={currentValue}
            options={tagOptions}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => handleFilterChange(e.value)}
            placeholder={`Select ${column.FieldLabel || fieldName}`}
            className="p-column-filter"
            showClear
            itemTemplate={(option: FilterOption) => {
              const statusColor = column.FieldColors?.find(
                (color: any) => color.value === option.value
              );
              return (
                <div
                  style={{
                    backgroundColor: statusColor?.backgroundColor || '#E0E0E0',
                    color: statusColor?.textColor || '#000000',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    margin: '2px 0',
                    fontSize: '12px',
                    cursor: 'pointer',
                    width: '100%'
                  }}
                >
                  {option.label}
                </div>
              );
            }}
            valueTemplate={(selectedOption: any) => {
              if (!selectedOption) return <span>Select Field</span>;

              const statusColor = column.FieldColors?.find(
                (color: any) => color.value === selectedOption.value
              );
              return (
                <div
                  style={{
                    backgroundColor: statusColor?.backgroundColor || '#E0E0E0',
                    color: statusColor?.textColor || '#000000',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    fontSize: '12px'
                  }}
                >
                  {selectedOption.label}
                </div>
              );
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-column-filter-element">
      {renderFilterInput()}
    </div>
  );
});

// Generate filter function
const generateFilter = (
  fieldName: string,
  filterType?: string,
  filterItems?: FilterOption[],
  column?: PanelFieldData,
  dt?: React.RefObject<DataTable<any>>,
  options?: any
): React.ReactNode => {
  // Early return if options is undefined
  console.log(options)
  if (!options) return null;

  if (filterType?.toLocaleLowerCase() === 'number') {
    if (options.filterModel.matchMode === "startsWith" || options.filterModel.matchMode === "endsWith") {
      options.filterModel.matchMode = 'equals'
    }
  }
  
  return (
    <FilterComponent
      fieldName={fieldName}
      filterType={filterType}
      filterItems={filterItems}
      column={column}
      dt={dt}
      filterOptions={options}
    />
  );
};

// Exports
export { FilterComponent, generateFilter };
export type { FilterComponentProps, FilterOption };