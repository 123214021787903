import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

import { getUrlParam, isUrlParamExist } from './utils/url';
import EventEmitter from './common/EventEmitter';
import { isEmpty } from 'lodash';

export const RedirectContext = createContext<{
    redirectPath: string | null;
    handleRedirect: (defaultPath?: string) => void;
}>({
    redirectPath: null,
    handleRedirect: () => { },
});

export const RedirectProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [redirectPath, setRedirectPath] = useState<string | null>(null);

    // Normalize path to ensure single leading hash
    const normalizePath = (path:string) => {
        if (!path) return null;
        // Remove multiple leading hashes and ensure single #
        const cleanedPath = path.replace(/^#+/, '#');
        // Remove any trailing slashes for consistency
        return cleanedPath.replace(/\/+$/, '');
    };

    useEffect(() => {
        const handleLogout = () => {

            let redirectUrl = 'dashboard';
            
            if (isUrlParamExist('redirect')) {
                redirectUrl = getUrlParam('redirect');
            } else {
                const currentPath = location.pathname;
                const isSignOutOrSignIn = currentPath === "/signout" || currentPath === "/signin";
                if (!isSignOutOrSignIn) {
                    redirectUrl = window.location.hash;    
                }
            }

            if (redirectUrl.includes('#/signin?redirect=')) {
                redirectUrl = redirectUrl.replaceAll('#/signin?redirect=', '')
            }

            if (isEmpty(redirectUrl)) {
                redirectUrl = '/dashboard';
            }

            window.location.replace(`#/signin?redirect=${redirectUrl}`);
        };

        EventEmitter.on('SIGNOUT', handleLogout);

        return () => {
            EventEmitter.off('SIGNOUT', handleLogout);
        };
    }, []);

    useEffect(() => {

        const redirectParam = getUrlParam('redirect');

        if (redirectParam) {
            // Ensure redirect path starts with # for hash routing
            const normalizedRedirect = redirectParam.startsWith('#') ? redirectParam : `${redirectParam}`;
            setRedirectPath(normalizedRedirect);
        }
    }, [location]);

    const handleRedirect = (defaultPath = 'dashboard') => {
        // Ensure default path starts with # for hash routing
        const normalizedDefault = defaultPath.replaceAll('#/', '');
        const destination = redirectPath || normalizedDefault;
        
        
        if (destination) {
            navigate(normalizePath(decodeURIComponent(destination)) || defaultPath, {
                replace: true
            });
        } else {
            navigate(normalizePath(decodeURIComponent(normalizedDefault)) || defaultPath, {
                replace: true
            });
        }
        setRedirectPath(null); // Clear after navigation
    };

    const contextValue = {
        redirectPath,
        handleRedirect,
    };

    return (
        <RedirectContext.Provider value={contextValue}>
            {children}
        </RedirectContext.Provider>
    );
};

export const useRedirect = () => {
    const context = useContext(RedirectContext);
    if (!context) {
        throw new Error('useRedirect must be used within a RedirectProvider');
    }
    return context;
};