/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import { isEmpty, isNull } from "../../utils/validation";
import { State } from "../../redux/reducers";
import { TabPanel, TabView } from "../../components/Tab";
import HtmlViewer from "../../components/HtmlViewer";
import { IHelpButton } from "../../models/classes/PanelData";
import { getUrlParam, isUrlParamExist } from "../../utils/url";
import { Panel } from "primereact/panel";

const Dashboard: React.FC = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth < 991 ? false : true);
    const sidebarItemSelector = useSelector((state: State) => state.sidebarItem);
    const [getHelps, setHelps] = useState<IHelpButton[]>([])
    const dashboardRef = useRef(null);

    useEffect(() => {
        return () => {
            dashboardRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(sidebarItemSelector)) {
            if (!isEmpty(sidebarItemSelector.isSidebarVisible)) {
                sidebarItemSelector.isSidebarVisible ? setIsSidebarVisible(true) : setIsSidebarVisible(false);
            }
        }

        if (isUrlParamExist("hash")) {
            const hash = getUrlParam("hash");
            const data = sessionStorage.getItem('help-' + hash);
            if (!isEmpty(data)) {
                setHelps(JSON.parse(data || '[]'))
            }
        }

    }, [sidebarItemSelector]);

    return (
        <section className="dashboard" ref={dashboardRef}>
            <div style={{ display: 'block' }}> <Sidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/></div>
            <main className={isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}>
                <Navbar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/>
                {

                    <section id="content" style={
                        {
                            marginLeft: isSidebarVisible ? "250px" : "0px",
                            width: isSidebarVisible ? "calc(100% - 250px)" : "100%"
                        }}>
                        <div className="container">
                            <Panel header="Help Center">
                                <TabView>
                                    {getHelps?.filter(x => x?.HelpEnable).map((help, index) => (
                                        <TabPanel
                                            key={index}
                                            header={
                                                help?.HelpTitle !== ""
                                                    ? (help?.HelpTitle || '')
                                                    : `Help Content ${index + 1}`
                                            }
                                        >
                                            <HtmlViewer Code={help?.HelpCode || ''} />
                                        </TabPanel>
                                    ))}
                                </TabView>
                            </Panel>

                        </div>
                    </section>
                }
            </main>
        </section>
    );
};

export default Dashboard;