/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import {
    LoginSocialGoogle,
    IResolveParams,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
    GoogleLoginButton, MicrosoftLoginButton
} from 'react-social-login-buttons'

import { authConfig, googleCallback, login, samlCallback, verify } from '../../redux/actions/auth';
import { State } from '../../redux/reducers';

import './styles.css';

import { isEmpty } from '../../utils/validation';
import AzureAD, { AuthenticationState, IAzureADFunctionProps, MsalAuthProvider } from 'react-aad-msal';
import { authProvider } from '../../common/authProvider';
import store from '../../redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useRedirect } from '../../RedirectContext';

const CryptoJS = require('crypto-js');

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

interface ILoginType {
    loginType: String;
    token?: String;
}

const Title: React.FC = () => {
    return (
        <div className="title">
            <span></span>
        </div>
    );
};

const LoginForm: React.FC = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const toastRef = useRef<Toast>(null);
    const { handleRedirect, redirectPath } = useRedirect();

    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);

    useEffect(() => {
        if (!_.isEmpty(authItemSelector)) {
            setIsLoggingIn(authItemSelector.isLoadingLogin);

            if (authItemSelector.isSuccessLogin && !_.isEmpty(authItemSelector.successData)) {
                handleSuccess(authItemSelector.successData);
            }

            if (authItemSelector.isErrorLogin && !_.isEmpty(authItemSelector.errorData)) {
                handleError(authItemSelector.errorData);
            }
        }
    }, [authItemSelector]);

    useEffect(() => {
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                submitForm(event);
            }
        };
        document.addEventListener('keyup', handleKeyUp);
        return () => document.removeEventListener('keyup', handleKeyUp);
    }, []);

    const handleSuccess = (successData: any) => {
        toastRef.current?.show({
            severity: "success",
            summary: "Login success",
            detail: successData.ReturnMessage
        });

        const { token, refreshToken, permissions, payload } = successData.data || {};
        if (payload) window.localStorage.setItem("Language", payload.Language || "");
        if (token) window.localStorage.setItem("jwtToken", token);
        if (refreshToken) window.localStorage.setItem("jwtRefreshToken", refreshToken);
        if (permissions) window.localStorage.setItem("permissionItem", JSON.stringify(permissions));
        
        handleRedirect();
    };

    const handleError = (errorData: any) => {
        toastRef.current?.show({
            severity: "error",
            summary: "Login failed",
            detail: errorData.ReturnMessage
        });

        if (errorData.data?.validation?.length) {
            errorData.data.validation.forEach((valItem: any) => {
                toastRef.current?.show({
                    severity: "error",
                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                    detail: valItem.message
                });
            });
        }
    };

    const submitForm = (event: React.FormEvent | KeyboardEvent) => {
        event.preventDefault();

        if (!username || !password) {
            toastRef.current?.show({
                severity: "error",
                summary: "Validation Error",
                detail: "Please fill in all required fields."
            });
            return;
        }

        const encryptedPassword = CryptoJS.AES.encrypt(password.trim(), process.env.REACT_APP_CLIENT_HEX || '').toString();
        const userData = {
            Username: username.trim(),
            Password: encryptedPassword
        };

        sessionStorage.clear();
        login(dispatch, userData);
    };

    return (
        <div className="form">
            <Toast ref={toastRef} />
            <form onSubmit={submitForm}>
                <span className="p-float-label username">
                    <InputText 
                        id="username"
                        value={username}
                        disabled={isLoggingIn}
                        onChange={(e) => setUsername(e.target.value)}
                        required 
                    />
                    <label htmlFor="username">Username</label>
                </span>

                <span className="p-float-label password">
                    <Password 
                        id="password"
                        style={{ width: '100%' }}
                        feedback={false}
                        value={password}
                        disabled={isLoggingIn}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password</label>
                </span>

                <div className="sign-in-btn">
                    <Button
                        className="p-button-secondary p-button-raised p-button-rounded"
                        label={isLoggingIn ? "Logging in..." : "Login"}
                        disabled={isLoggingIn}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    );
};


const MSALLogin: React.FC<any> = ({ loginType }) => {

    const [, setIsLoggingIn] = useState<boolean>(false);
    const [msalAuthProvider, setMsalAuthProvider] = useState<MsalAuthProvider>();

    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);
    const msalItemSelector = useSelector((state: State) => state.msalItem);
    const toastRef = useRef<Toast>(null);
    const location = useLocation();
    const { handleRedirect, redirectPath } = useRedirect();

    useEffect(() => {
        if (loginType === 'MSAL') {
            // Extract the `redirect` parameter from the URL
            const queryParams = new URLSearchParams(location.search);
            const redirectUrl = queryParams.get('redirect');

            if (redirectUrl) {
                // Store the redirect URL in localStorage or state
                localStorage.setItem('redirectUrl', encodeURIComponent(redirectUrl));
            }    
        }
    }, [location, loginType]);

    const redirect = () => {
        
        if (loginType !== 'MSAL') {
            return;
        }

        handleRedirect();
         // Clear the redirect URL from localStorage
         localStorage.removeItem('redirectUrl');
    }


    useEffect(() => {
        if (msalAuthProvider === null || msalAuthProvider === undefined) {
            authProvider().then((value) => setMsalAuthProvider(value))
        }

        if (!isEmpty(authItemSelector)) {
            if (authItemSelector.isLoadingLogin) setIsLoggingIn(true);
            else { setIsLoggingIn(false); sessionStorage.removeItem("isVerifying"); }

            if (authItemSelector.isSuccessLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.successData)) {
                    const successData = authItemSelector.successData;
                    if (successData?.ReturnMessage) {
                        if (toastRef) {
                            toastRef.current!.show({
                                severity: "success",
                                summary: "Login success",
                                detail: successData.ReturnMessage
                            });
                        }
                    }

                    if (successData?.data.payload) {
                        window.localStorage.setItem("Language", _.get(successData?.data.payload, "Language", ""));
                    }

                    if (successData?.data?.token) {
                        window.localStorage.setItem("jwtToken", successData.data.token);
                        window.localStorage.setItem("jwtRefreshToken", successData.data.refreshToken);

                        redirect();
                    }

                    if (successData?.data?.permissions) {
                        window.localStorage.setItem("permissionItem", JSON.stringify(successData?.data?.permissions));
                        redirect();
                    }
                }
            }

            if (authItemSelector.isErrorLogin) {
                sessionStorage.removeItem("isVerifying");
                if (!isEmpty(authItemSelector.errorData)) {
                    const errorData = authItemSelector.errorData;
                    if (errorData?.ReturnMessage) {
                        toastRef.current!.show({
                            severity: "error",
                            icon: <></>,
                            summary: "Login failed",
                            detail: errorData.ReturnMessage
                        });
                    }
                    if (errorData?.data) {
                        if (errorData.data?.validation?.length) {
                            errorData.data.validation.forEach((valItem: any) => {
                                toastRef!.current!.show({
                                    severity: "error",
                                    icon: <></>,
                                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                                    detail: valItem.message
                                });
                            });
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [authItemSelector, msalItemSelector]);

    React.useMemo(
        async () => {
            if (!isEmpty(msalItemSelector)) {
                const isAuthenticated =
                    msalItemSelector.state === AuthenticationState.Authenticated;
                if (isAuthenticated && sessionStorage.getItem("isVerifying") == null && (msalItemSelector.idToken != null && msalItemSelector.idToken.idToken != null)) {
                    sessionStorage.setItem("isVerifying", '1')
                    const request = {
                        scopes: msalAuthProvider?.getAuthenticationParameters()?.scopes
                    };
                    try {
                        var response = await msalAuthProvider!.acquireTokenSilent(request)
                        verify(dispatch, response.accessToken)
                    } catch (error) {
                        msalAuthProvider!.logout()
                        window.sessionStorage.clear();
                        console.log("ERROR", error)
                    }
                }
            }
        },
        [msalItemSelector]
    );

    return (
        <div className="form-msal">
            <Toast ref={toastRef} />
            <div className="sign-in-btn" style={{ margin: '0px !important', height: 'auto !important' }}>

                {msalAuthProvider != null ? <AzureAD provider={msalAuthProvider!} reduxStore={store}>
                    {({
                        accountInfo,
                        authenticationState,
                        error,
                        login
                    }: IAzureADFunctionProps) => {
                        const isInProgress =
                            authenticationState === AuthenticationState.InProgress;

                        const isLoading = error == null && (isInProgress || sessionStorage.getItem("isVerifying") != null);
                        
                        return <Button
                            className="p-button-secondary p-button-raised p-button-rounded"
                            style={{ justifyContent: 'center', marginTop: "100px !important" }}
                            onClick={login}
                            disabled={isLoading}
                            label={isLoading ? 'Logging in...' : 'Login with Microsoft Account'}
                        >
                        </Button>
                    }}
                </AzureAD> : "Initializing Configuration..."}
            </div>
        </div>
    );
};

const SocialLogin: React.FC<any> = ({ token, isVerifying, loginType }) => {
    const dispatch = useDispatch();
    const authItemSelector = useSelector((state: State) => state.authItem);
    const toastRef = useRef<Toast>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [config, setConfig] = useState<any>(null);
    const location = useLocation();
    const { handleRedirect, redirectPath } = useRedirect();

    useEffect(() => {
        if (loginType !== 'SOCIAL') {
            return;
        }
        // Extract the `redirect` parameter from the URL
        const queryParams = new URLSearchParams(location.search);
        const redirectUrl = queryParams.get('redirect');

        if (redirectUrl) {
            // Store the redirect URL in localStorage or state
            localStorage.setItem('redirectUrl', encodeURIComponent(redirectUrl));
        }
    }, [location, loginType]);

    const redirect = () => {

        if (loginType === 'SOCIAL' && !isEmpty(token)) {
            handleRedirect();
            // Clear the redirect URL from localStorage
            localStorage.removeItem('redirectUrl');
        }


    }

    useEffect(() => {
        if (isEmpty(authItemSelector)) return;

        const { 
            isSuccessLoginConfig, 
            isLoadingLogin, 
            isSuccessLogin, 
            isErrorLogin, 
            successData, 
            errorData 
        } = authItemSelector;

        if (isSuccessLoginConfig && !isEmpty(successData)) {
            setConfig(successData);
        }

        setIsLoggingIn(isLoadingLogin);
        if (!isLoadingLogin) sessionStorage.removeItem("isVerifying");

        if (isSuccessLogin && !isEmpty(successData)) {
            sessionStorage.removeItem("isVerifying");
            handleLoginSuccess(successData);
        }

        if (isErrorLogin && !isEmpty(errorData)) {
            sessionStorage.removeItem("isVerifying");
            handleLoginError(errorData);
        }
    }, [authItemSelector]);

    useMemo(() => {
        if (!isEmpty(token)) {
            setIsLoggingIn(true);
            samlCallback(dispatch, token);
        }
    }, [token, dispatch]);

    const handleLoginSuccess = (data: any) => {
        const { ReturnMessage, data: payload } = data;
        
        if (ReturnMessage && toastRef.current) {
            toastRef.current.show({ severity: "success", summary: "Login success", detail: ReturnMessage });
        }

        if (payload) {
            const { token, refreshToken, permissions, payload: userPayload } = payload;
            
            if (token) window.localStorage.setItem("jwtToken", token);
            if (refreshToken) window.localStorage.setItem("jwtRefreshToken", refreshToken);
            if (permissions) window.localStorage.setItem("permissionItem", JSON.stringify(permissions));
            if (userPayload?.Language) window.localStorage.setItem("Language", userPayload.Language);

            redirect();
        }
    };

    const handleLoginError = (errorData: any) => {
        const { ReturnMessage, data } = errorData;

        if (ReturnMessage && toastRef.current) {
            toastRef.current.show({ severity: "error", summary: "Login failed", detail: ReturnMessage });
        }

        if (data?.validation?.length) {
            data.validation.forEach((valItem: any) => {
                toastRef.current?.show({
                    severity: "error",
                    summary: `Validation Error: ${valItem.dataPath.replace(".", "")}`,
                    detail: valItem.message
                });
            });
        }
    };

    const getSocialAuth = (name: string) => {
        if (!config || typeof config !== 'object') return null;
    
        switch (name) {
            case 'GOOGLE_AUTH':
                if (!config?.GOOGLE_AUTH || isEmpty(config.GOOGLE_AUTH)) return null;
                const scopes = Array.isArray(config?.GOOGLE_AUTH?.SCOPES)
                    ? config.GOOGLE_AUTH.SCOPES.join(' ')
                    : config.GOOGLE_AUTH.SCOPES || '';
    
                return (
                    <LoginSocialGoogle
                        key="GOOGLE"
                        isOnlyGetToken
                        client_id={config.GOOGLE_AUTH.CLIENT_ID || ''}
                        scope={scopes}
                        onLoginStart={() => setIsLoggingIn(true)}
                        onResolve={({ data }: IResolveParams) => {
                            try {
                                googleCallback(dispatch, data?.access_token);
                            } catch (error) {
                                setIsLoggingIn(false);
                                console.error("Google callback error:", error);
                            }
                        }}
                        onReject={(err) => {
                            setIsLoggingIn(false);
                            console.error("Google login error:", err)}
                        }
                    >
                        <Toast ref={toastRef} />
                        <GoogleLoginButton
                            disabled={isLoggingIn}
                            text={isLoggingIn ? 'Verifying...' : 'Log in with Google'}
                            style={{ borderRadius: '25px' }}
                        />
                    </LoginSocialGoogle>
                );
    
            case 'MICROSOFT_SAML_AUTH':
                return (
                    <MicrosoftLoginButton
                        key="SAML"
                        style={{ background: 'white', color: 'black', borderRadius: '25px' }}
                        visible={!isVerifying}
                        disabled={isLoggingIn}
                        text={isLoggingIn ? 'Verifying...' : 'Log in with Microsoft'}
                        onClick={() => {
                            const port = window.location.port ? `:${window.location.port}` : '';
                            const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
                            const loginUrl = new URL(`${process.env.REACT_APP_API_URL}/users/saml/login`);
                            loginUrl.searchParams.append('redirectUrl', redirectUrl);
                            window.location.replace(loginUrl.toString());
                        }}
                    />
                );
    
            default:
                return null;
        }
    };    

    return (
        <div>
            { isVerifying ? <p style={{ textAlign: 'center', margin: '10px' }}>Verifying User...</p> :
            config && Object.keys(config).map((key) => getSocialAuth(key))}
        </div>
    );
};

const Signin: React.FC<ILoginType> = (props) => {
    const dispatch = useDispatch();
    const [config, setConfig] = useState<any>(null);
    const { token } = props;

    useEffect(() => {
        let isMounted = true;
    
        authConfig(dispatch).then((response) => {
            if (isMounted && !isEmpty(response.data)) {
                setConfig(response.data);
            }
        });
    
        return () => {
            isMounted = false; // Mencegah update state setelah komponen di-unmount
        };
    }, [dispatch]);    

    return (
        props.loginType !== "DIRECT_SAML" ? (
            <div className="sign-in">
                <div className="container">
                    <Title />
                    {token ? (
                        <div className="verifying">
                            <SocialLogin loginType="SOCIAL" token={token} isVerifying />
                        </div>
                    ) : (
                        <>
                            {props.loginType === "MSAL" ? (
                                <MSALLogin loginType={props.loginType} />
                            ) : (
                                <LoginForm />
                            )}
                            {(!isEmpty(config) || !isEmpty(token)) && (
                                <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                    <hr />
                                    <SocialLogin loginType="SOCIAL" token={token} isVerifying={!isEmpty(token)} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        ) : null
    );
};

export default Signin;
