    import React, { useState, useRef, useEffect } from 'react';
    import moment from "moment";
    import numeral from "numeral";
    import { useDispatch, useSelector } from 'react-redux';
    import { useForm, Controller } from 'react-hook-form';
    import Calendar from '@toast-ui/calendar';
    import '@toast-ui/calendar/dist/toastui-calendar.min.css';
    import { Button } from 'primereact/button';
    import { Dialog } from 'primereact/dialog';
    import { Dropdown } from 'primereact/dropdown';
    import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

    import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
    import { checkPermission } from "../../../../../../utils/permission";
    import { emitData } from "../../../../../../redux/actions/beacon";

    // Interfaces
    import { IAPIOption } from '../../../../../../models/interfaces/IAPIOption';
    import { IConsumeAPI } from '../../../../../../models/interfaces/IConsumeAPI';
    import { IFieldValue } from '../../../../../../models/interfaces/IFieldValue';

    // Models
    import { DialogField } from '../../../../../../models/classes/DialogField';
    import { PanelFieldData } from '../../../../../../models/classes/PanelFieldData';

    // Components
    import FieldGenerator from '../../../../../../utils/field_generator';

    // Redux
    import { consumeAPI } from '../../../../../../redux/actions/panelItem';
    import { State } from '../../../../../../redux/reducers';

    // Utils
    import { isEmpty } from '../../../../../../utils/validation';
    import api from '../../../../../../common/api';

    import { getTodayDate } from '../../../../../../utils/date';
    import { getDropdownValues } from '../../common/action';

    import { ProgressSpinner } from "primereact/progressspinner";
    import __t from "../../../../../../utils/translation";
    interface IPanelCalendarActivity {
      widgetName: string;
      panelData: any;
    }

    const PanelCalendarActivity: React.FC<IPanelCalendarActivity> = ({ widgetName, panelData }) => {
      const containerRef = useRef<HTMLDivElement>(null);
      const formRef = useRef(null);
      const dataOriginalRef = useRef<any[]>([]);
      const [calendar, setCalendar] = useState<Calendar | null>(null);
      const [view, setView] = useState<'month' | 'week' | 'day'>(panelData?.ActivityOption?.type || 'month');
      const [showAddDialog, setShowAddDialog] = useState(false);
      const [showViewDialog, setShowViewDialog] = useState(false);
      const [showEditDialog, setShowEditDialog] = useState(false);
      const [selectedEvent, setSelectedEvent] = useState<any>(null);
      const [formData, setFormData] = useState<any>({});
      const [dataOriginal, setDataOriginal] = useState<any>([]);
      // First, update the state management at the top of your component
      const [isAddLoading, setIsAddLoading] = useState(false);
      const [isEditLoading, setIsEditLoading] = useState(false);
      const [isDeleteLoading, setIsDeleteLoading] = useState(false);

      const [dialog, setDialog] = useState<any>(null);
      const [dropdownValues, setDropdownValues] = useState<{[key: string]: any[]}>({});

      const [currentDate, setCurrentDate] = useState(moment());
      const [selectedYear, setSelectedYear] = useState(moment().year());
      const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);


      const dispatch = useDispatch();
      const panelItemSelector = useSelector((state: State) => state.panelItem);
      const panelName = `${widgetName}_${panelData.PanelName}`;

      const { control, handleSubmit, formState: { errors }, reset, setValue, clearErrors } = useForm();

      
      // Generate years (e.g., 10 years before and after current year)
      const years = Array.from({ length: 21 }, (_, i) => ({
        label: String(moment().year() - 10 + i),
        value: moment().year() - 10 + i
      }));

      // Months array
      const months = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ];
    

      // Modified navigation handlers
      const handleYearChange = (e: any) => {
        const newYear = e.value;
        setSelectedYear(newYear);
        const newDate = moment(currentDate).year(newYear);
        setCurrentDate(newDate);
        if (calendar) {
          calendar.setDate(newDate.toDate());
          calendar.render();
        }
      };

      const handleMonthChange = (e: any) => {
        const newMonth = e.value;
        setSelectedMonth(newMonth);
        // Subtract 1 when setting moment month since it uses 0-11
        const newDate = moment(currentDate).month(newMonth - 1);
        setCurrentDate(newDate);
        if (calendar) {
          calendar.setDate(newDate.toDate());
          calendar.render();
        }
      };

      // Add new helper functions to check PostActions

      const checkPostActionFlag = (flagName: string): boolean => {
        // Instead of looking for specific ActionName, check all actions for the PostAction flag
        return panelData.Actions.some((action: any) => 
          action.PostActions?.some((postAction: any) => postAction[flagName] === true)
        ) || false;
      };

      const canAddData = checkPostActionFlag('AddRowData');
      const canEditData = checkPostActionFlag('EditRowData');
      const canDeleteData = checkPostActionFlag('DeleteRowData');

      const getActionByPostAction = (postActionFlag: string) => {
        return panelData.Actions.find((action: any) => 
          action.PostActions.some((postAction: any) => postAction[postActionFlag] === true)
        );
      };

      const formatApiEvent = (event: any) => {
        const originalData: any = {};
        const addAction = panelData.Actions.find((action: any) => 
          action.PostActions.some((postAction: any) => postAction.AddRowData === true)
        );
        
        if (addAction) {
          addAction.Fields.forEach((field: PanelFieldData) => {
            originalData[field.FieldName] = event[field.FieldName];
          });
        }

        const eventTitle = (() => {
          if (panelData.Fields) {
            const titleField = panelData.Fields.find((field: PanelFieldData) => field.FieldTitle === true);
            if (titleField) {
              return event[titleField.FieldName] || 'Untitled Event';
            }
          }
          return event.EventName || 'Untitled Event';
        })();
        const startDate = event.StartDate || event.START_DATE;
        const endDate = event.EndDate || event.END_DATE;
        const backgroundColor = event.BackgroundColor || event.BACKGROUND_COLOR;
        
        const uniqueId = event.id || event._id || `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        
        return {
          id: uniqueId,
          calendarId: '1',
          title: eventTitle,
          start: new Date(startDate),
          end: new Date(endDate),
          backgroundColor: backgroundColor,
          category: 'time',
          isAllday: false,
          ...originalData,
          ...event // Include all original fields for reference
        };
      };

      const checkActionVisibility = (actionType: string, rowData: any): boolean => {
        if (!rowData) return false;
        
        const editAction = getActionByPostAction('EditRowData');
        const deleteAction = getActionByPostAction('DeleteRowData');
        
        if (actionType === 'edit') {
          // Check if edit action exists and its HPO flag
          if (!editAction) return false;
          const hideFlag = rowData[`HPO_ACTION_HIDDEN_${editAction.ActionName}`];
          return hideFlag !== "true" && hideFlag !== true && hideFlag !== 1 && hideFlag !== "1";
        } else if (actionType === 'delete') {
          // Check if delete action exists and its HPO flag
          if (!deleteAction) return false;
          const hideFlag = rowData[`HPO_ACTION_HIDDEN_${deleteAction.ActionName}`];
          return hideFlag !== "true" && hideFlag !== true && hideFlag !== 1 && hideFlag !== "1";
        }
        return false;
      };
      

      // Pertama, buat instance calendar dalam useEffect pertama
      useEffect(() => {
        if (containerRef.current) {
          const calendarInstance = new Calendar(containerRef.current, {
            defaultView: view,
            usageStatistics: false,
            isReadOnly: !canAddData,
            month: {
              dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              startDayOfWeek: 0,
              isAlways6Weeks: false,
            },
            week: {
              dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              hourStart: 0,
              hourEnd: 24,
              eventView: true,
              taskView: false
            },
            useDetailPopup: false
          });

          setCalendar(calendarInstance);

          return () => {
            calendarInstance.destroy();
          };
        }
      }, []);

      // Inside the useEffect with event handlers
      useEffect(() => {
        if (!calendar) return;

        // Event handler for click event
        const handleClickEvent = (eventInfo: any) => {
          const matchingData = dataOriginalRef.current?.find((item: any) => 
            item.id === eventInfo.event.id
          );
          
          if (matchingData) {
            setSelectedEvent(matchingData);
            const canEdit = checkActionVisibility('edit', matchingData);
            const canDelete = checkActionVisibility('delete', matchingData);              
            if (canEdit) {
              const editData = { ...matchingData };
              setFormData(editData);
              reset(editData);
              setShowEditDialog(true);
            } else if (canDelete) {
              const deleteAction = getActionByPostAction('DeleteRowData');
              confirmDialog({
                message: deleteAction?.ConfirmationMessage || "Are you sure you want to delete this?",
                header: deleteAction?.header || 'Delete Confirmation',
                icon: deleteAction?.icon || 'pi pi-exclamation-triangle',
                acceptClassName: !isEmpty(deleteAction?.acceptClassName)
                  ? deleteAction.acceptClassName
                  : "p-button-danger",
                accept: async () => {
                  try {
                    await handleDeleteEvent(matchingData);
                    await loadCalendarData();
                  } catch (error) {
                    console.error("Error during deletion:", error);
                  }
                },
                reject: () => {}
              });
            } else {
              setShowViewDialog(true);
            }
          }
        };

        // Event handler for select date time
        const handleSelectDateTime = (dateTimeInfo: any) => {
          calendar.clearGridSelections();
          handleAddEvent(dateTimeInfo);
        };

        // Event handler before update
        const handleBeforeUpdateEvent = () => {
          loadCalendarData();
        };

        // Add event listeners
        calendar.on('clickEvent', handleClickEvent);
        calendar.on('selectDateTime', handleSelectDateTime);
        calendar.on('beforeUpdateEvent', handleBeforeUpdateEvent);

        // Cleanup function
        return () => {
          calendar.off('clickEvent', handleClickEvent);
          calendar.off('selectDateTime', handleSelectDateTime);
          calendar.off('beforeUpdateEvent', handleBeforeUpdateEvent);
        };
      }, [calendar]);

      useEffect(() => {
        if (calendar) {
          loadCalendarData();
          calendar.on('dateChange', (date) => {
            const newDate = moment(date.date);
            setCurrentDate(newDate);
            setSelectedYear(newDate.year());
            setSelectedMonth(newDate.month() + 1); // Add 1 to convert from 0-11 to 1-12
          });
        }
      }, [calendar]);

      const loadCalendarData = async () => {
        if (!panelData?.LoadData?.APIName || !calendar) return;
        try {
          const requestBody = {
            APIName: panelData.LoadData.APIName,
            inputParameterValues: []
          };
      
          const apiOption: IAPIOption = {
            path: "query_management",
            method: "POST",
            data: requestBody
          };
          const response = await api(apiOption);
          
          if (response.success) {
            const events = response.data.map((event: any) => formatApiEvent(event));
            // Update both the ref and state
            dataOriginalRef.current = events;
            setDataOriginal(events);
            
            // Clear existing events and create new ones
            calendar.clear();
            calendar.createEvents(events);
            
            // Make sure we're in the correct view before rendering
            calendar.changeView(view);
            // Force calendar to refresh view
            calendar.render();
          }
        } catch (error) {
          console.error('Error loading calendar data:', error);
        }
      };

      const LoadingSpinner = () => {
        return (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 50
            }}
          >
            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          </div>
        );
      };

      const createDialogField = (field: PanelFieldData, value: any = null) => {
        // The DialogField constructor takes (tableField: PanelFieldData, disabled: boolean, value: any)
        const disabled = field.hasOwnProperty("Editable") ? !field.Editable : false;
        return new DialogField(field, disabled, value);
      };

      const handleAddEvent = (dateTimeInfo: any) => {
        const initialData: any = {};
      
        const addAction = getActionByPostAction('AddRowData');
        
        if (addAction) {
          addAction.Fields.forEach((field: PanelFieldData) => {
            if (field.DataType === "Datetime" || field.DataType === "Date") {
              if (!isEmpty(field?.DefaultValue) || isEmpty(field?.DefaultValue as DialogField)) {
                if (field?.DataType === "Date" && !isEmpty(field.DefaultValue)) {
                    if (field.FieldName === "StartDate" || field.FieldName === "START_DATE" || field.FieldName.toUpperCase() === "STARTDATE") {
                      field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.start, "YYYY-MM-DD").format(field.FieldFormat || "YYYY-MM-DD");
                    } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE" || field.FieldName.toUpperCase() === "ENDDATE") {
                      field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.end, "YYYY-MM-DD").format(field.FieldFormat || "YYYY-MM-DD");
                    }
                }

                if (field?.DataType === "Datetime" && !isEmpty(field.DefaultValue)) {
                  if (field.FieldName === "StartDate" || field.FieldName === "START_DATE" || field.FieldName.toUpperCase() === "STARTDATE") {
                    field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.start, "YYYY-MM-DD HH:mm:ss").format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                  } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE" || field.FieldName.toUpperCase() === "ENDDATE") {
                    field.DefaultValue = moment(getTodayDate(field.DefaultValue) || dateTimeInfo.end, "YYYY-MM-DD HH:mm:ss").format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                  }
                }
              }
            } else {
              initialData[field.FieldName] = field.DefaultValue || null;
            }
          });
        }
      
        setFormData(initialData);
        reset(initialData);
        setShowAddDialog(true);
      };

      const handleCloseAddDialog = () => {
        setShowAddDialog(false);
        if (calendar) {
          calendar.clearGridSelections();
        }
        reset({});
      };

      const handleSaveEvent = async (data: any) => {
        if (!calendar) return;
        setIsAddLoading(true);
        const addAction = getActionByPostAction('AddRowData');
        
        if (addAction) {
          const fieldValues: IFieldValue[] = addAction.Fields.map((field: PanelFieldData) => {
            let value = data[field.FieldName];
      
            // Format value based on DataType
            if (!isEmpty(value)) {
              if (field.DataType === "Date") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD");
              }
      
              if (field.DataType === "Datetime") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD HH:mm:ss");
              }
      
              if (field.DataType === "Decimal" || field.DataType === "Number") {
                const parsedValue = numeral(value).value();
                if (parsedValue !== null) {
                  value = parsedValue;
                }
              }
            }
      
            return {
              fieldName: field.FieldName,
              fieldType: field.DataType,
              value,
              dataType: field.DataType,
              isFieldOverrider: false
            };
          });
      
          const options: IConsumeAPI = {
            targetPanel: panelName,
            APIName: addAction.APIName,
            inputParameterValues: fieldValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: addAction.PostActions
          };
      
          try {
            await consumeAPI(dispatch, options, async (response:any) => {
              handleCloseAddDialog();
              setIsAddLoading(false);
              await loadCalendarData();
            });
          } catch (error) {
            console.error('Error saving event:', error);
          }
        }
      };

      const handleUpdateEvent = async (data: any) => {
        if (!calendar || !selectedEvent) return;
        setIsEditLoading(true);
        const editAction = getActionByPostAction('EditRowData');
        if (editAction) {
          const fieldValues: IFieldValue[] = editAction.Fields.map((field: PanelFieldData) => {
            let value = data[field.FieldName];
      
            // Format value based on DataType
            if (!isEmpty(value)) {
              if (field.DataType === "Date") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD");
              }
      
              if (field.DataType === "Datetime") {
                value = moment(value, field.FieldFormat || undefined).format("YYYY-MM-DD HH:mm:ss");
              }
      
              if (field.DataType === "Decimal" || field.DataType === "Number") {
                const parsedValue = numeral(value).value();
                if (parsedValue !== null) {
                  value = parsedValue;
                }
              }
            }
      
            return {
              fieldName: field.FieldName,
              fieldType: field.DataType,
              value,
              dataType: field.DataType,
              isFieldOverrider: false
            };
          });

          const options: IConsumeAPI = {
            targetPanel: panelName,
            APIName: editAction.APIName,
            inputParameterValues: fieldValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: editAction.PostActions
          };

          try {
            await consumeAPI(dispatch, options, async (response:any) => {
              setShowEditDialog(false);
              setIsEditLoading(false);
              await loadCalendarData();
            });
            
          } catch (error) {
            console.error('Error updating event:', error);
            setIsEditLoading(false);
          }
        }
      };

      useEffect(() => {
        const fetchDropdownValues = async () => {
          const fields = getActionByPostAction('AddRowData')?.Fields || [];
          const editFields = getActionByPostAction('EditRowData')?.Fields || [];
          const allFields = [...fields, ...editFields];
      
          for (const field of allFields) {
            if (field.ListOfValuesAPI && !dropdownValues[field.FieldName]) {
              try {
                const response: any = await getDropdownValues(field.ListOfValuesAPI, true);
                if (response?.success && response.data) {
                  let ListOfValues:any = [];
                  if (Array.isArray(response.data) && response.data.length > 0) {
                    ListOfValues = response.data;
                  } else if (response.data.ListOfValues) {
                    ListOfValues = response.data.ListOfValues;
                  }
      
                  if (ListOfValues) {
                    setDropdownValues(prev => ({
                      ...prev,
                      [field.FieldName]: ListOfValues
                    }));
                  }
                }
              } catch (error) {
                console.error('Error fetching dropdown values:', error);
              }
            }
          }
        };
      
        fetchDropdownValues();
      }, []);

      const renderDialogFields = (fields: PanelFieldData[]) => {
        return fields.map((field: PanelFieldData, index) => {
          // if (field.Hidden) return null;
      
          const dialogField = createDialogField(
            field, 
            formData[field.FieldName] || field.DefaultValue || null
          );
      
          // Set ListOfValues from our cached values
          if (field.ListOfValuesAPI && dropdownValues[field.FieldName]) {
            dialogField.ListOfValues = dropdownValues[field.FieldName];
          }
      
          return (            
              <div
                key={index} 
                style={{
                    height: "100%",
                    whiteSpace: "nowrap",
                    display: field!.Hidden? "none":"flex",
                    flexDirection: field?.InputType === "DisplayText" || field?.InputType === "FileUpload" ? "row" : process.env.REACT_APP_LAYOUT_INPUT === "grid" ? "column" : "row",
                    alignItems: field?.InputType === "FileUpload" ? undefined : "flex-start",
                    justifyContent: "space-between",
                    marginTop:"10px"
                }}
              >
              {/* <div key={field.FieldName} className="flex flex-column gap-2" style={{margin:"5px"}}> */}
                <label style={{marginRight:'20px'}} htmlFor={field.FieldName}>{field.FieldLabel}</label>
                <Controller
                  name={field.FieldName}
                  control={control}
                  defaultValue={dialogField.value}
                  rules={dialogField.Mandatory ? { required: `${field.FieldLabel} is required.` } : undefined}
                  render={({ field: formField, fieldState }) => (
                    <FieldGenerator
                      reference={formField}
                      field={dialogField}
                      ref={formRef}
                      customProps={{
                        chartName: panelName,
                        container: {
                          widgetName,
                          panelName,
                          formRef,
                          form: {
                            setValue: (name: string, value: any) => {
                              formField.onChange(value);
                              setFormData((prev:any) => ({
                                ...prev,
                                [name]: value
                              }));
                            },
                            clearErrors,
                            setError: (name: string, error: any) => {
                              // Handle error setting if needed
                            }
                          },
                        },
                        dialog,
                        setDialog,
                        style: { width: field.FieldWidth || '100%' }
                      }}
                      fieldState={fieldState}
                      onChange={(value:any) => {
                        formField.onChange(value);
                        setFormData((prev:any) => ({
                          ...prev,
                          [field.FieldName]: value
                        }));
                      }}
                    />
                  )}
                />
                {errors[field.FieldName] && (
                  <small className="p-error">{errors[field?.FieldName]?.message}</small>
                )}
              </div>          
          );
        });
      };

      const getDialogDimensions = (actionType: 'add' | 'edit' | 'view'): { width: string, height: string } => {
        const action = (() => {
          switch (actionType) {
            case 'add':
              return getActionByPostAction('AddRowData');
            case 'edit':
              return getActionByPostAction('EditRowData');
            default:
              return null;
          }
        })();
      
        return {
          width: action?.Width || '450px',  // Default width if not specified
          height: action?.Height ? `${action.Height}px` : 'auto' // Use ActionDialogHeight if available
        };
      };

      const checkRowCondition = (action: any, rowData: any) => {
        if (!rowData) return false;
        const key = `HPO_ACTION_HIDDEN_${action.ActionName}`;
        const value = rowData[key];
        return value === 1 || value === "1" || value === true || value === "true";
      };

      const getEventActions = (event: any) => {
        if (!event || !panelData.Actions) return [];
      
        return panelData.Actions.filter((action: any) => {
          // Skip actions that are already handled (Add/Edit/Delete)
          const isBasicAction = action.PostActions?.some((postAction: any) => 
            postAction.AddRowData || 
            postAction.EditRowData
          );
      
          const isHidden = checkPermission(action) || 
                          checkRowCondition(action, event);
      
          return !isBasicAction && !isHidden;
        });
      };
      
      // Handle dynamic action click
      const handleActionClick = async (action: any, event: any) => {
        if (!event) return;
      
        const handleSubmit = async () => {
          setIsEditLoading(true)
          // Get primary key field from RefreshFieldParameters if it exists
          const primaryKeyField = action.PostActions
            ?.find((postAction: any) => postAction.RefreshFieldParameters)
            ?.RefreshFieldParameters
            ?.find((param: any) => param.PrimaryKey)
            ?.FieldName;
      
          let fieldValues: IFieldValue[] = [];
          
          if (primaryKeyField) {
            fieldValues = [{
              fieldName: primaryKeyField,
              fieldType: 'Text',
              value: event[primaryKeyField],
              dataType: 'Text',
              isFieldOverrider: false
            }];
          } else if (action.Fields) {
            fieldValues = action.Fields.map((field: PanelFieldData) => ({
              fieldName: field.FieldName,
              fieldType: field.DataType,
              value: event[field.FieldName],
              dataType: field.DataType,
              isFieldOverrider: false
            }));
          }
      
          const options: IConsumeAPI = {
            targetPanel: panelName,
            APIName: action.APIName,
            inputParameterValues: fieldValues,
            UseQueue: false,
            isAPIForDataTable: false,
            postActions: action.PostActions
          };
      
          try {
            await consumeAPI(dispatch, options, async () => {
              setShowEditDialog(false);
              setIsEditLoading(false)
              await loadCalendarData();
            });
          } catch (error) {
            console.error('Error executing action:', error);
          }
        };
      
        if (action.OnClickAction === "EmitData") {
          const fields = action.Fields.map((field: any) => ({
            DataName: action.ActionName,
            DataSource: field.FieldName,
            EmittedValue: event[field.FieldName],
          }));
      
          emitData(dispatch, panelName, fields);
          setShowEditDialog(false);
        } else if (action.RequiredConfirmation) {
          confirmDialog({
            message: action.ConfirmationMessage || "Please Confirm!",
            header: action.header || 'Confirmation',
            icon: action.icon || 'pi pi-exclamation-triangle',
            acceptClassName: !isEmpty(action.acceptClassName)
            ? action.acceptClassName
            : "p-button-danger",
            accept: handleSubmit,
            reject: () => {}
          });
        } else {
          await handleSubmit();
        }
      };

      const renderAddDialog = () => {
        const dimensions = getDialogDimensions('add');

        return (
          <Dialog 
            header="Add New Event" 
            visible={showAddDialog} 
            style={{ width: dimensions.width, height: dimensions.height }} 
            modal
            onHide={handleCloseAddDialog}
            footer={(
              <div>
                <Button label="Cancel" icon="pi pi-times" onClick={handleCloseAddDialog} className="p-button-text" />
                <Button label="Save" icon="pi pi-check" onClick={handleSubmit(handleSaveEvent)} />
              </div>
            )}
          >
            <div className="flex flex-column gap-4 relative">
            {isAddLoading && <LoadingSpinner />}
              {renderDialogFields(
                getActionByPostAction('AddRowData')?.Fields || []
              )}
            </div>
          </Dialog>
        );
      }

      const renderViewDialog = () => {
        const checkRowCondition = (action: any, rowData: any) => {
          if (!rowData) return false;
          const key = `HPO_ACTION_HIDDEN_${action.ActionName}`;
          const value = rowData[key];
          return value === 1 || value === "1" || value === true || value === "true";
        };
      
        const onSelect = async (action: any, rowData: any) => {
          // Close the view dialog first when any action is clicked
          setShowViewDialog(false);
        
          const handleSubmit = async () => {
            // Get primary key field from RefreshFieldParameters if it exists
            setIsEditLoading(true)
            const primaryKeyField = action.PostActions
              ?.find((postAction: any) => postAction.DeleteRowData || postAction.EditRowData)
              ?.RefreshFieldParameters
              ?.find((param: any) => param.PrimaryKey)
              ?.FieldName;
        
            let fieldValues: IFieldValue[] = [];
            
            if (primaryKeyField) {
              // If we have a primary key field, use it
              fieldValues = [{
                fieldName: primaryKeyField,
                fieldType: 'Text',
                value: rowData[primaryKeyField],
                dataType: 'Text',
                isFieldOverrider: false
              }];
            } else if (action.Fields) {
              // Otherwise fall back to using all fields
              fieldValues = action.Fields.map((field: PanelFieldData) => ({
                fieldName: field.FieldName,
                fieldType: field.DataType,
                value: rowData[field.FieldName],
                dataType: field.DataType,
                isFieldOverrider: false
              }));
            }
        
            const options = {
              targetPanel: panelName,
              APIName: action.APIName,
              inputParameterValues: fieldValues,
              UseQueue: false,
              isAPIForDataTable: false,
              postActions: action.PostActions
            };
        
            return consumeAPI(dispatch, options, async () => {
              setIsEditLoading(false)
              await loadCalendarData();
            });
          };
        
          const onReject = () => {};
        
          if (action.OnClickAction === "EmitData") {
            const fields = action.Fields.map((field: any) => ({
              DataName: action.ActionName,
              DataSource: field.FieldName,
              EmittedValue: rowData[field.FieldName],
            }));
        
            emitData(dispatch, panelName, fields);
          } else {
            const setAction = async () => {
              if (!isEmpty(action.Fields)) {
                // Handle dialog opening if needed
                const editData = { ...rowData };
                
                // Properly format the data for the edit dialog
                action.Fields.forEach((field: PanelFieldData) => {
                  if (field.DataType === "Datetime" || field.DataType === "Date") {
                    if (field.FieldName === "StartDate" || field.FieldName === "START_DATE") {
                      editData[field.FieldName] = moment(rowData.start).format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                    } else if (field.FieldName === "EndDate" || field.FieldName === "END_DATE") {
                      editData[field.FieldName] = moment(rowData.end).format(field.FieldFormat || "YYYY-MM-DD HH:mm:ss");
                    }
                  }
                  // Keep the original values for other fields
                  else if (rowData[field.FieldName] !== undefined) {
                    editData[field.FieldName] = rowData[field.FieldName];
                  }
                });
        
                setFormData(editData);
                reset(editData);
                setShowEditDialog(true);
              } else if (action.RequiredConfirmation) {
                confirmDialog({
                  message: action.ConfirmationMessage || "Please Confirm!",
                  header: action.header || 'Confirmation',
                  icon: action.icon || 'pi pi-exclamation-triangle',
                  acceptClassName: !isEmpty(action.acceptClassName)
                  ? action.acceptClassName
                  : "p-button-danger",
                  accept: handleSubmit,
                  reject: onReject
                });
              } else {
                await handleSubmit();
              }
            };
        
            const setActionController = async () => {
              try {
                const requestBody = {
                  APIName: action.ActionControllerAPI,
                  inputParameterValues: action.Fields?.map((field: PanelFieldData) => ({
                    fieldName: field.FieldName,
                    value: rowData[field.FieldName]
                  }))
                };
                
                const response = await api({
                  path: "query_management",
                  method: "POST",
                  data: requestBody
                });
        
                if (!isEmpty(response) && response.success) {
                  setAction();
                }
              } catch (error) {
                console.error("Action controller check failed:", error);
              }
            };
        
            if (!isEmpty(action.ActionControllerAPI)) {
              await setActionController();
            } else {
              await setAction();
            }
          }
        };
      
        return (
          <Dialog
            header="Event Details"
            visible={showViewDialog}
            style={{ width: '450px' }}
            modal
            onHide={() => setShowViewDialog(false)}
            footer={(() => {
              if (!selectedEvent || !panelData.Actions) return null;
      
              // Filter visible actions
              const visibleActions = panelData.Actions.filter((action: any) => {
                const hasAddRowData = action.PostActions?.some((postAction: any) => postAction.AddRowData);
                const hasDetailRowData = action.PostActions?.some((postAction: any) => postAction.DetailRowData);
                
                return !checkPermission(action) && 
                       !checkRowCondition(action, selectedEvent) &&
                       !hasAddRowData && 
                       !hasDetailRowData;
              });
      
              if (visibleActions.length === 0) return null;
      
              return (
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                  {visibleActions.length > 1 ? (
                    <Menu>
                      <MenuButton>
                        Actions <span aria-hidden>▾</span>
                      </MenuButton>
                      <MenuList>
                        {visibleActions.map((action: any, index: any) => (
                          <MenuItem
                            key={index}
                            style={{ margin: "5px 0" }}
                            onSelect={() => onSelect(action, selectedEvent)}
                          >
                            {__t(action, "ActionLabel")}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  ) : (
                    <Button
                      label={__t(visibleActions[0], "ActionLabel")}
                      onClick={() => onSelect(visibleActions[0], selectedEvent)}
                    />
                  )}
                  <Button 
                    label="Close" 
                    icon="pi pi-times" 
                    onClick={() => setShowViewDialog(false)} 
                    className="p-button-text"
                  />
                </div>
              );
            })()}
          >
            <div className="relative">
              {isDeleteLoading && <LoadingSpinner />}
              {selectedEvent && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {panelData.Fields
                    .filter((field: PanelFieldData) => !field.Hidden)
                    .map((field: PanelFieldData) => {
                      let displayValue = selectedEvent[field.FieldName] || '-';
                      
                      // Format datetime fields
                      if ((field.DataType === 'Datetime' || field.DataType === 'Date') && selectedEvent[field.FieldName]) {
                        if (field.FieldName === 'StartDate') {
                          displayValue = moment(selectedEvent.start).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                        } else if (field.FieldName === 'EndDate') {
                          displayValue = moment(selectedEvent.end).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                        } else {
                          displayValue = moment(selectedEvent[field.FieldName]).format(field.FieldFormat || 'MM/DD/YYYY hh:mm A');
                        }
                      }
      
                      // Handle color radio button display
                      if (field.InputType === 'ColorRadioButton' && selectedEvent[field.FieldName]) {
                        displayValue = (
                          <div 
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '50%',
                              backgroundColor: selectedEvent[field.FieldName],
                              border: '1px solid #dee2e6'
                            }}
                          />
                        );
                      }
      
                      return (
                        <div 
                          key={field.FieldName} 
                          style={{
                            display: 'flex',
                            padding: '8px 0',
                            borderBottom: '1px solid #dee2e6',
                            minHeight: '40px',
                            alignItems: 'center'
                          }}
                        >
                          <label 
                            style={{
                              fontWeight: 600,
                              color: '#4B5563',
                              minWidth: '140px'
                            }}
                          >
                            {field.FieldLabel}:
                          </label>
                          <div style={{ flex: 1, color: '#111827' }}>
                            {displayValue}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </Dialog>
        );
      };

      const renderEditDialog = () => {
        const dimensions = getDialogDimensions('edit');
        // Recalculate canDelete every time we render the dialog
        const canDelete = selectedEvent && checkActionVisibility('delete', selectedEvent);
        const additionalActions = getEventActions(selectedEvent);
        
        return (
          <Dialog
            header="Edit Event"
            visible={showEditDialog}
            style={{ width: dimensions.width, height: dimensions.height }}
            modal
            onHide={() => setShowEditDialog(false)}
            footer={(
              <div className="flex justify-between items-center">
                <div>
                  {additionalActions.map((action: any, index: number) => (
                    <>
                      {
                        action.PostActions?.some((postAction: any) => postAction["DeleteRowData"] === true) && canDelete ? 
                        <Button
                          key={index}
                          label={__t(action, "ActionLabel")}
                          icon={action.Icon || "pi pi-cog"}
                          className={action.ButtonClass || "p-button-secondary"}
                          onClick={() => handleActionClick(action, selectedEvent)}
                        /> : 
                        <Button
                          key={index}
                          label={__t(action, "ActionLabel")}
                          icon={action.Icon || "pi pi-cog"}
                          className={action.ButtonClass || "p-button-secondary"}
                          onClick={() => handleActionClick(action, selectedEvent)}
                        /> 
                      }
                    </>
                  ))}
                  <Button 
                    label="Cancel" 
                    onClick={() => setShowEditDialog(false)} 
                    type="button"
                    className="p-button p-component p-button-text-only p-button-danger p-button-raised" 
                    raised 
                    severity="danger" 
                    icon={`pi ${process.env.REACT_APP_ICON_BTN_CANCEL}`}
                    iconPos={process.env.REACT_APP_ICON_BTN_CANCEL === "left" || process.env.REACT_APP_ICON_BTN_CANCEL === "right" || process.env.REACT_APP_ICON_BTN_CANCEL === "top" || process.env.REACT_APP_ICON_BTN_CANCEL === "bottom" ? process.env.REACT_APP_ICON_BTN_CANCEL : "left"}
                    style={{
                        backgroundColor: process.env.REACT_APP_BACKGROUND_BTN_CANCEL || '#d32f2f',
                        color: process.env.REACT_APP_TEXT_COLOR_BTN_CANCEL || '#ffffff',
                    }}
                  />
                  <Button 
                    type="button" 
                    className="p-button p-component p-button-text-only p-button-raised"
                    raised
                    label="Submit" 
                    severity="info"
                    icon={`pi ${process.env.REACT_APP_ICON_BTN_SUBMIT}`}
                    iconPos={process.env.REACT_APP_ICON_BTN_SUBMIT === "left" || process.env.REACT_APP_ICON_BTN_SUBMIT === "right" || process.env.REACT_APP_ICON_BTN_SUBMIT === "top" || process.env.REACT_APP_ICON_BTN_SUBMIT === "bottom" ? process.env.REACT_APP_ICON_BTN_SUBMIT : "left"}
                    style={{
                        backgroundColor: process.env.REACT_APP_BACKGROUND_BTN_SUBMIT || '#2196f3',
                        color: process.env.REACT_APP_TEXT_COLOR_BTN_SUBMIT || '#ffffff',
                    }}
                    onClick={handleSubmit(handleUpdateEvent)} 
                  />
                </div>
              </div>
            )}
          > 
            <div className="flex flex-column gap-4 relative">
              {isEditLoading || isDeleteLoading ? <LoadingSpinner /> : ''}
              {renderDialogFields(
                getActionByPostAction('EditRowData')?.Fields || []
              )}
            </div>
          </Dialog>
        );
      };      
      
      const handleDeleteEvent = async (event: any) => {
        return new Promise(async (resolve, reject) => {
          try {
            if (!event || !checkActionVisibility('delete', event)) {
              console.warn('Delete action is hidden or unavailable');
              return;
            }
            setIsDeleteLoading(true);
            const deleteAction = getActionByPostAction('DeleteRowData');
            if (!deleteAction) {
              setIsDeleteLoading(false);
              return;
            }
            
            // Get primary key field from RefreshFieldParameters if it exists
            const primaryKeyField = deleteAction.PostActions
            ?.find((postAction: any) => postAction.DeleteRowData)
            ?.RefreshFieldParameters
            ?.find((param: any) => param.PrimaryKey)
            ?.FieldName;
          
            let fieldValues: IFieldValue[] = [];
            
            if (primaryKeyField) {
              fieldValues = [{
                fieldName: primaryKeyField,
                fieldType: 'Text',
                value: event[primaryKeyField],
                dataType: 'Text',
                isFieldOverrider: false
              }];
            } else {
              fieldValues = deleteAction.Fields.map((field: PanelFieldData) => ({
                fieldName: field.FieldName,
                fieldType: field.DataType,
                value: event[field.FieldName],
                dataType: field.DataType,
                isFieldOverrider: false
              }));
            }
          
            const options: IConsumeAPI = {
              targetPanel: panelName,
              APIName: deleteAction.APIName,
              inputParameterValues: fieldValues,
              UseQueue: false,
              isAPIForDataTable: false,
              postActions: deleteAction.PostActions
            };
          
            try {
              await consumeAPI(dispatch, options, async () => {
                setShowEditDialog(false);
                setIsDeleteLoading(false);
                await loadCalendarData();
                resolve(true);
              });
            } catch (error) {
              console.error('Error deleting event:', error);
              setIsDeleteLoading(false);
            }
          } catch (error) {
            reject(error);    
          }
        });
      };
      
      // Modified calendar navigation helper
      const safeCalendarOperation = (operation: string) => {
        if (!calendar) return;

        try {
          let newDate = moment(currentDate);
          const currentView = view || 'month';

          switch(operation) {
            case 'next':
              switch(currentView) {
                case 'month':
                  if (selectedMonth === 12) {
                    newDate = newDate.add(1, 'month');
                    setSelectedMonth(1);
                    setSelectedYear(newDate.year());
                  } else {
                    newDate = newDate.add(1, 'month');
                    setSelectedMonth(newDate.month() + 1);
                  }
                  break;
                case 'week':
                  newDate = newDate.add(1, 'week');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
                case 'day':
                  newDate = newDate.add(1, 'day');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
              }
              break;
            case 'prev':
              switch(currentView) {
                case 'month':
                  if (selectedMonth === 1) {
                    newDate = newDate.subtract(1, 'month');
                    setSelectedMonth(12);
                    setSelectedYear(newDate.year());
                  } else {
                    newDate = newDate.subtract(1, 'month');
                    setSelectedMonth(newDate.month() + 1);
                  }
                  break;
                case 'week':
                  newDate = newDate.subtract(1, 'week');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
                case 'day':
                  newDate = newDate.subtract(1, 'day');
                  setSelectedMonth(newDate.month() + 1);
                  setSelectedYear(newDate.year());
                  break;
              }
              break;
            case 'today':
              newDate = moment();
              setSelectedMonth(newDate.month() + 1);
              setSelectedYear(newDate.year());
              break;
          }

          setCurrentDate(newDate);
          calendar.setDate(newDate.toDate());
          calendar.render();
        } catch (error) {
          console.error('Calendar operation failed:', error);
        }
      };

      return (
        <div className="h-full mb-">
          <div className="flex justify-between items-center mb-4 gap-2" style={{marginTop:"10px", marginBottom: "10px"}}>
            <div style={{display:"flex", gap: "5px"}}>
              <Button label="Today" onClick={() => safeCalendarOperation('today')} />
              <Button icon="pi pi-chevron-left" onClick={() => safeCalendarOperation('prev')} />
              <Button icon="pi pi-chevron-right" onClick={() => safeCalendarOperation('next')} />
              <Dropdown
                value={selectedMonth}
                options={months}
                onChange={handleMonthChange}
                className="w-40"
              />
              <Dropdown
                value={selectedYear}
                options={years}
                onChange={handleYearChange}
                className="w-24"
              />
              <Dropdown
                value={view}
                options={[
                  { label: 'Month', value: 'month' },
                  { label: 'Week', value: 'week' },
                  { label: 'Day', value: 'day' }
                ]}
                onChange={(e) => {
                  setView(e.value);
                  if (calendar) {
                    // First change the view
                    calendar.changeView(e.value);
                    // Then reload the data
                    calendar.clear();
                    const existingEvents = dataOriginalRef.current;
                    if (existingEvents && existingEvents.length > 0) {
                      calendar.createEvents(existingEvents);
                    }
                    // Finally render
                    calendar.render();
                  }
                }}
                className="w-24"
              />
            </div>
          </div>

          <div 
            ref={containerRef} 
            style={{ height: panelData.PanelHeight || '600px' }}
            className="calendar-container"
          />
          {isDeleteLoading || isAddLoading || isEditLoading ? <LoadingSpinner /> : ''}
          {canAddData && renderAddDialog()}
          {renderViewDialog()}
          {canEditData && renderEditDialog()}
        </div>
      );
    };

    export default PanelCalendarActivity;